<div class="container-fluid">
    <app-pagetitle title="Patient List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>



<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="row" style="margin: 3%;">
                <form [formGroup]="Perform" (ngSubmit)="searchdata()">
                    <div class="row">
                        <div class="col-md-4 col-sm-4">
                            <div class="form-group mb-4">
                                <label for="name">From Date <span style="color:red">*</span></label>
                                <input type="date" formControlName="pdate" class="form-control"
                                    placeholder="Enter Date" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="form-group mb-4">
                                <label for="name">To Date <span style="color:red">*</span></label>
                                <input type="date" formControlName="tdate" class="form-control"
                                    placeholder="Enter Date" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <button style="background-color: #44b6ae; border-color: #44b6ae; margin-top: 28px;"
                                type="submit" class="btn btn-primary">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="clcik()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Add - Patient
                </button>

                <div class="row mb-md-2">

                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <br>
                            <label class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries
                            </label>
                        </div>
                    </div>
                    <!-- Download Excel Button -->
                    <div class="col-sm-12 col-md-2" style="text-align: left">
                        <div class="form-group mb-0" style="text-align: right;">
                            <button type="submit" class="btn btn-secondary me-1"
                                (click)="downloadExcel()">Excel</button>
                            <button type="reset" class="btn btn-secondary" (click)="downloadPdf()"> PDF</button>
                        </div>
                    </div>
                    <!-- Search -->
                    <!-- <div class="col-sm-4 col-md-4"> -->
                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table id="basic-datatable"
                            class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th sortable="patient_id" (sort)="onSort($event)"> Patient ID</th>
                                    <th sortable="first_name" (sort)="onSort($event)"> First Name</th>
                                    <th sortable="last_name" (sort)="onSort($event)">Last Name</th>
                                    <th sortable="phone_number" (sort)="onSort($event)">Number</th>
                                    <th sortable="age" (sort)="onSort($event)">Age</th>
                                    <th sortable="gender" (sort)="onSort($event)">Gender</th>
                                    <th sortable="description" (sort)="onSort($event)">Problem</th>
                                    <th sortable="dob" (sort)="onSort($event)">Date of birth</th>
                                    <th sortable="date" (sort)="onSort($event)">Admitted Date</th>
                                    <th>Active Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let table of tables$ | async;let i=index">
                                <tr>
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.patient_id"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.first_name"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.last_name"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.phone_number"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.age" [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.gender"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.description"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        {{table.dob | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td>
                                        {{table.date | date:'dd-MM-yyyy HH:mm'}}
                                    </td>
                                    <td>
                                        <button *ngIf="table.expiry_date>currentDate" type="button"
                                            class="btn btn-success btn-rounded">Active </button>
                                        <button *ngIf="table.expiry_date<currentDate" type="button"
                                            class="btn btn-danger btn-rounded">In-active</button>
                                    </td>
                                    <td>

                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                            class="ri-eye-fill" title="View" (click)="gotoprescriptionview(table)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- End table -->
                    <div class="row justify-content-md-between align-items-md-center mt-2">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                                entries
                            </div>
                        </div>

                        <!-- Pagination -->
                        <div class="col-sm-12 col-md-5">
                            <div class="text-md-end float-md-end s pagination-rounded">
                                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                    [pageSize]="service.pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- End Pagination -->

                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <ng-template #centerDataModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title mt-0">Edit Patient </h5>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="edit()" [formGroup]="typeValidationForm">

                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>First Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                            <input type="text" class="form-control" formControlName="first_name"
                                [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                                placeholder="Type something" />
                            <div *ngIf="typesubmit && type.first_name.errors" class="invalid-feedback">
                                <span *ngIf="type.first_name.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Last Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                            <input type="text" class="form-control" formControlName="last_name"
                                [ngClass]="{'is-invalid': typesubmit && type.last_name.errors}"
                                placeholder="Type something" />
                            <div *ngIf="typesubmit && type.last_name.errors" class="invalid-feedback">
                                <span *ngIf="type.last_name.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                            <div>
                                <input formControlName="age" type="text" minlength="2" maxlength="2"
                                    class="form-control" [ngClass]="{'is-invalid': typesubmit && type.age.errors}"
                                    placeholder="Enter only numbers">
                                <div *ngIf="typesubmit && type.age.errors" class="invalid-feedback">
                                    <span *ngIf="type.age.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Phone Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                            <div>
                                <input formControlName="phone_number" type="text" minlength="10" maxlength="10"
                                    class="form-control"
                                    [ngClass]="{'is-invalid': typesubmit && type.phone_number.errors}"
                                    placeholder="Enter only numbers">
                                <div *ngIf="typesubmit && type.phone_number.errors" class="invalid-feedback">
                                    <span *ngIf="type.phone_number.errors.required">This value is required.</span>
                                    <span *ngIf="type.phone_number.errors.pattern">This value should be a valid
                                        Phone Number.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Date of Birth <span style="color:red">&nbsp;&nbsp;*</span></label>
                            <input type="date" class="form-control" formControlName="dob"
                                [ngClass]="{'is-invalid': typesubmit && type.dob.errors}"
                                placeholder="Type something" />
                            <div *ngIf="typesubmit && type.dob.errors" class="invalid-feedback">
                                <span *ngIf="type.dob.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Guardian (optional)</label>
                            <input type="text" class="form-control" formControlName="guardian"
                                [ngClass]="{'is-invalid': typesubmit && type.guardian.errors}"
                                placeholder="Type something" />
                            <div *ngIf="typesubmit && type.guardian.errors" class="invalid-feedback">
                                <span *ngIf="type.guardian.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Aadhar (optional)</label>
                            <input type="text " class="form-control" formControlName="aadhar" minlength="12"
                                maxlength="12" [ngClass]="{'is-invalid': typesubmit && type.aadhar.errors}"
                                placeholder="Type something" />
                            <div *ngIf="typesubmit && type.aadhar.errors" class="invalid-feedback">
                                <span *ngIf="type.aadhar.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                            <div>
                                <select formControlName="gender" class="form-control"
                                    [ngClass]="{'is-invalid': typesubmit && type.gender.errors}">
                                    <option value="" selected>Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                                <div *ngIf="typesubmit && type.gender.errors" class="invalid-feedback">
                                    <span *ngIf="type.gender.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Address (optional)</label>
                            <textarea type="number" class="form-control" formControlName="address"
                                [ngClass]="{'is-invalid': typesubmit && type.address.errors}"
                                placeholder="Type something" rows="3"></textarea>
                            <div *ngIf="typesubmit && type.address.errors" class="invalid-feedback">
                                <span *ngIf="type.address.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary me-1"> Save changes </button>
                        <button type="reset" class="btn btn-secondary"> Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template> -->