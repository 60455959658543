export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  // production: false,
  // Api: 'http://localhost:2303/nodeapp/', 
  Api: 'https://amaravathicare.com:2024/nodeapp/',

};


