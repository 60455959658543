<table width="98%" style="padding:5px;" align="center">
    <tbody>
        <tr>
            <td colspan="8">
                <table width="98%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td width="6%" rowspan="4" align="left">
                                <div align="center"><img src="assets/images/brands/ava.png"
                                        style="max-height:100px; max-width:800px"></div>
                            </td>
                            <td colspan="3" align="left">
                                <div align="center" class="style66">
                                    {{companyName}}. </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                    </strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                        {{companyAddress}} </strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67">Phone:-
                                    {{companyNumber}} </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" style="border:2px solid;" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td colspan="2" align="left" bgcolor="#99FFCC"><b>
                                    <div align="center"><b><span class="style41">
                                                PURCHASE RECEIPT/BILL </span></b></div>
                                </b></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style41">
                                    </span><span class="style41"><span class="style2">
                                    </span></span></td>
                            <td align="left"><span class="style2">
                                    </span><span class="style2">
                                    <span class="style41">
                             </span></span></td>
                        </tr>
                        <tr>
                            <td><span class="style2">
                                    Supplier Name : </span> <span class="style2">{{sName}} </span>
                            </td>
                            <td align="right"><span class="style2">
                                    Age/Sex : </span><span class="style2">
                                    Male </span></td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style2">
                                    Supplier Invoice :</span><span class="style2">{{sInvoice}}</span></td>
                            <td align="right"><span class="style2">
                                    Rcpt.No .:</span><span class="style2">
                                    AMVT-99&nbsp;&nbsp;&nbsp;&nbsp;Pay Mode :-Cash </span></td>
                        </tr>
                        <tr>
                            <td height="18" align="left"><span class="style2"></span><span class="style2"></span></td>
                            <td><span class="style41">
                                    </span><span class="style41"></span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <table width="100%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td width="1%" bgcolor="#99FFCC"><span class="style56 style13">
                                    Sr. </span></td>
                            <td width="20%" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td width="10%" bgcolor="#99FFCC"><span class="style56 style13">
                                </span>Purchase date</td>
                            <td width="10%" bgcolor="#99FFCC">
                                <div align="right" class="style56 style13">Batch No </div>
                            </td>
                            <td width="8%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td width="5%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span>Medicine Name</td>
                            <td width="5%" align="center" bgcolor="#99FFCC"></td>
                            <td width="3%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span>Medicine Price</td>
                            <td width="4%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td width="8%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span>Quantity</td>
                            <td width="8%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span>GST(%)</td>
                            <td width="4%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td width="8%" align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                    Amt.(
                                    ₹)</span></td>
                        </tr>

                        <tr>
                            <td colspan="13">
                                <hr>
                            </td>
                        </tr>
                        <tr *ngFor="let a of data2; let i = index">
                            <td>
                                <span class="style13">{{i+1}}</span>
                            </td>
                            <td><span class="style13"></span></td>
                            <td><span class="style13">{{a.purchasedate}}</span></td>
                            <td>
                                <div align="right" class="style13">{{a.batch_no}}</div>
                            </td>
                            <td align="right"><span class="style2"></span></td>
                            <td align="right"><span class="style13" style="padding-right:5px">{{a.medicine_name}}</span>
                            </td>
                            <td align="center"></td>
                            <td align="right">
                                <span class="style13" style="padding-right:5px"><span
                                        class="style2">{{a.value}}</span></span>
                            </td>
                            <td align="right"></td>
                            <td align="right" style="padding-right:5px;"><span class="style13">{{a.quantity}}</span>
                            </td>
                            <td align="right" style="padding-right:5px;"><span class="style13">{{a.gst_per}}</span></td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right" style="padding-right:5px;"><span
                                    class="style13">{{a.medicine_price}}</span>
                            </td>
                        </tr>


                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" bgcolor="#99FFCC" style="padding-top:0px;"><span
                                    class="style13">Total</span></td>
                            <td bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td colspan="2" bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;">&nbsp;</td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;"></td>
                            <td bgcolor="#99FFCC"></td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;"></td>
                            <td colspan="2" align="right" bgcolor="#99FFCC" style="padding-right:5px;"><span
                                    class="style13"><span class="style13"
                                        style="border-bottom:dashed #000000;border-width:2px;">
                                        <span class="style56 style13">(
                                            ₹)</span>
                                        {{grandTotal}}</span></span></td>
                        </tr>

                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <div align="center" class="style13">
                                    <hr>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12" bgcolor="#99FFCC">Total</td>
                            <td bgcolor="#99FFCC" style="text-align:right;"><span class="style56 style13"> (
                                    ₹)</span> {{grandTotal}}</td>
                        </tr>
                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <div align="center" class="style13">
                                    <hr>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top:0px;" colspan="11"><span class="style41">
                                    Amount in word &nbsp;&nbsp;:&nbsp;&nbsp;(
                                    ₹  rupees &nbsp;&nbsp;Only) </span></td>
                        </tr>

                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13">*This is a
                                    Computerised Bill/Receipt </span></td>
                            <td colspan="10" style="padding-top:0px;">
                                <div align="right" class="style13">{{companyName}}
                                     </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;">&nbsp;</td>
                            <td colspan="10" style="padding-top:0px;">
                                <div align="right" class="style13">
                                    AMARAVATHI 13-12-2023 </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13"> Wish you speedy
                                    Recovery..... </span></td>
                            <td colspan="10" style="padding-top:0px;">
                                <div align="right" class="style13">
                                    Authorised Signatory * </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top:0px;" colspan="13">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<br><br>




<div style="text-align: center;">
    <button mat-raised-button id="printPageButton" style="margin-top: 1%;" (click)="openPDF()" color="primary"
        class="button-customize" *ngIf="disable">
        Print</button>&nbsp;&nbsp;
    <button mat-raised-button id="printPageButton" style="margin-top: 1%;" (click)="Back()" color="primary"
        class="button-customize" *ngIf="disable">
        Back</button>&nbsp;&nbsp;
</div>

