import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-appointmentprint',
  templateUrl: './appointmentprint.component.html',
  styleUrls: ['./appointmentprint.component.scss']
})
export class AppointmentprintComponent {
  disable: boolean = true;
  opdata: any[] = [];
  address: any;
  company: any;
  one: any;
  two: any;
  three: any;
  four: any;
  five: any;
  six: any;
  seven: any;
  eight: any;
  com_add: any;
  nine: any;
  hos_number: any;
  ngOnInit(): void {
    this.opdata = [JSON.parse(sessionStorage.getItem('appointmentprint'))]
    this.company = (localStorage.getItem('company_name'))
    this.com_add = (localStorage.getItem('address'))
    this.hos_number = (localStorage.getItem('hos_number'))
    this.one = this.opdata[0].salutation
    this.two = this.opdata[0].name
    this.three = this.opdata[0].dob
    this.four = this.opdata[0].appointment_date
    this.five = this.opdata[0].doctor_name
    this.six = this.opdata[0].gender
    this.seven = this.opdata[0].age
    this.eight = this.opdata[0].number
    this.nine = this.opdata[0].problem

  }
  constructor(private location: Location) {


  }
  print(){

    setTimeout(() => {
      window.print();
    }, 200);

  }

  Back() {
    this.location.back();
  }
}




