import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

var Api = environment.Api;

@Injectable({
  providedIn: 'root'
})

export class ServiceService {

  constructor(private http: HttpClient) { }

  login(data) {
    return this.http.post<any>(Api + `login`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  getloginpermission(data) {
    return this.http.post<any>(Api + `getloginpermission`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }


  getpermission() {
    return this.http.post<any>(Api + `getpermission`, []).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  posthospitaldetails(data) {
    return this.http.post<any>(Api + `posthospitaldetails`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  gethospitaldetails() {
    return this.http.post<any>(Api + `gethospitaldetails`, []).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
  gethospitallimit() {
    return this.http.post<any>(Api + 'gethospitallimt10', []).pipe(map(res => {
      return res;
    }, (error) => {
      return error;
    }));
  }

  postactive_status(data) {
    return this.http.post<any>(Api + `postactive_status`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  delete_company(data) {
    return this.http.post<any>(Api + `delete_company`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  getmodulelistdata(usr_id) {
    var data = {
      usr_id: localStorage.getItem('usr_id'),
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getmodulelistdetails`, data)
  }

  submituserslogin(data) {
    return this.http.post<any>(Api + `submituserslogin`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  getpermissionlistcompanywise() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getpermissionlistcompanywise`, data)
  }

  deleteuser(data) {
    return this.http.post<any>(Api + `deleteuser`, data)
  }

  getdashotp(data) {
    return this.http.post<any>(Api + `getdashotp`, data)
  }

  loginwithotp(data) {
    return this.http.post<any>(Api + `loginwithotp`, data)
  }

  changePasswd(data) {
    return this.http.post<any>(Api + `changePasswd`, data)
  }

  getupdatemodules(user_id) {
    var data = {
      usr_id: user_id
    }
    return this.http.post<any>(Api + `getupdatemodules`, data)
  }

  postusermenulist(data) {
    return this.http.post<any>(Api + `postusermenulist`, data)
  }
  getlastsevendaysappointments() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getlastsevendaysappointments`, data)
  }
  getlastsevendayspatients() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getlastsevendayspatients`, data)
  }



  ///////////////////////////////////////////////////////////////////
  // getTotalApptmnts(data){
  //   return this.http.post<any>(Api + `getTotalAppmtns`, data)
  // }
  // getTodayApptmnts(data){
  //   return this.http.post<any>(Api + `getTodayAppmts` , data )
  // }
  ////////////////////////////////////////////////////////////////////
  // getOPPatientsTotal(data){
  //   return this.http.post<any>(Api + `getopptnstTotal` , data)
  // }
  // getoppateintsToday(data){
  //   return this.http.post<any>(Api + `getopptnstToday` , data)
  // }
  ///////////////////////////////////////////////////////////////////
  // getlabTotal(data){
  //   return this.http.post<any>(Api + `getlabsnumTotal` , data)
  // }
  // getlabToday(data){
  //   return this.http.post<any>(Api + `getlabsnumToday` , data)
  // }
  ///////////////////////////////////////////////////////////////////
  // getbedamntTotal(data){
  //   return this.http.post<any>(Api + `getbedamTotal` , data)
  // }
  // getbedamntToday(data){
  //   return this.http.post<any>(Api+ `getbedAmntToday` , data)
  // }
  // getdeptwsDoctros(){
  //   var data = {
  //     'company_id':localStorage.getItem('company_id')
  //   }
  //   return this.http.post<any>(Api + 'getdptdoctorsws' , data )
  // }
  //////////////////////////////////////////////////////////////////

}
