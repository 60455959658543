import { Component } from '@angular/core';

@Component({
  selector: 'app-prescriptedprint',
  templateUrl: './prescriptedprint.component.html',
  styleUrls: ['./prescriptedprint.component.scss']
})
export class PrescriptedprintComponent {

  details: any;
  main_patient: any;
  prescription_patient: any;
  medicinedata: any;
  testsdata: any;

  disable: boolean = false;
  constructor() {
    this.details = JSON.parse(localStorage.getItem('printdata'));

    this.main_patient = this.details[0][0];
    this.prescription_patient = this.details[1][0];
    this.medicinedata = this.details[2];
    this.testsdata = this.details[3]

  }

  public openPDF(): void {
    // this.disable = false;
    setTimeout(() => {
      window.print();
    }, 200);
  }

}
