import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../module.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent {
  form: FormGroup;

  breadCrumbItems: Array<{}>;
  data: any;
  patient_id: any;
  today: any;
  datare: any;
  now: any;
  doctordata: any;
  loadingpart: boolean = false;
  constructor(public formBuilder: UntypedFormBuilder, private myservice: ModuleService, public route: ActivatedRoute, private router: Router, public datePipe: DatePipe) {
    this.form = this.formBuilder.group({
      salutation: [""],
      name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      appointment_date: new FormControl(
        new Date().toISOString().substring(0, 10)
      ),
      time_slot: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      department_name: ["", [Validators.required]],
      doctor_name: ["", [Validators.required]],
      problem: ["", [Validators.required]],
      infosource: [""],
    });

    this.getappointmentdata();
    this.today = new Date();
    this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Amaravathi' }, { label: 'Add - Appointment', active: true }];
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    this.myservice.adddoctorreports(data).subscribe((res: any) => {
      this.doctordata = res.data
    })
  }

  getdep(evrnt) {

    var result = this.doctordata.find(o => o.doctor_name === evrnt);


    this.form.patchValue({
      department_name: result.doctor_dept
    })


  }


  typesubmit: boolean;
  get type() {
    return this.form.controls;
  }

  gotoprescriptionlist() {
    this.router.navigate(['/hospital/appointment-list']);
  }

  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getappointmentdata() {
    this.myservice.gettodayappointments().subscribe((res: any) => {
      this.data = res.data;
      this.changeFn();
    });
  }

  changeFn() {
    const search = this.now;
    this.data = this.data.filter(obj => {
      return !!JSON.stringify(Object.values(obj)).match(new RegExp(search, 'i'));
    });
  }

  typeSubmit() {
    this.loadingpart = true;
    this.typesubmit = true;
    if (this.form.invalid) {
      this.loadingpart = false;
      return;
    } else {
      this.form.value.company_id = localStorage.getItem('company_id');
      this.myservice.Add_appointment(this.form.value).subscribe((res: any) => {
        if (res.status == 200) {
          this.loadingpart = false;
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Successfully Submitted',
            showConfirmButton: false,
            timer: 1500
          });
          this.typesubmit = false;
          this.form.reset();
          this.getappointmentdata();
        } else {
          Swal.fire('Failed');
        }
      })
    }
  }

  reset() {
    this.form.reset();
  }

  printData(data) {
    sessionStorage.setItem('appointmentprint', JSON.stringify(data))
    this.router.navigate(['appointmentprint']);
  }
}

