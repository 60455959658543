<div class="container-fluid">
    <app-pagetitle title="Add-Patient" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="clcick()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Patient - list
                </button>

                <form (ngSubmit)="typeSubmit()" [formGroup]="typeValidationForm">
                    <br>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Salutation <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select id="inputstate" id="default-select" formControlName="salutation">
                                    <ng-option value="" disabled Selected>Select the Option</ng-option>
                                    <ng-option>Mr.</ng-option>
                                    <ng-option>Mrs.</ng-option>
                                    <ng-option>Miss.</ng-option>
                                    <ng-option>Master.</ng-option>
                                    <ng-option>Baby.</ng-option>
                                    <ng-option>Ms.</ng-option>
                                    <ng-option>Dr.</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>First Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="first_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.first_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.first_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Last Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="last_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.last_name.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.last_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.last_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="age" type="text" minlength="2" maxlength="2"
                                        class="form-control" [ngClass]="{'is-invalid': typesubmit && type.age.errors}"
                                        placeholder="Enter only numbers" (keypress)="numericOnly($event)">
                                    <div *ngIf="typesubmit && type.age.errors" class="invalid-feedback">
                                        <span *ngIf="type.age.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Phone Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="phone_number" type="text" minlength="10" maxlength="10"
                                        class="form-control" (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.phone_number.errors}"
                                        placeholder="Enter only numbers">
                                    <div *ngIf="typesubmit && type.phone_number.errors" class="invalid-feedback">
                                        <span *ngIf="type.phone_number.errors.required">This value is required.</span>
                                        <span *ngIf="type.phone_number.errors.pattern">This value should be a valid
                                            Phone Number.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Date of Birth <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="date" [max]="now" class="form-control" formControlName="dob"
                                    [ngClass]="{'is-invalid': typesubmit && type.dob.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.dob.errors" class="invalid-feedback">
                                    <span *ngIf="type.dob.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Guardian (optional)</label>
                                <input type="text" class="form-control" formControlName="guardian"
                                    [ngClass]="{'is-invalid': typesubmit && type.guardian.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.guardian.errors" class="invalid-feedback">
                                    <span *ngIf="type.guardian.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Aadhar (optional)</label>
                                <input type="text " class="form-control" formControlName="aadhar" minlength="12"
                                    maxlength="12" [ngClass]="{'is-invalid': typesubmit && type.aadhar.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.aadhar.errors" class="invalid-feedback">
                                    <span *ngIf="type.aadhar.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <ng-select id="inputstate" id="default-select" formControlName="gender"
                                        [ngClass]="{'is-invalid': typesubmit && type.gender.errors}">
                                        <ng-option value="" selected>Select</ng-option>
                                        <ng-option value="Male">Male</ng-option>
                                        <ng-option value="Female">Female</ng-option>
                                        <ng-option value="Other">Other</ng-option>
                                    </ng-select>
                                    <div *ngIf="typesubmit && type.gender.errors" class="invalid-feedback">
                                        <span *ngIf="type.gender.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Consultant Fees <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="consultant_fee"
                                    [ngClass]="{'is-invalid': typesubmit && type.consultant_fee.errors}"
                                    placeholder="Type something" (keypress)="numericOnly($event)" />
                                <div *ngIf="typesubmit && type.consultant_fee.errors" class="invalid-feedback">
                                    <span *ngIf="type.consultant_fee.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>No.of days to Expiry<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="days_to_expiry"
                                    [ngClass]="{'is-invalid': typesubmit && type.days_to_expiry.errors}"
                                    placeholder="Type something" (keypress)="numericOnly($event)" />
                                <div *ngIf="typesubmit && type.days_to_expiry.errors" class="invalid-feedback">
                                    <span *ngIf="type.days_to_expiry.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Address (optional)</label>
                                <textarea type="number" class="form-control" formControlName="address"
                                    [ngClass]="{'is-invalid': typesubmit && type.address.errors}"
                                    placeholder="Type something" rows="3"></textarea>
                                <div *ngIf="typesubmit && type.address.errors" class="invalid-feedback">
                                    <span *ngIf="type.address.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Doctor Name</label>
                                <!-- <input type="text" class="form-control" formControlName="d_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.d_name.errors}"
                                    placeholder="Type something" /> -->
                                <ng-select (change)="autoFdoctor($event)" formControlName="d_name">
                                    <ng-option disabled>Select Doctor</ng-option>
                                    <ng-option value="{{a.doctor_name}}"
                                        *ngFor="let a of doctorData">{{a.doctor_name}}</ng-option>
                                </ng-select>
                                <div *ngIf="typesubmit && type.d_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.d_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Department Name</label>
                                <input type="text" class="form-control" formControlName="dpt_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.dpt_name.errors}"
                                    placeholder="Type something" readonly />
                                <div *ngIf="typesubmit && type.dpt_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.dpt_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Patient Brief</label>
                                <textarea type="number" class="form-control" formControlName="description"
                                    [ngClass]="{'is-invalid': typesubmit && type.address.errors}"
                                    placeholder="Type something" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="form-group m-3" style="text-align: right;">
                            <button type="submit" class="btn btn-primary me-1"> Submit </button>
                            <button type="reset" class="btn btn-secondary"> Reset </button>
                        </div>
                        <div>
                            <button type="button" (click)="addvital(centerDataModal)" class="btn btn-info">ADD
                                VITALS</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>




<ng-template #centerDataModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Add Vitals</h5>
        <button type="button" class="btn-close" (click)="close()" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="typeValidationForm">
            <div class="row">

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Blood Pressure (optional)</label>
                        <input type="text" class="form-control" formControlName="blood_pressure"
                            (keypress)="numericOnly($event)"
                            [ngClass]="{'is-invalid': typesubmit && type.blood_pressure.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.blood_pressure.errors" class="invalid-feedback">
                            <span *ngIf="type.blood_pressure.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Sugar (optional)</label>
                        <input type="text" class="form-control" formControlName="sugar"
                            [ngClass]="{'is-invalid': typesubmit && type.sugar.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.sugar.errors" class="invalid-feedback">
                            <span *ngIf="type.sugar.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Weight (optional)</label>
                        <input type="text" class="form-control" formControlName="weight"
                            (keypress)="numericOnly($event)"
                            [ngClass]="{'is-invalid': typesubmit && type.weight.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.weight.errors" class="invalid-feedback">
                            <span *ngIf="type.weight.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Height (optional)</label>
                        <input type="number" class="form-control" formControlName="height"
                            [ngClass]="{'is-invalid': typesubmit && type.height.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.height.errors" class="invalid-feedback">
                            <span *ngIf="type.height.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Others (optional)</label>
                        <input type="text" class="form-control" formControlName="others"
                            [ngClass]="{'is-invalid': typesubmit && type.others.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.others.errors" class="invalid-feedback">
                            <span *ngIf="type.others.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Patient Details Data </h4>
                <div class="table-responsive">
                    <table class="table table-bordered mb-0 ">
                        <thead>
                            <tr>
                                <th></th>
                                <th>S.no</th>
                                <th>Patient ID</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Gender</th>
                                <th>Mobile Number</th>
                                <th>Date of Birth </th>
                                <th>Expiry Date </th>
                                <th>Guardian </th>
                                <th>Actions </th>
                                <th>Prints </th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let i of data;let y =index">
                            <tr>
                                <td align="center">
                                    <a class="expand-row" (click)="changeValue(y)">
                                        +
                                    </a>
                                </td>
                                <th scope="row">{{y+1}}</th>
                                <td>{{i.patient_id}}</td>
                                <td>{{i.first_name}} {{i.last_name}}</td>
                                <td>{{i.age}}</td>
                                <td>{{i.gender}}</td>
                                <td>{{i.phone_number}}</td>
                                <td>{{i.dob | date:'dd-MM-yyyy'}}</td>
                                <td>{{i.expiry_date | date:'dd-MM-yyyy'}}</td>
                                <td>{{i.guardian}}</td>
                                <td>
                                    <div>
                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 4%;"
                                            class="ri-edit-fill" title="Edit"
                                            (click)="editPatientDetails(i,editFormTempo)"></i>
                                    </div>
                                </td>
                                <td>
                                    <a>
                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                            class="fa fa-print" (click)="printData(i)"></i></a>
                                </td>
                            </tr>
                            <tr *ngIf="!hideme[y]" class="sub-table">
                                <td align="center"> </td>
                                <td colspan="15" class="p-0">
                                    <table class="table mb-0 table-striped">
                                        <thead>
                                            <tr>
                                                <th>Doctor Name</th>
                                                <td>:</td>
                                                <td>{{i.doctor_name}}</td>
                                            </tr>
                                            <tr>
                                                <th>Aadhar</th>
                                                <td>:</td>
                                                <td>{{i.aadhar}}</td>
                                            </tr>
                                            <tr>
                                                <th>Consultant Fees</th>
                                                <td>:</td>
                                                <td>{{i.consultant_fee}}</td>
                                            </tr>
                                            <tr>
                                                <th>Days to Expiry</th>
                                                <td>:</td>
                                                <td>{{i.days_to_expiry}}</td>
                                            </tr>
                                            <tr>
                                                <th>Date of Entry</th>
                                                <td>:</td>
                                                <td>{{i.date | date}}</td>
                                            </tr>
                                            <tr>
                                                <th>Address</th>
                                                <td>:</td>
                                                <td>{{i.address}}</td>
                                            </tr>
                                            <tr>
                                                <th>Blood pressure</th>
                                                <td>:</td>
                                                <!-- <td>{{i.blood_pressure}}</td> -->
                                                <td
                                                    *ngIf="i.blood_pressure !== null && i.blood_pressure !== undefined; else hyphen">
                                                    {{i.blood_pressure}}</td>
                                                <ng-template #hyphen>-</ng-template>
                                            </tr>
                                            <tr>
                                                <th>Weight</th>
                                                <td>:</td>
                                                <!-- <td>{{i.weight}}</td> -->
                                                <td *ngIf="i.weight !== null && i.weight !== undefined; else hyphen">
                                                    {{i.weight}}</td>
                                                <ng-template #hyphen>-</ng-template>
                                            </tr>
                                            <tr>
                                                <th>Sugar</th>
                                                <td>:</td>
                                                <!-- <td>{{i.sugar}}</td> -->
                                                <td *ngIf="i.sugar !== null && i.sugar !== undefined; else hyphen">
                                                    {{i.sugar}}</td>
                                                <ng-template #hyphen>-</ng-template>
                                            </tr>
                                            <tr>
                                                <th>Height</th>
                                                <td>:</td>
                                                <!-- <td>{{i.address}}</td> -->
                                                <td *ngIf="i.height !== null && i.height !== undefined; else hyphen">
                                                    {{i.height}}</td>
                                                <ng-template #hyphen>-</ng-template>
                                            </tr>
                                            <tr>
                                                <th>Others</th>
                                                <td>:</td>
                                                <!-- <td>{{i.others}}</td> -->
                                                <td *ngIf="i.others !== null && i.others !== undefined; else hyphen">
                                                    {{i.others}}</td>
                                                <ng-template #hyphen>-</ng-template>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-overlay" *ngIf="loadingpart">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>



<ng-template #editFormTempo let modal;>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Edit Patient </h5>
        <button type="button" class="btn-close" (click)="dismiss(editFormTempo)" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editForm">

            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>First Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="first_name"
                            [ngClass]="{'is-invalid': submitted && valid.first_name.errors}"
                            placeholder="Type something" />
                        <div *ngIf="submitted && valid.first_name.errors" class="invalid-feedback">
                            <span *ngIf="valid.first_name.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Last Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="last_name"
                            [ngClass]="{'is-invalid': submitted && valid.last_name.errors}"
                            placeholder="Type something" />
                        <div *ngIf="submitted && valid.last_name.errors" class="invalid-feedback">
                            <span *ngIf="valid.last_name.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <div>
                            <input formControlName="age" type="text" minlength="2" maxlength="2" class="form-control"
                                [ngClass]="{'is-invalid': submitted && valid.age.errors}"
                                placeholder="Enter only numbers">
                            <div *ngIf="submitted && valid.age.errors" class="invalid-feedback">
                                <span *ngIf="valid.age.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Phone Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <div>
                            <input formControlName="phone_number" type="text" minlength="10" maxlength="10"
                                class="form-control" [ngClass]="{'is-invalid': submitted && valid.phone_number.errors}"
                                placeholder="Enter only numbers">
                            <div *ngIf="submitted && valid.phone_number.errors" class="invalid-feedback">
                                <span *ngIf="valid.phone_number.errors.required">This value is required.</span>
                                <span *ngIf="valid.phone_number.errors.pattern">This value should be a valid
                                    Phone Number.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Date of Birth <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="date" class="form-control" formControlName="dob"
                            [ngClass]="{'is-invalid': submitted && valid.dob.errors}" placeholder="Type something" />
                        <div *ngIf="submitted && valid.dob.errors" class="invalid-feedback">
                            <span *ngIf="valid.dob.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Guardian (optional)</label>
                        <input type="text" class="form-control" formControlName="guardian"
                            [ngClass]="{'is-invalid': submitted && valid.guardian.errors}"
                            placeholder="Type something" />
                        <div *ngIf="submitted && valid.guardian.errors" class="invalid-feedback">
                            <span *ngIf="valid.guardian.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Aadhar (optional)</label>
                        <input type="text " class="form-control" formControlName="aadhar" minlength="12" maxlength="12"
                            [ngClass]="{'is-invalid': submitted && valid.aadhar.errors}" placeholder="Type something" />
                        <div *ngIf="submitted && valid.aadhar.errors" class="invalid-feedback">
                            <span *ngIf="valid.aadhar.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <div>
                            <select formControlName="gender" class="form-control"
                                [ngClass]="{'is-invalid': submitted && valid.gender.errors}">
                                <option value="" selected>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <div *ngIf="submitted && valid.gender.errors" class="invalid-feedback">
                                <span *ngIf="valid.gender.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Address (optional)</label>
                        <textarea type="number" class="form-control" formControlName="address"
                            [ngClass]="{'is-invalid': submitted && valid.address.errors}" placeholder="Type something"
                            rows="3"></textarea>
                        <div *ngIf="submitted && valid.address.errors" class="invalid-feedback">
                            <span *ngIf="valid.address.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="editData()" class="btn btn-primary me-1"> Save changes </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>