import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/account/service.service';
import * as ApexCharts from 'apexcharts';
import { ReportsserviceService } from 'src/app/mis-reports/reportsservice.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {

  barChart: any;
  basicRadialBarChart: any;
  lineBarChart: any;
  columnlabelChart: any;
  simplePieChart: any;
  basicColumChart: any;

  constructor(public service: ServiceService, private misServ: ReportsserviceService,) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Amaravathi' }, { label: 'Dashboard', active: true }];
    // this.getAppointmntsCount();
    // this.getOPPatientsCount();
    // this.getLabSCount();
    // this.getBedScountam()
    // this.horzBar();
    // this.getlastsevendayLab();
    // this.forPiechart();
    // this.getDoctorsgraph()



    this.barChart = {
      series: [{
        data: [30, 43, 45, 47, 55, 58, 78]
      }],
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#1cbb8c'],
      xaxis: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      },
      grid: {
        borderColor: '#f1f1f1',
        padding: {
          bottom: 5
        }
      }
    };

    this.columnlabelChart = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      colors: ['#5664d2'],
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      series: [{
        name: 'Inflation',
        data: [2.5, 3.2, 5.0, 10.1, 4.2, 3.8, 3, 2.4, 4.0, 1.2, 3.5, 0.8]
      }],
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        position: 'top',
        labels: {
          offsetY: -18,
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        }
      },
      fill: {
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        },
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: (val) => {
            return val + '%';
          }
        }
      },
      title: {
        text: 'Appointments of 2023',
        floating: true,
        offsetY: 320,
        align: 'center',
        style: {
          color: '#444'
        }
      },
      legend: {
        offsetY: 7
      }
    };

    this.simplePieChart = {
      chart: {
        height: 330,
        type: 'pie',
      },
      series: [44, 55, 41, 17],
      labels: ['Social Media', 'Walk-in', 'Reference', 'Famous Doctor'],
      colors: ['#1cbb8c', '#fcb92c', '#4aa3ff', '#ff3d60'],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        floating: false,
        fontSize: '14px',
        offsetX: 0,
        offsetY: -10
      },
      responsive: [{
        breakpoint: 600,
        options: {
          chart: {
            height: 350
          },
          legend: {
            show: false
          },
        }
      }]
    }


    this.basicColumChart = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          columnWidth: '45%',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: ['#5664d2', '#1cbb8c', '#fcb92c'],
      series: [{
        name: 'Net Profit',
        data: [46, 57, 59, 54, 62, 58, 64, 60, 66, 14, 25, 48]
      }, {
        name: 'Revenue',
        data: [74, 83, 102, 97, 86, 106, 93, 114, 94, 45, 47, 45]
      }, {
        name: 'Free Cash Flow',
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43, 47, 45, 78]
      }],
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yaxis: {
        title: {
          text: '₹ (thousand)'
        }
      },
      fill: {
        opacity: 1
      },
      grid: {
        borderColor: '#f1f1f1'
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return '₹ ' + val + ' thousand';
          }
        }
      }
    };


  }



  getlastsevendaysappointment() {
    this.service.getlastsevendaysappointments().subscribe((res: any) => {
      var totaltappalacdatent = res.data.map(function (obj) {
        return obj.tcnt;
      });
      var totaltappalacdatentdates = res.data.map(function (obj) {
        return obj.total;
      });

      var options = {
        chart: {
          height: 310,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return parseInt(val);
          },
          offsetY: -20,
          style: {
            fontSize: '14px',
            colors: ["#304758"]
          }
        },
        stroke: {
          width: 0
        },
        series: [{
          name: 'Appointments',
          data: totaltappalacdatent
        }],
        xaxis: {
          categories: totaltappalacdatentdates,
          position: 'bottom',
          labels: {
            offsetY: 0,
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          }
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
        },
        grid: {
          show: true,
          borderColor: 'rgb(19, 183, 203)',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['rgb(19, 183, 203)'],
            shadeIntensity: 1,
            type: 'vertical',
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        },
        colors: ["rgb(19, 183, 203)"],
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return parseInt(val);
            }
          }
        },
        title: {
          text: 'Last Seven  Appointments Data',
          floating: true,
          offsetY: 0,
          align: 'left',
          style: {
            fontSize: '15px',
            color: '#444',

          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 310
            },
            legend: {
              position: 'bottom'
            },
            title: {
              text: 'Last Seven  Appointments Data',
              floating: true,
              offsetY: 0,
              align: 'left',
              style: {
                fontSize: '13px',
                color: '#444'
              }
            }
          }
        }]
      }
      var chart = new ApexCharts(
        document.querySelector("#lastsevendaysappointment"),
        options
      );
      chart.render();
    }, er => {
    })
  }

  // getlastsevendayspatients() {
  //   this.service.getlastsevendayspatients().subscribe((res: any) => {
  //     var totaltappalacdatent = res.data.map(function (obj) {
  //       return obj.tcnt;
  //     });
  //     var totaltappalacdatentdates = res.data.map(function (obj) {
  //       return obj.total;
  //     });

  //     var options = {
  //       chart: {
  //         height: 310,
  //         type: 'bar',
  //         toolbar: {
  //           show: false
  //         }
  //       },
  //       plotOptions: {
  //         bar: {
  //           columnWidth: '50%',
  //           endingShape: 'rounded',
  //           dataLabels: {
  //             position: 'top', // top, center, bottom
  //           },
  //         }
  //       },
  //       dataLabels: {
  //         enabled: true,
  //         formatter: function (val) {
  //           return parseInt(val);
  //         },
  //         offsetY: -20,
  //         style: {
  //           fontSize: '14px',
  //           colors: ["#8197d1"]
  //         }
  //       },
  //       stroke: {
  //         width: 0
  //       },
  //       series: [{
  //         name: 'Patients',
  //         data: totaltappalacdatent
  //       }],
  //       xaxis: {
  //         categories: totaltappalacdatentdates,
  //         position: 'bottom',
  //         labels: {
  //           offsetY: 0,
  //         },
  //         axisBorder: {
  //           show: true
  //         },
  //         axisTicks: {
  //           show: true
  //         }
  //       },
  //       tooltip: {
  //         enabled: true,
  //         theme: 'dark',
  //       },
  //       grid: {
  //         show: true,
  //         borderColor: '#8197d1',
  //       },
  //       fill: {
  //         type: 'gradient',
  //         gradient: {
  //           shade: 'dark',
  //           gradientToColors: ['#8197d1'],
  //           shadeIntensity: 1,
  //           type: 'vertical',
  //           inverseColors: false,
  //           opacityFrom: 1,
  //           opacityTo: 1,
  //           stops: [0, 100, 100, 100]
  //         },
  //       },
  //       colors: ["#8197d1"],
  //       yaxis: {
  //         axisBorder: {
  //           show: false
  //         },
  //         axisTicks: {
  //           show: false,
  //         },
  //         labels: {
  //           show: false,
  //           formatter: function (val) {
  //             return parseInt(val);
  //           }
  //         }
  //       },
  //       title: {
  //         text: 'Last Seven Patients Data',
  //         floating: true,
  //         offsetY: 0,
  //         align: 'left',
  //         style: {
  //           fontSize: '15px',
  //           color: '#444'
  //         }
  //       },
  //       responsive: [{
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             height: 310
  //           },
  //           legend: {
  //             position: 'bottom'
  //           },
  //           title: {
  //             text: 'Last Seven  Patients Data',
  //             floating: true,
  //             offsetY: 0,
  //             align: 'left',
  //             style: {
  //               fontSize: '13px',
  //               color: '#444'
  //             }
  //           }
  //         }
  //       }]
  //     }
  //     var chart = new ApexCharts(
  //       document.querySelector("#lastsevendayspatients"),
  //       options
  //     );
  //     chart.render();
  //   }, er => {
  //   })
  // }


  totalAppmnt: any;
  todayAppmnt: any;
  // getAppointmntsCount() {
  //   var data = {
  //     'company_id': localStorage.getItem('company_id')
  //   }
  //   this.service.getTotalApptmnts(data).subscribe((res) => {
  //     this.totalAppmnt = res.data[0].count
  //   })
  //   this.service.getTodayApptmnts(data).subscribe((res) => {
  //     this.todayAppmnt = res.data[0].count
  //   })
  // }
  totalops: any;
  todayops: any;
  // getOPPatientsCount() {
  //   var data = { 'company_id': localStorage.getItem('company_id') }
  //   this.service.getOPPatientsTotal(data).subscribe((res) => {
  //     this.totalops = res.data[0].count
  //   })
  //   this.service.getoppateintsToday(data).subscribe((res) => {
  //     this.todayops = res.data[0].count
  //   })
  // }
  totallabs: any;
  todaylab: any;
  // getLabSCount() {
  //   var data = {
  //     'company_id': localStorage.getItem('company_id')
  //   }
  //   this.service.getlabTotal(data).subscribe((res) => {
  //     this.totallabs = res.data[0].count
  //   })
  //   this.service.getlabToday(data).subscribe((res) => {
  //     this.todaylab = res.data[0].count
  //   })
  // }
  totalbed: any;
  todaybed: any;
  // getBedScountam() {
  //   var data = {
  //     'company_id': localStorage.getItem("company_id")
  //   }
  //   this.service.getbedamntTotal(data).subscribe((res) => {
  //     this.totalbed = res.data[0].count
  //   })
  //   this.service.getbedamntToday(data).subscribe((res) => {
  //     this.todaybed = res.data[0].count
  //   })
  // }




  forMonthPatients: any = [];
  // horzBar() {
  //   this.misServ.getMonthWisePatients().subscribe((res) => {
  //     this.forMonthPatients = res.data
  //     var options = {
  //       series: [{ data: [] }],
  //       chart: {
  //         type: 'bar',
  //         height: 350
  //       },
  //       plotOptions: {
  //         bar: {
  //           borderRadius: 4,
  //           horizontal: true,
  //         }
  //       },
  //       dataLabels: {
  //         enabled: false
  //       },
  //       xaxis: {
  //         categories: [
  //         ],
  //       },
  //       colors: ['#fbd076'],
  //       title: {
  //         text: 'Month Wise Patients',
  //         align: 'left',
  //         margin: 10,
  //         offsetX: 0,
  //         offsetY: 0,
  //         floating: false,
  //         style: {
  //           fontSize: '16px',
  //           fontWeight: 'bold',
  //           color: '#263238'
  //         }
  //       }
  //     };
  //     this.forMonthPatients.map((res) => {
  //       options.series[0].data.push(res.patient_count)
  //       options.xaxis.categories.push(res.month)
  //     })
  //     var chart = new ApexCharts(document.querySelector("#chart1"), options);
  //     chart.render();
  //   })
  // }

  // getlastsevendayLab() {
  //   this.misServ.last7daysLabsIncome().subscribe((res) => {
  //     var totalLabIncomes = res.data.map(function (obj) {

  //       return obj.each_day_amount;
  //     });
  //     var totalLabDates = res.data.map(function (obj) {
  //       return obj.date;
  //     });
  //     var options = {
  //       series: [{ data: totalLabIncomes }],
  //       chart: {
  //         type: 'bar',
  //         height: 350
  //       },
  //       plotOptions: {
  //         bar: {
  //           borderRadius: 4,
  //           horizontal: false,
  //         }
  //       },
  //       dataLabels: {
  //         enabled: true
  //       },
  //       xaxis: {
  //         categories: totalLabDates,
  //       },
  //       colors: ['#f3a8b5'],
  //       title: {
  //         text: 'Last Seven Days Labs Income',
  //         align: 'left',
  //         margin: 10,
  //         offsetX: 0,
  //         offsetY: 0,
  //         floating: false,
  //         style: {
  //           fontSize: '16px',
  //           fontWeight: 'bold',
  //           color: '#263238'
  //         }
  //       }
  //     };
  //     var chart = new ApexCharts(document.querySelector("#chart3"), options);
  //     chart.render();
  //   })

  // }

  forStoringData: any = []
  // forPiechart() {
  //   this.misServ.getRoomsOccupancy().subscribe((res) => {
  //     this.forStoringData = res.data

  //     var options = {
  //       series: [this.forStoringData[0].total_rooms, this.forStoringData[0].available_rooms,
  //       this.forStoringData[0].occupied_rooms, this.forStoringData[0].in_cleaning],
  //       chart: {
  //         width: 500,
  //         type: 'pie',
  //       },
  //       labels: ['Total Rooms', 'Availability Rooms', 'Occupied Rooms', 'In Cleaning Stage'],
  //       colors: ['#5664d2', '#fcb92c', '#1cbb8c', '#ff3d60'],
  //       responsive: [{
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 150
  //           },
  //           legend: {
  //             position: 'bottom'
  //           }
  //         }
  //       }]
  //     };

  //     var chart = new ApexCharts(document.querySelector("#chart"), options);
  //     chart.render();


  //   })
  // }

  doctorData: any;
  grandTotal: any;
  // getDoctorsgraph() {
  //   this.service.getdeptwsDoctros().subscribe((res) => {
  //     this.doctorData = res.data



  //     this.basicRadialBarChart = {
  //       chart: {
  //         height: 320,
  //         type: 'radialBar',
  //       },
  //       plotOptions: {
  //         radialBar: {
  //           dataLabels: {
  //             name: {
  //               fontSize: '22px',
  //             },
  //             value: {
  //               fontSize: '16px',
  //             },
  //             total: {
  //               show: true,
  //               label: 'Total',
  //               formatter: (w) => {
  //                 return this.grandTotal;
  //               }
  //             }
  //           }
  //         }
  //       },
  //       colors: ['#5664d2', '#fcb92c', '#1cbb8c', '#ff3d60', '#1cbb8c', '#fcb92c'],
  //       series: [],
  //       labels: [],
  //     };
  //     this.grandTotal = 0;
  //     this.doctorData.map((res) => {
  //       this.basicRadialBarChart.series.push(res.count)
  //       this.basicRadialBarChart.labels.push(res.doctor_dept)
  //       this.grandTotal += res.count
  //     })
  //   })
  // }
}