<div class="container-fluid">
  <app-pagetitle title="Analysis" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">

  <div class="col-md-3">
    <div class="card" style="background-color: #3ec59d; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Total Appointments</p>
            <h4 class="mb-0">2</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-md-3">
    <div class="card" style="background-color: #f3a8b5; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Today Appointments</p>
            <h4 class="mb-0">1</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card" style="background-color: #8197d1; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Total OP Patients</p>
            <h4 class="mb-0">0</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card" style="background-color: #fbd076; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Today OP patients</p>
            <h4 class="mb-0">12</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-md-3">
    <div class="card" style="background-color: #3ec59d; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Total Bed Admits</p>
            <h4 class="mb-0">5</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card" style="background-color: #f3a8b5; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Today Bed Admits</p>
            <h4 class="mb-0">14</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card" style="background-color: #8197d1; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Total Lab patients</p>
            <h4 class="mb-0">88</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card" style="background-color: #fbd076; border-radius: 12px;">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate font-size-14 mb-2">Today Lab patients</p>
            <h4 class="mb-0">5</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class=" font-size-24"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- 
<div class="container-fluid">
  <div class="row">


    <div class="col-6 col-lg-6 col-xl-6">
      <div class="card radius-10">
        <div class="card-body">
          <div id="chart1"></div>
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-6 col-xl-6">
      <div class="card radius-10">
        <div class="card-body">
          <div id="chart3"></div>
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-6 col-xl-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-2" style="font-size: 16px;font-weight: bold;color: #263238;">Rooms Availability</h4>
          <div id="chart"></div>
        </div>
      </div>
    </div>





  </div>
</div> -->
<div class="container-fluid">
  <div class="row">

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Team Analysis</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="barChart.series" [chart]="barChart.chart"
            [plotOptions]="barChart.plotOptions" [dataLabels]="barChart.dataLabels" [xaxis]="barChart.xaxis"
            [colors]="barChart.colors" [grid]="barChart.grid"></apx-chart>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">User Analysis</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="simplePieChart.series" [chart]="simplePieChart.chart"
            [labels]="simplePieChart.labels" [legend]="simplePieChart.legend" [colors]="simplePieChart.colors"
            [responsive]="simplePieChart.responsive">
          </apx-chart>
        </div>
      </div>
    </div>

   
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Total Analysis</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="columnlabelChart.series" [chart]="columnlabelChart.chart"
            [plotOptions]="columnlabelChart.plotOptions" [yaxis]="columnlabelChart.yaxis"
            [title]="columnlabelChart.title" [stroke]="columnlabelChart.stroke"
            [dataLabels]="columnlabelChart.dataLabels" [xaxis]="columnlabelChart.xaxis"
            [legend]="columnlabelChart.legend" [colors]="columnlabelChart.colors" [fill]="columnlabelChart.fill">
          </apx-chart>
        </div>
      </div>
      <!--end card-->
    </div>
    <!-- <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Total Analysis</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="basicRadialBarChart.series"
            [chart]="basicRadialBarChart.chart" [plotOptions]="basicRadialBarChart.plotOptions"
            [labels]="basicRadialBarChart.labels" [colors]="basicRadialBarChart.colors">
          </apx-chart>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Month wise Analysis</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart"
            [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid"
            [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
            [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors"
            [fill]="basicColumChart.fill">
          </apx-chart>
        </div>
      </div>
    </div> -->
  </div>
</div>
<!-- <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4" style="font-weight: 700;">Total Doctors Departments Wise</h4>
        <apx-chart class="apex-charts" dir="ltr" [series]="basicRadialBarChart.series"
          [chart]="basicRadialBarChart.chart" [plotOptions]="basicRadialBarChart.plotOptions"
          [labels]="basicRadialBarChart.labels" [colors]="basicRadialBarChart.colors">
        </apx-chart>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">Revenue</h4>
        <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart"
          [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid"
          [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
          [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors"
          [fill]="basicColumChart.fill">
        </apx-chart>
      </div>
    </div>
  </div> -->