<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">User Permission List</h4>
                <p class="card-sub-title">
                    <!-- Example of datatable. -->
                </p>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <label class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries
                            </label>
                        </div>
                    </div>
                      <div class="col-sm-12 col-md-2" style="text-align: left"><div class="form-group mb-0" style="text-align: right;" >
                            <button  class="btn btn-secondary me-1"(click)="downloadExcel()" >Excel</button>
                            <button class="btn btn-secondary"(click)="downloadPdf()" > PDF</button>
                        </div></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                                class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th sortable="name" (sort)="onSort($event)">Name</th>
                                <th sortable="number" (sort)="onSort($event)">Number</th>
                                <th sortable="hospital_name" (sort)="onSort($event)">Email</th>
                                <th>Main Modules</th>
                                <th>Sub Modules</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let table of tables$ | async;let i=index">
                            <tr>
                                <td>{{i+1}}</td>
                                <td>
                                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.number" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.email" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ul>
                                        <li *ngFor="let item of table.module_nm " style="text-align:left !important;">
                                            {{item}}</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li *ngFor="let item of table.sub_menu " style="text-align:left !important;">
                                            {{item}}</li>
                                    </ul>
                                </td>
                                <td>
                                    <i style="font-size: 20px;border-radius:5px;background-color: rgb(31, 115, 31);color: white;padding: 4%;"
                                        class="ri-edit-fill" title="Edit"  (click)="centerModal(centerDataModal,table)"></i>&nbsp;&nbsp;
                                    <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 4%;"
                                        class="ri-delete-bin-2-line" title="Delete" (click)="Delete(table)"></i>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tr *ngIf="tables$.length==0">
                            <td colspan="7" style="text-align: center;padding: 2%;font-size: 20px;font-weight: bolder;">
                                <span> No data Found</span>
                            </td>
                        </tr> -->
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-end float-md-end s pagination-rounded">
                            <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #centerDataModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">{{userdetails.name}}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
    </div>
    <div class="modal-body">
        <div class="modal-body">
            <div class="card-body">

                <div class="row" *ngIf="totalprojectlist.length>0">
                    <div class="col-12 col-lg-12 col-xl-12" *ngFor="let item of totalprojectlist; let i=index;" style="    padding: 22px;
                        border-style: groove;">
                        <div class="row">
                            <div class="col-md-10">
                                <a href="javascript:;" (click)="openprojectmodules(item.subcollopescnd,item)">
                                    <h5>{{item.label}} </h5>
                                </a>
                            </div>
                            <div class="col-md-2">
                                <label class="container"
                                    for="user-checkbox{{item.label}}00"><b>{{item.checkallstatus}}</b>
                                    <input type="checkbox" [checked]="item.checkallmodules"
                                        id="user-checkbox{{item.label}}00"
                                        (change)="checkallmoduledata(item.checkallmodules,item.submenu,item)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3" *ngFor="let subitem of item.submenu; let j=index;">
                                <label class="container"
                                    for="user-checkbox{{item.label}}{{subitem.check_sub_menu}}{{i}}{{j}}">{{subitem.label}}
                                    <input type="checkbox"
                                        id="user-checkbox{{item.label}}{{subitem.check_sub_menu}}{{i}}{{j}}"
                                        [checked]="subitem.check_sub_menu"
                                        (change)="selectedsubmodules(subitem.check_sub_menu,subitem,item)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col-lg-12">
                        <button type="submit" class="btn btn-primary"
                            (click)="updateuserpermissionlist(user_id)">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
