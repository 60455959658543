<div class="container-fluid">
    <app-pagetitle title="Add-Appointment" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <button type="button" class="btn btn-success" (click)="gotoprescriptionlist()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Appointment-List
                </button>
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Salutation <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select id="inputstate" id="default-select" formControlName="salutation">
                                    <ng-option value="" disabled Selected>Select the Option</ng-option>
                                    <ng-option>Mr.</ng-option>
                                    <ng-option>Mrs.</ng-option>
                                    <ng-option>Miss.</ng-option>
                                    <ng-option>Master.</ng-option>
                                    <ng-option>Baby.</ng-option>
                                    <ng-option>Ms.</ng-option>
                                    <ng-option>Dr.</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>First Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{'is-invalid': typesubmit && type.name.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.name.errors" class="invalid-feedback">
                                    <span *ngIf="type.name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Last Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="last_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.last_name.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.last_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.last_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="age" type="text" minlength="2" maxlength="2"
                                        class="form-control" [ngClass]="{'is-invalid': typesubmit && type.age.errors}"
                                        placeholder="Enter only numbers" (keypress)="numericOnly($event)">
                                    <div *ngIf="typesubmit && type.age.errors" class="invalid-feedback">
                                        <span *ngIf="type.age.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Phone Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="phone_number" type="text" minlength="10" maxlength="10"
                                        class="form-control" (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.phone_number.errors}"
                                        placeholder="Enter only numbers">
                                    <div *ngIf="typesubmit && type.phone_number.errors" class="invalid-feedback">
                                        <span *ngIf="type.phone_number.errors.required">This value is required.</span>
                                        <span *ngIf="type.phone_number.errors.pattern">This value should be a valid
                                            Phone Number.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Date of Birth <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="date" [max]="now" class="form-control" formControlName="dob"
                                    [ngClass]="{'is-invalid': typesubmit && type.dob.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.dob.errors" class="invalid-feedback">
                                    <span *ngIf="type.dob.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <select formControlName="gender" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.gender.errors}">
                                        <option value="" selected>Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div *ngIf="typesubmit && type.gender.errors" class="invalid-feedback">
                                        <span *ngIf="type.gender.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 class="card-title">
                            <br><b>Enter Appointment details :- </b><br>
                        </h3>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Appointment Date<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="date" [min]="now" class="form-control" formControlName="appointment_date"
                                    [ngClass]="{'is-invalid': typesubmit && type.appointment_date.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.appointment_date.errors" class="invalid-feedback">
                                    <span *ngIf="type.appointment_date.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Time Slot <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <!-- <input type="time" step="00:15" class="form-control" formControlName="time_slot"
                                    [ngClass]="{'is-invalid': typesubmit && type.time_slot.errors}"
                                    placeholder="Type something" /> -->

                                <ng-select formControlName="time_slot"
                                    [ngClass]="{'is-invalid': typesubmit && type.time_slot.errors}">
                                    <ng-option value="" selected>Select the Time</ng-option>
                                    <ng-option value="08:00 AM">08:00 AM</ng-option>
                                    <ng-option value="08:15 AM">08:15 AM</ng-option>
                                    <ng-option value="08:30 AM">08:30 AM</ng-option>
                                    <ng-option value="08:45 AM">08:45 AM</ng-option>
                                    <ng-option value="09:00 AM">09:00 AM</ng-option>
                                    <ng-option value="09:15 AM">09:15 AM</ng-option>
                                    <ng-option value="09:30 AM">09:30 AM</ng-option>
                                    <ng-option value="09:45 AM">09:45 AM</ng-option>
                                    <ng-option value="10:00 AM">10:00 AM</ng-option>
                                    <ng-option value="10:15 AM">10:15 AM</ng-option>
                                    <ng-option value="10:30 AM">10:30 AM</ng-option>
                                    <ng-option value="10:45 AM">10:45 AM</ng-option>
                                    <ng-option value="11:00 AM">11:00 AM</ng-option>
                                    <ng-option value="11:15 AM">11:15 AM</ng-option>
                                    <ng-option value="11:30 AM">11:30 AM</ng-option>
                                    <ng-option value="11:45 AM">11:45 AM</ng-option>
                                    <ng-option value="12:00 PM">12:00 PM</ng-option>
                                    <ng-option value="12:15 PM">12:15 PM</ng-option>
                                    <ng-option value="12:30 PM">12:30 PM</ng-option>
                                    <ng-option value="12:45 PM">12:45 PM</ng-option>
                                    <ng-option value="01:00 PM">01:00 PM</ng-option>
                                    <ng-option value="01:15 PM">01:15 PM</ng-option>
                                    <ng-option value="01:30 PM">01:30 PM</ng-option>
                                    <ng-option value="01:45 PM">01:45 PM</ng-option>
                                    <ng-option value="02:00 PM">02:00 PM</ng-option>
                                    <ng-option value="02:15 PM">02:15 PM</ng-option>
                                    <ng-option value="02:30 PM">02:30 PM</ng-option>
                                    <ng-option value="02:45 PM">02:45 PM</ng-option>
                                    <ng-option value="03:00 PM">03:00 PM</ng-option>
                                    <ng-option value="03:15 PM">03:15 PM</ng-option>
                                    <ng-option value="03:30 PM">03:30 PM</ng-option>
                                    <ng-option value="03:45 PM">03:45 PM</ng-option>
                                    <ng-option value="04:00 PM">04:00 PM</ng-option>
                                    <ng-option value="04:15 PM">04:15 PM</ng-option>
                                    <ng-option value="04:30 PM">04:30 PM</ng-option>
                                    <ng-option value="04:45 PM">04:45 PM</ng-option>
                                    <ng-option value="05:00 PM">05:00 PM</ng-option>
                                    <ng-option value="05:15 PM">05:15 PM</ng-option>
                                    <ng-option value="05:30 PM">05:30 PM</ng-option>
                                    <ng-option value="05:45 PM">05:45 PM</ng-option>
                                    <ng-option value="06:00 PM">06:00 PM</ng-option>
                                    <ng-option value="06:15 PM">06:15 PM</ng-option>
                                    <ng-option value="06:30 PM">06:30 PM</ng-option>
                                    <ng-option value="06:45 PM">06:45 PM</ng-option>
                                    <ng-option value="07:00 PM">07:00 PM</ng-option>
                                    <ng-option value="07:15 PM">07:15 PM</ng-option>
                                    <ng-option value="07:30 PM">07:30 PM</ng-option>
                                    <ng-option value="07:45 PM">07:45 PM</ng-option>
                                    <ng-option value="08:00 PM">08:00 PM</ng-option>
                                    <!-- <ng-option value="Female">Female</ng-option>
                                    <ng-option value="Other">Other</ng-option> -->
                                </ng-select>
                                <div *ngIf="typesubmit && type.time_slot.errors" class="invalid-feedback">
                                    <span *ngIf="type.time_slot.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Consultant Fees <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="amount"
                                    [ngClass]="{'is-invalid': typesubmit && type.amount.errors}"
                                    (keypress)="numericOnly($event)" placeholder="Type something" />
                                <div *ngIf="typesubmit && type.amount.errors" class="invalid-feedback">
                                    <span *ngIf="type.amount.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Doctor Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select formControlName="doctor_name" (change)="getdep($event)">
                                    <ng-option value="" disabled Selected>Select The Doctor</ng-option>
                                    <ng-option *ngFor="let i of doctordata "
                                        value="{{i.doctor_name}}">{{i.doctor_name}}</ng-option>
                                </ng-select>
                                <div *ngIf="typesubmit && type.doctor_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.doctor_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Department Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="department_name"
                                    [ngClass]="{'is-invalid': typesubmit && type.department_name.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.department_name.errors" class="invalid-feedback">
                                    <span *ngIf="type.department_name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Information Source <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select id="inputstate" id="default-select" formControlName="infosource">
                                    <ng-option value="" disabled Selected>Select the Option</ng-option>
                                    <ng-option>Old-patint</ng-option>
                                    <ng-option>Website</ng-option>
                                    <ng-option>Facebook</ng-option>
                                    <ng-option>Television Ad</ng-option>
                                    <ng-option>Mouth Publicity</ng-option>
                                    <ng-option>Referral</ng-option>
                                    <ng-option>Newspaper</ng-option>
                                    <ng-option>Other</ng-option>
                                </ng-select>
                            </div>
                            <div *ngIf="typesubmit && type.infosource.errors" class="invalid-feedback">
                                <span *ngIf="type.infosource.errors.required">This value is required.</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Patient Brief <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <textarea type="text" class="form-control" formControlName="problem"
                                    [ngClass]="{'is-invalid': typesubmit && type.problem.errors}"
                                    placeholder="Type something" rows="4"></textarea>
                                <div *ngIf="typesubmit && type.problem.errors" class="invalid-feedback">
                                    <span *ngIf="type.problem.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <div class="form-group mb-0" style="text-align: right;">
                    <br>
                    <button type="submit" class="btn btn-primary me-1" (click)="typeSubmit()"> Submit </button>
                    <button type="reset" class="btn btn-secondary" (click)="reset()"> Reset </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title"> Today Appointments Data </h4>
                <div class="table-responsive">
                    <table class="table table-bordered mb-0 ">
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th>Appointment date / Time</th>
                                <th>Amount</th>
                                <th>Department Name</th>
                                <th>Doctor Name</th>
                                <th>Problem </th>
                                <th>Info Source </th>
                                <th>Date of Entry </th>
                                <th>Prints </th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let i of data;let y =index">
                            <tr>
                                <th scope="row">{{y+1}}</th>
                                <td>{{i.salutation}} {{i.name}} {{i.last_name}}</td>
                                <td>{{i.age}}</td>
                                <td>{{i.number}}</td>
                                <td>{{i.gender}}</td>
                                <td>{{i.appointment_date | date:'dd-MM-yyyy'}} / {{i.time_slot}} </td>
                                <td>{{i.amount}}</td>
                                <td>{{i.department_name}}</td>
                                <td>{{i.doctor_name}}</td>
                                <td>{{i.problem}}</td>
                                <td>{{i.infosource}}</td>
                                <td>{{i.date | date:'dd-MM-yyyy'}}</td>
                                <td align="center">
                                    <a>
                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                            class="fa fa-print" (click)="printData(i)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-overlay" *ngIf="loadingpart">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>