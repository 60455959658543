import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layouts/layout/layout.component';
import { OpPrintComponent } from './module/op-print/op-print.component';
import { SalesPrintComponent } from './pharmacy/sales-print/sales-print.component';
import { PurchasePrintsComponent } from './pharmacy/purchase-prints/purchase-prints.component';
import { AppointmentprintComponent } from './module/appointmentprint/appointmentprint.component';
import { RoomsreportComponent } from './bed-management/roomsreport/roomsreport.component';
import { LabreportsComponent } from './lab-management/labreports/labreports.component';
import { LbresultsprintComponent } from './lab-management/lbresultsprint/lbresultsprint.component';

const routes: Routes = [

  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'hospital', component: LayoutComponent, loadChildren: () => import('./module/module.module').then(m => m.ModuleModule) },
  { path: 'pharmacy', component: LayoutComponent, loadChildren: () => import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule) },
  { path: 'bed-management', component: LayoutComponent, loadChildren: () => import('./bed-management/bed-management.module').then(m => m.BedManagementModule) },
  { path: 'lab-management', component: LayoutComponent, loadChildren: () => import('./lab-management/lab-management.module').then(m => m.LabManagementModule) },
  { path: 'blood-management', component: LayoutComponent, loadChildren: () => import('./blood-management/blood-management.module').then(m => m.BloodManagementModule) },
  { path: 'ambulance', component: LayoutComponent, loadChildren: () => import('./ambulance/ambulance.module').then(m => m.AmbulanceModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'billing', component: LayoutComponent, loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
  { path: 'mis-reports', component: LayoutComponent, loadChildren: () => import('./mis-reports/mis-reports.module').then(m => m.MisReportsModule) },
  { path: 'op-print', component: OpPrintComponent },
  { path: 'sales-print', component: SalesPrintComponent },
  { path: 'purchase-prints', component: PurchasePrintsComponent },
  { path: 'appointmentprint', component: AppointmentprintComponent },
  { path: 'labreports', component: LabreportsComponent },
  { path: 'roomsreport', component: RoomsreportComponent },
  { path: 'lbresultsprint', component: LbresultsprintComponent },


];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
