import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';

import { environment } from 'src/environments/environment';

var Api = environment.Api;

@Injectable({
  providedIn: 'root'
})
export class BillServicesService {

  constructor(private http: HttpClient) { }


  getTotalPatients() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getTotalPatients`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
 
  getLabBillings(data){
    return this.http.post<any>(Api + `getLabBillings`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  getBedBillings(data){
    return this.http.post<any>(Api + `getBedBillings`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
 
  getPharmacyBillings(data){
    return this.http.post<any>(Api + `getPharmacyBillings`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
 
  getcompanyDetails(data){
    return this.http.post<any>(Api + `getCompanyDetails`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }


  
}
