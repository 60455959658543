<table width="98%" style="padding:5px;" align="center">
    <tbody>
        <tr>
            <td colspan="8">
                <table width="98%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td width="6%" rowspan="4" align="left">
                                <div align="center"><img src="../../../assets/images/brands/ava.png"
                                        style="max-height:100px; max-width:800px"></div>
                            </td>
                            <td colspan="3" align="left">
                                <div align="center" class="style66">
                                    {{company}} </div>
                            </td>
                            <!-- <td colspan="3" align="left">
                                <div align="center" class="style66">
                                    {{address}} </div>
                            </td> -->
                            <td width="8%" rowspan="4" align="left">
                                <!-- <div align="center">
                                    <img src="http://chart.googleapis.com/chart?cht=qr&amp;chl=https%3A%2F%2Ftirupatihms.com%2Fhms%2F%2Fcashreceipts%2Fprint_and_invoice%2F679%402556&amp;chs=130x130&amp;choe=UTF-8&amp;chld=L|1"
                                        alt="">
                                </div> -->
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                    </strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                        {{com_add}}</strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67">Phone:-
                                    9177917791,&nbsp;Email:-
                                    infoamaravathisoftware.com</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" style="border:2px solid;" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td colspan="2" align="left" bgcolor="#99FFCC"><b>
                                    <div align="center"><b><span class="style41">
                                                OPD RECEIPT/BILL </span></b></div>
                                </b></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style41">
                                    OPD :</span>{{three}}<span class="style41">
                                </span></td>
                            <td align="right"><span class="style2">
                                    Date : </span><span class="style2">
                                    {{four}}</span></td>
                        </tr>
                        <tr>
                            <td><span class="style2">
                                    Patient : </span> <span class="style2"> {{one}} {{two}} </span>
                            </td>
                            <td align="right"><span class="style2">
                                    Age/Sex : </span><span class="style2">{{seven}} Yrs. /
                                    {{six}} </span></td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style2">
                                    Address :</span><span class="style2">{{five}}</span></td>

                            <td align="right"><span class="style2">
                                    Mobile :</span><span class="style41">{{eight}}</span></td>
                        </tr>
                        <tr>


                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <table width="100%" style="border:2px solid;" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                    Sr. </span></td>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                    Item/Service </span></td>
                            <td bgcolor="#99FFCC">
                                <div align="right" class="style56 style13">Item-Id </div>
                            </td>

                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                    Rate.</span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                    Total.</span></td>


                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                    Amt.(
                                    ₹)</span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="center" bgcolor="#99FFCC"></td>
                        </tr>

                        <tr>
                            <td colspan="13">
                                <hr>
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <span class="style13">1</span>
                            </td>
                            <td><span class="style13"></span></td>
                            <td><span class="style13">CONSULTATION FEE</span></td>
                            <td>
                                <div align="right" class="style13">OPD</div>
                            </td>

                            <td align="right">
                                <span class="style13" style="padding-right:5px"><span
                                        class="style2">{{nine}}</span></span>
                            </td>
                            <td align="right">{{nine}}</td>

                            <td align="right" style="padding-right:5px;"><span class="style13">{{nine}}</span></td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span>
                            </td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right"><span class="style2"></span></td>
                            <td align="right"><span class="style13" style="padding-right:5px"></span></td>
                            <td align="center"></td>
                        </tr>


                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" bgcolor="#99FFCC" style="padding-top:0px;"><span
                                    class="style13">Total</span></td>
                            <td bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td colspan="2" bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td bgcolor="#99FFCC"><span class="style13"></span></td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;">&nbsp;</td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;">{{nine}}</td>
                            <td bgcolor="#99FFCC"></td>
                            <td align="right" bgcolor="#99FFCC" style="padding-right:5px;">0.000</td>
                            <td colspan="2" align="right" bgcolor="#99FFCC" style="padding-right:5px;"><span
                                    class="style13"><span class="style13"
                                        style="border-bottom:dashed #000000;border-width:2px;">
                                        <span class="style56 style13">(
                                            ₹)</span>
                                        {{nine}}</span></span></td>
                        </tr>

                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <div align="center" class="style13">
                                    <hr>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12" bgcolor="#99FFCC">Total</td>
                            <td bgcolor="#99FFCC" style="text-align:right;"><span class="style56 style13"> (
                                    ₹)</span> {{nine}}</td>
                        </tr>
                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <div align="center" class="style13">
                                    <hr>
                                </div>
                            </td>
                        </tr>


                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13">*This is a
                                    Computerised Bill/Receipt </span></td>
                            <td colspan="10" style="padding-top:0px;">
                                <div align="right" class="style13">{{company}} </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;">&nbsp;</td>

                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13"> Wish you speedy
                                    Recovery..... </span></td>

                        </tr>
                        <tr>
                            <td style="padding-top:0px;" colspan="13">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<br><br>
<div class="btns">
    <button mat-raised-button id="printPageButton" style="margin-top: 1%;" (click)="openPDF()" color="primary"
        class="button-customize" *ngIf="disable">
        Print</button>&nbsp;&nbsp;
    <button mat-raised-button id="printPageButton" style="margin-top: 1%;" (click)="Back()" color="primary"
        class="button-customize">
        Back</button>&nbsp;&nbsp;
</div>