import { Component, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { permissionlist } from '../registration/advanced.model';
import { DecimalPipe } from '@angular/common';
import { Observable, take } from "rxjs";
import { AdvancedSortableDirective, SortEvent } from '../registration/advanced-sortable.directive';
import { Permissionlistadvance } from './permissionlist.advance';
import Swal from 'sweetalert2';
import { ServiceService } from 'src/app/account/service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
@Component({
  selector: "app-permission-list",
  templateUrl: "./permission-list.component.html",
  styleUrls: ["./permission-list.component.scss"],
})
export class PermissionListComponent {
  selectedmoduleslist = [];
  notselectedmoduleslist = [];
  totalprojectlist = [];
  totalmoduleslistarr = [];
  modulelistarr = [];

  tableData: permissionlist[];
  tables$: Observable<permissionlist[]>;
  total$: Observable<number>;
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public service: Permissionlistadvance,
    private pipe: DecimalPipe,
    public myservice: ServiceService,
    public modalservice: NgbModal
  ) { }

  ngOnInit() {
    this.get();
  }

  get() {
    this.tables$ = this.service.tables$();
    this.total$ = this.service.total$;
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  Delete(i) {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to delete it ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.myservice.deleteuser(i).subscribe((res: any) => {
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            this.get();
          }
        });
      }
    });
  }

  getemployeemoduleslist(event) {
    this.selectedmoduleslist = [];
    var user_id = event;
    // this.loadingpart = true;
    this.myservice.getupdatemodules(user_id).subscribe((resdata) => {
      this.totalmoduleslistarr = resdata.data;


      // this.loadingpart = false;
      this.totalprojectlist = [];
      this.totalprojectlist = Object.values(
        this.totalmoduleslistarr.reduce((r, o) => {
          if (o.check_sub_menu == true) {
            this.selectedmoduleslist.push(o);
          }
          r[o.module_id] = r[o.module_id] || {
            module_id: o.module_id,
            label: o.module_nm,
            icon: o.icon,
            class: "",
            link: o.link,
            badge: "",
            badgeClass: "",
            d_in: o.d_in,
            isExternalLink: 0,
            subcollopescnd: false,
            checkallstatus: "Un Check All",
            checkallmodules: true,
            submenu: [],
            reportdata: [],
          };
          r[o.module_id]["submenu"].push({
            module_id: o.module_id,
            module_order: o.module_order,
            id: o.id,
            label: o.label,
            icon: o.icon,
            class: "",
            link: o.link,
            badge: "",
            badgeClass: "",
            d_in: o.d_in,
            isExternalLink: 0,
            subcollopescnd: false,
            checkallstatus: "Un Check All",
            check_sub_menu: o.check_sub_menu,
            checkallmodules: true,
            submenu: [],
          });
          return r;
        }, {})
      );

      this.totalprojectlist.map((obj) => {
        obj.submenu.map((obj2) => {
          if (obj2.check_sub_menu == false) {
            return (
              (obj.checkallmodules = false), (obj.checkallstatus = "Check All")
            );
          } else {
            return obj;
          }
        });
      });

      this.modulelistarr = resdata.data.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.module_id === value.module_id)
      );
    });
  }

  getemployee() {
    // this.myservice.userpermissionget().subscribe((Result: any) => {
    //   var id = 0;
    //   Result.data.map(respo => {
    //     respo.i = ++id;
    //   })
    //   if (Result.status == 200) {
    //     Result.data.map(obj => {
    //       var array1 = obj.module_nm.split(',');
    //       obj.module_nm = array1;
    //       var array2 = obj.sub_menu.split(',');
    //       obj.sub_menu = array2;
    //     })
    //     this.masterdata1 = Result.data;
    //   }
    // })
  }

  checkallmoduledata(checkallmodules, projectmodules, projectdata) {
    if (checkallmodules == true) {
      checkallmodules = false;
    } else if (checkallmodules == false) {
      checkallmodules = true;
    }
    if (checkallmodules == true) {
      projectdata.submenu.map((obj123) => {
        if (obj123.check_sub_menu == false) {
          obj123.check_sub_menu = true;
          this.selectedmoduleslist.push(obj123);
        }
      });
      projectdata.checkallmodules = checkallmodules;
      projectdata.checkallstatus = "Un Check All";
    } else if (checkallmodules == false) {
      var tepmcheckedmodule = [];
      var ccnt = 0;
      this.selectedmoduleslist.map((obj) => {
        ccnt++;
        if (obj.module_id == projectdata.module_id) {
          projectdata.submenu.map((obj123) => {
            obj123.check_sub_menu = false;
          });
        } else {
          tepmcheckedmodule.push(obj);
        }
        if (ccnt == this.selectedmoduleslist.length) {
          this.selectedmoduleslist = [];
          this.selectedmoduleslist = tepmcheckedmodule;
        }
      });
      projectdata.checkallmodules = checkallmodules;
      projectdata.checkallstatus = "Check All";
    }
  }

  selectedsubmodules(modulecnd, moduledetails, projectdata) {
    if (modulecnd == true) {
      modulecnd = false;
    } else if (modulecnd == false) {
      modulecnd = true;
    }
    if (modulecnd == true) {
      moduledetails.check_sub_menu = modulecnd;
      this.selectedmoduleslist.push(moduledetails);
      var mcnt = 0;
      var checkcnt = 0;
      projectdata.submenu.map((m) => {
        mcnt++;
        if (m.check_sub_menu == false) {
          checkcnt = 1;
        }
        if (mcnt == projectdata.submenu.length && checkcnt == 0) {
          projectdata.checkallstatus = "Un Check All";
          projectdata.checkallmodules = true;
        }
      });
    } else if (modulecnd == false) {
      moduledetails.check_sub_menu = modulecnd;
      this.selectedmoduleslist.map((m) => {
        if (m.id == moduledetails.id) {
          var index = this.selectedmoduleslist.indexOf(m);
          this.selectedmoduleslist.splice(index, 1);
        }
      });
      projectdata.checkallstatus = "Check All";
      projectdata.checkallmodules = false;
    }
  }

  openprojectmodules(subcollopescnd, pdata) {
    if (subcollopescnd == true) {
      subcollopescnd = false;
    } else if (subcollopescnd == false) {
      subcollopescnd = true;
    }
    pdata.subcollopescnd = subcollopescnd;
  }

  userdetails: any;
  user_id: any;

  centerModal(centerDataModal, table) {

    this.userdetails = "";
    this.userdetails = table;
    this.user_id = table.id;
    this.getemployeemoduleslist(table.id);
    this.modalservice.open(centerDataModal, { size: "xl", centered: true });
  }

  updateuserpermissionlist(roll_usr_id) {
    this.selectedmoduleslist.map((obj) => {
      obj.user_id = roll_usr_id;
    });
    const uniqueIds = [];
    const unique = this.selectedmoduleslist.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    if (this.selectedmoduleslist.length > 0) {
      this.myservice.postusermenulist(unique).subscribe((resdata) => {
        Swal.fire("Successfully Added...!");
        this.get();
        this.selectedmoduleslist = [];
        this.modalservice.dismissAll();
      });
    } else {
      Swal.fire("Please Select Module list");
    }
  }
  isDownloading: boolean = false;
  downloadExcel() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data) => {
      const exportData = data.map((table) => ({
        Name: table.name,
        Number: table.number,
        Email: table.email,
      }));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer: any = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "Permissions list ");
      this.isDownloading = false; // Reset the flag after download is complete
    });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Name",
        "Number",
        "Email",
      ];
      const exportData = data.map((table: any) => [
        table.name,
        table.number,
        table.email,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("Permissions-List.pdf");
      this.isDownloading = false;
    });
  }
}
