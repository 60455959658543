<div class="container-fluid">
    <app-pagetitle title="Add-Appointment" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="row" style="margin: 3%;">
                <form [formGroup]="Perform" (ngSubmit)="searchdata()">
                    <div class="row">
                        <div class="col-md-4 col-sm-4">
                            <div class="form-group mb-4">
                                <label for="name">From Date <span style="color:red">*</span></label>
                                <input type="date" formControlName="pdate" class="form-control"
                                    placeholder="Enter Date" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="form-group mb-4">
                                <label for="name">To Date <span style="color:red">*</span></label>
                                <input type="date" formControlName="tdate" class="form-control"
                                    placeholder="Enter Date" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <label for="name"></label>
                            <button style="background-color: #44b6ae; border-color: #44b6ae; margin-top: 28px;"
                                type="submit" class="btn btn-primary">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="gotoprescriptionlist()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Add-Appointment
                </button>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <br>
                            <label class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries
                            </label>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-2" style="text-align: left">
                        <div class="form-group mb-0" style="text-align: right;">
                            <button class="btn btn-secondary me-1" (click)="downloadExcel()">Excel</button>
                            <button class="btn btn-secondary" (click)="downloadPdf()"> PDF</button>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Table -->
            <div class="table-responsive">
                <table id="basic-datatable"
                    class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th sortable="department_name" (sort)="onSort($event)">Department</th>
                            <th sortable="doctor_name" (sort)="onSort($event)">Doctor</th>
                            <th sortable="name" (sort)="onSort($event)">Name</th>
                            <th sortable="age" (sort)="onSort($event)">Age</th>
                            <th sortable="number" (sort)="onSort($event)">Number</th>
                            <th sortable="dob" (sort)="onSort($event)">Date of Birth</th>
                            <th sortable="gender" (sort)="onSort($event)">Gender</th>
                            <th sortable="appointment_date" (sort)="onSort($event)">Appointment-Date</th>
                            <th sortable="amount" (sort)="onSort($event)">Amount</th>
                            <th sortable="problem" (sort)="onSort($event)">Problem </th>
                            <th sortable="infosource" (sort)="onSort($event)">Information Source </th>
                            <th>Action </th>
                            <th>Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let table of tables$ | async;let i=index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                <ngb-highlight [result]="table.department_name"
                                    [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.doctor_name" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                {{table.salutation}} {{table.name}} {{table.last_name}}
                            </td>
                            <td>
                                <ngb-highlight [result]="table.age" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.number" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                {{table.dob | date:'dd-MM-yyyy'}}
                            </td>
                            <td>
                                <ngb-highlight [result]="table.gender" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                {{table.appointment_date | date:'dd-MM-yyyy'}}
                                &nbsp;&nbsp;/&nbsp;&nbsp;{{table.time_slot}}
                            </td>
                            <td>
                                <ngb-highlight [result]="table.amount" [term]="service.searchTerm"></ngb-highlight>
                            </td>

                            <td>
                                <ngb-highlight [result]="table.problem" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.infosource" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 4%;"
                                    class="ri-user-shared-2-fill" title="Edit"
                                    (click)="centerModal(centerDataModal,table)"></i>&nbsp;&nbsp;
                            </td>
                            <td>
                                <!-- (click)="centerModal(centerDataModal,table)" -->
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(31, 115, 31);color: white;padding: 2%;"
                                    class="ri-edit-fill" title="Edit"
                                    (click)="centerModals(centerDataModals,table)"></i>&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- End table -->
            <div class="row justify-content-md-between align-items-md-center mt-2">
                <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                        Showing {{service.startIndex}} to {{service.endIndex}} of
                        {{service.totalRecords}}
                        entries
                    </div>
                </div>
                <!-- Pagination -->
                <div class="col-sm-12 col-md-5">
                    <div class="text-md-end float-md-end s pagination-rounded">
                        <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                            [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
                <!-- End Pagination -->
            </div>
        </div>
    </div>
</div>
<ng-template #centerDataModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Add Patient </h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="typeSubmit()" [formGroup]="typeValidationForm">
            <br>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Name : </label> <b style="font-weight: bolder;font-size: 17px;"> {{patient_details.name}}
                        </b>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Age : </label><b style="font-weight: bolder;font-size: 17px;"> {{patient_details.age}}
                        </b>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Number : </label><b style="font-weight: bolder;font-size: 17px;">
                            {{patient_details.number}} </b>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Guardian (optional)</label>
                        <input type="text" class="form-control" formControlName="guardian"
                            [ngClass]="{'is-invalid': typesubmit && type.guardian.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.guardian.errors" class="invalid-feedback">
                            <span *ngIf="type.guardian.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Aadhar (optional)</label>
                        <input type="text " class="form-control" formControlName="aadhar" minlength="12" maxlength="12"
                            [ngClass]="{'is-invalid': typesubmit && type.aadhar.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.aadhar.errors" class="invalid-feedback">
                            <span *ngIf="type.aadhar.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Consultant Fees <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="number" class="form-control" formControlName="consultant_fee"
                            [ngClass]="{'is-invalid': typesubmit && type.consultant_fee.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.consultant_fee.errors" class="invalid-feedback">
                            <span *ngIf="type.consultant_fee.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>No.of days to Expiry<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="number" class="form-control" formControlName="days_to_expiry"
                            [ngClass]="{'is-invalid': typesubmit && type.days_to_expiry.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.days_to_expiry.errors" class="invalid-feedback">
                            <span *ngIf="type.days_to_expiry.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Address (optional)</label>
                        <textarea type="number" class="form-control" formControlName="address"
                            [ngClass]="{'is-invalid': typesubmit && type.address.errors}" placeholder="Type something"
                            rows="3"></textarea>
                        <div *ngIf="typesubmit && type.address.errors" class="invalid-feedback">
                            <span *ngIf="type.address.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Blood Pressure (optional)</label>
                        <input type="text" class="form-control" formControlName="blood_pressure"
                            (keypress)="numericOnly($event)"
                            [ngClass]="{'is-invalid': typesubmit && type.blood_pressure.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.blood_pressure.errors" class="invalid-feedback">
                            <span *ngIf="type.blood_pressure.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Sugar (optional)</label>
                        <input type="text" class="form-control" formControlName="sugar"
                            [ngClass]="{'is-invalid': typesubmit && type.sugar.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.sugar.errors" class="invalid-feedback">
                            <span *ngIf="type.sugar.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Weight (optional)</label>
                        <input type="text" class="form-control" formControlName="weight"
                            (keypress)="numericOnly($event)"
                            [ngClass]="{'is-invalid': typesubmit && type.weight.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.weight.errors" class="invalid-feedback">
                            <span *ngIf="type.weight.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Height (optional)</label>
                        <input type="number" class="form-control" formControlName="height"
                            [ngClass]="{'is-invalid': typesubmit && type.height.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.height.errors" class="invalid-feedback">
                            <span *ngIf="type.height.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Others (optional)</label>
                        <input type="text" class="form-control" formControlName="others"
                            [ngClass]="{'is-invalid': typesubmit && type.others.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.others.errors" class="invalid-feedback">
                            <span *ngIf="type.others.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <div class="form-group mb-0" style="text-align: right;">
                    <button type="submit" class="btn btn-primary me-1"> Submit </button>
                    <button type="reset" class="btn btn-secondary"> Reset </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #centerDataModals let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Edit Appointment details </h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="edit()" [formGroup]="typeeditForm">
            <br>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Name : </label>
                        <input type="text" class="form-control" formControlName="name"
                            [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                            placeholder="Type something" />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Age : </label>
                        <input type="text" class="form-control" formControlName="age"
                            [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                            placeholder="Type something" />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Number : </label><input type="text" class="form-control" formControlName="number"
                            [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                            placeholder="Type something" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Date of Birth</label>
                        <input type="date" class="form-control" formControlName="dob"
                            [ngClass]="{'is-invalid': typesubmit && type.guardian.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.guardian.errors" class="invalid-feedback">
                            <span *ngIf="type.guardian.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Gender</label>
                        <select formControlName="gender" class="form-control"
                            [ngClass]="{'is-invalid': typesubmit && type.gender.errors}">
                            <option value="" selected>Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        <div *ngIf="typesubmit && type.aadhar.errors" class="invalid-feedback">
                            <span *ngIf="type.aadhar.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Amount<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="number" class="form-control" formControlName="amount"
                            [ngClass]="{'is-invalid': typesubmit && type.days_to_expiry.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.days_to_expiry.errors" class="invalid-feedback">
                            <span *ngIf="type.days_to_expiry.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="form-group mb-0" style="text-align: right;">
                <button type="submit" class="btn btn-primary me-1"> Submit </button>
                <button type="reset" class="btn btn-secondary"> Reset </button>
            </div>

        </form>
    </div>
</ng-template>