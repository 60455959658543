<div class="container-fluid">
    <app-pagetitle title="Patient Prescription List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="gotoprescriptionview1()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Add-Prescription
                </button>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <br>
                            <label class="d-inline-flex align-items-center">
                                Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                                entries
                            </label>
                        </div>
                    </div>


                    <div class="col-sm-12 col-md-2" style="text-align: left">
                        <div class="form-group mb-0" style="text-align: right;">
                            <button class="btn btn-secondary me-1" (click)="downloadExcel()">Excel</button>
                            <!-- <button class="btn btn-secondary" (click)="downloadPdf()"> PDF</button> -->
                        </div>

                    </div>
                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>

                </div>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th></th>
                                <th sortable="patient_id" (sort)="onSort($event)"> Patient ID</th>
                                <th sortable="first_name" (sort)="onSort($event)"> First Name</th>
                                <th sortable="first_name" (sort)="onSort($event)">Weight</th>
                                <th sortable="phone_number" (sort)="onSort($event)">Blood Pressure</th>

                                <th sortable="gender" (sort)="onSort($event)">Description </th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let table of tables$ | async;let i=index">
                                <td>
                                    {{i+1}}
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.patient_id"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.first_name"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.weight" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.bp" [term]="service.searchTerm"></ngb-highlight>
                                </td>

                                <td>
                                    <ngb-highlight [result]="table.description"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <a>
                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                            class=" ri-eye-fill" (click)="gotoprescriptionview(table)"
                                            title="View"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-end float-md-end s pagination-rounded">
                            <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>