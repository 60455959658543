<div class="container-fluid">
    <app-pagetitle title="Add-Doctor" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <button type="button" class="btn btn-success" (click)="gotoprescriptionlist()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Doctor List
                </button>


                <br>
                <form [formGroup]="addDoctorForm">
                    <br>
                    <div class="row">
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Name<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="doctor_name"
                                    placeholder="Type something"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_name'].errors }" />
                                <div *ngIf="submitte && valid['doctor_name'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_name'].errors['required']">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="doctor_age"
                                    placeholder="Type something" minlength="2" maxlength="2"
                                    (keypress)="numericOnly($event)"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_age'].errors }" />
                                <div *ngIf="submitte && valid['doctor_age'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_age'].errors['required']">Age is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select formControlName="doctor_gender"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_gender'].errors }">
                                    <ng-option value="MALE">MALE</ng-option>
                                    <ng-option value="FEMALE">FEMALE</ng-option>
                                </ng-select>
                                <div *ngIf="submitte && valid['doctor_gender'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_gender'].errors['required']">Gender is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Mobile No<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="doctor_number"
                                    placeholder="Type something" minlength="10" maxlength="10"
                                    (keypress)="numericOnly($event)"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_number'].errors }" />
                                <div *ngIf="submitte && valid['doctor_number'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_number'].errors['required']">Number is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Timings<span style="color:red">&nbsp;&nbsp;</span></label>
                                <ng-select formControlName="doctor_timings" (change)="changeType($event,opentimings)">
                                    <ng-option *ngFor="let p of timings" value="{{p.timings}}">{{p.timings}}
                                    </ng-option>
                                    <ng-option value="ADD">Add New Timings</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Address<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <textarea class="form-control" formControlName="doctor_address"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_number'].errors }"></textarea>
                                <div *ngIf="submitte && valid['doctor_address'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_address'].errors['required']">Address is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="form-group">
                                <label>Department<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <ng-select (change)="changeType($event,openPopforNew)" id="inputstate"
                                        id="default-select" formControlName="doctor_dept"
                                        [ngClass]="{'is-invalid': submitte && valid['doctor_dept'].errors }">
                                        <ng-option *ngFor="let p of department" value="{{p.name}}">{{p.name}}
                                        </ng-option>
                                        <ng-option value="ADD">Add New Department</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitte && valid['doctor_dept'].errors" class="invalid-feedback">
                                        <div *ngIf="valid['doctor_dept'].errors['required']">Department is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>License No<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="license_number"
                                    placeholder="Type something"
                                    [ngClass]="{'is-invalid': submitte && valid['license_number'].errors }" />
                                <div *ngIf="submitte && valid['license_number'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['license_number'].errors['required']">License No required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Experience<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="number" class="form-control" formControlName="doctor_experience"
                                    placeholder="Type something" (keypress)="numericOnly($event)"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_experience'].errors }" />
                                <div *ngIf="submitte && valid['doctor_experience'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_experience'].errors['required']">Experience is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Doctor Fees<span style="color:red">&nbsp;&nbsp;</span></label>
                                <input type="text" class="form-control" formControlName="doctor_charges"
                                    placeholder="Type something" (keypress)="numericOnly($event)" />
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Email<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="email" class="form-control" formControlName="doctor_email"
                                    placeholder="Type something"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_email'].errors }" />
                                <div *ngIf="submitte && valid['doctor_email'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_email'].errors['required']">Email is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                            <div class="form-group">
                                <label>Description<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <textarea type="text" class="form-control" formControlName="description"
                                    placeholder="Type something"
                                    [ngClass]="{'is-invalid': submitte && valid['doctor_password'].errors }"></textarea>
                                <div *ngIf="submitte && valid['doctor_password'].errors" class="invalid-feedback">
                                    <div *ngIf="valid['doctor_password'].errors['required']">Password is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-3">
                            <div class="form-group">
                                <br><button type="button" class="btn btn-primary me-1" (click)="addDoctor()">
                                    Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #openPopforNew let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Add New Department</h5>
        <button type="button" (click)="dis()" class="btn-close" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addNew">
            <div class="row">

                <div class="col-10">
                    <label>ADD Department Name</label>
                    <input type="text" class="form-control" formControlName="new_type" />

                </div>
                <div class="col-2">
                    <label></label>
                    <br>&nbsp;
                    <button (click)="submitNew()" class="btn btn-primary btn-sm">ADD</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #opentimings let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Add New Timings</h5>
        <button type="button" (click)="dis()" class="btn-close" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addNewtime">
            <div class="row">

                <div class="col-10">
                    <label>ADD Timings("AM-PM")</label>
                    <input type="text" class="form-control" formControlName="new_time" />

                </div>
                <div class="col-2">
                    <label></label>
                    <br>&nbsp;
                    <button (click)="submitNewtime()" class="btn btn-primary btn-sm">ADD</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>