<div class="container-fluid">
    <app-pagetitle title="Add-Prescription" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="gotoprescriptionlist()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Prescription-List
                </button>
                <form [formGroup]="form">
                    <br>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Patient Id <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <ng-select id="inputstate" id="default-select" (change)="changeFn(patient_id)"
                                    [ngClass]="{'is-invalid': typesubmit && type.patient_id.errors}"
                                    style="text-transform: uppercase;" [(ngModel)]="patient_id"
                                    formControlName="patient_id">
                                    <ng-option *ngFor="let p of data" value="{{p.patient_id}}">{{p.patient_id}} - (
                                        {{p.first_name}} {{p.last_name}} )
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="typesubmit && type.patient_id.errors" class="invalid-feedback">
                                    <span *ngIf="type.patient_id.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{'is-invalid': typesubmit && type.name.errors}"
                                    placeholder="Type something" readonly />
                                <div *ngIf="typesubmit && type.name.errors" class="invalid-feedback">
                                    <span *ngIf="type.name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Age<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="age" type="text" readonly minlength="2" maxlength="2"
                                        class="form-control" [ngClass]="{'is-invalid': typesubmit && type.age.errors}"
                                        placeholder="Enter only numbers">
                                    <div *ngIf="typesubmit && type.age.errors" class="invalid-feedback">
                                        <span *ngIf="type.age.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Phone Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div>
                                    <input formControlName="phone_number" readonly type="text" minlength="10"
                                        maxlength="10" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.phone_number.errors}"
                                        placeholder="Enter only numbers">
                                    <div *ngIf="typesubmit && type.phone_number.errors" class="invalid-feedback">
                                        <span *ngIf="type.phone_number.errors.required">This value is required.</span>
                                        <span *ngIf="type.phone_number.errors.pattern">This value should be a valid
                                            Phone Number.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Date of Birth <span style="color:red">&nbsp;&nbsp;*</span></label>
                                <input type="date" class="form-control" formControlName="dob" readonly
                                    [ngClass]="{'is-invalid': typesubmit && type.dob.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.dob.errors" class="invalid-feedback">
                                    <span *ngIf="type.dob.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Gender<span style="color:red">&nbsp;&nbsp;*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="gender" readonly
                                        placeholder="Type something" />
                                </div>
                            </div>
                        </div>
                        <h3 class="card-title">
                            <br><b>Enter basic details :- </b><br>
                        </h3>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Weight </label>
                                <input type="text" class="form-control" formControlName="weight"
                                    [ngClass]="{'is-invalid': typesubmit && type.weight.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.weight.errors" class="invalid-feedback">
                                    <span *ngIf="type.weight.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Height</label>
                                <input type="text" class="form-control" formControlName="height"
                                    [ngClass]="{'is-invalid': typesubmit && type.height.errors}"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.height.errors" class="invalid-feedback">
                                    <span *ngIf="type.height.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Blood Pressure ( 120 / 80 mm Hg) </label>
                                <input type="text" class="form-control" formControlName="bp"
                                    [ngClass]="{'is-invalid': typesubmit && type.bp.errors}" mask="000/000"
                                    placeholder="Type something" />
                                <div *ngIf="typesubmit && type.bp.errors" class="invalid-feedback">
                                    <span *ngIf="type.bp.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Doctor Description</label>
                                <textarea type="number" class="form-control" formControlName="prescription_description"
                                    [ngClass]="{'is-invalid': typesubmit && type.prescription_description.errors}"
                                    placeholder="Type something" rows="3"></textarea>
                                <div *ngIf="typesubmit && type.prescription_description.errors"
                                    class="invalid-feedback">
                                    <span *ngIf="type.prescription_description.errors.required">This value is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <hr>

                <form [formGroup]="medicine_form">
                    <table style="width:100%;">
                        <br>
                        <tr style="background-color: aqua;font-size: 17px;">
                            <th *ngIf="medicine_list.length" style="width:50px">S.no</th>
                            <th>Medicine Name</th>
                            <th>Medicine Type</th>
                            <th>Instruction</th>
                            <th>Days</th>
                            <th>Options</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let i of medicine_list; let y = index">
                            <td style="width:50px">{{y + 1}}</td>
                            <td>{{i.Medicine_Name}}</td>
                            <td>{{i.Medicine_Type}}</td>
                            <td>{{i.Medicine_Instruction}}</td>
                            <td>{{i.Medicine_Days}}</td>
                            <td>{{i.timings}}</td>
                            <td (click)="deleteTab(y)">
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                    class="ri-delete-bin-line" title="Delete"></i>
                            </td>
                        </tr>
                        <tr>
                            <td *ngIf="medicine_list.length" style="width:20px"></td>
                            <td>
                                <ng-select id="inputstate" id="default-select" (change)="changepns($event)"
                                    [ngClass]="{'is-invalid': typesubmit2 && type2.Medicine_Name.errors}"
                                    style="text-transform: uppercase;" formControlName="Medicine_Name">
                                    <ng-option *ngFor="let p of medicinedata"
                                        [value]="p.medicine_name">{{p.medicine_name}}</ng-option>
                                </ng-select>
                                <div *ngIf="typesubmit2 && type2.Medicine_Name.errors" class="invalid-feedback">
                                    <span *ngIf="type2.Medicine_Name.errors.required">This value is required.</span>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="Type Medicine Type"
                                    formControlName="Medicine_Type"
                                    [ngClass]="{'is-invalid': typesubmit2 && type2.Medicine_Type.errors}">
                                <div *ngIf="typesubmit2 && type2.Medicine_Type.errors" class="invalid-feedback">
                                    <span *ngIf="type2.Medicine_Type.errors.required">This value is required.</span>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="Type Instruction"
                                    formControlName="Medicine_Instruction"
                                    [ngClass]="{'is-invalid': typesubmit2 && type2.Medicine_Instruction.errors}">
                                <div *ngIf="typesubmit2 && type2.Medicine_Instruction.errors" class="invalid-feedback">
                                    <span *ngIf="type2.Medicine_Instruction.errors.required">This value is
                                        required.</span>
                                </div>
                            </td>
                            <td>
                                <input type="number" class="form-control" placeholder="Type Days"
                                    formControlName="Medicine_Days"
                                    [ngClass]="{'is-invalid': typesubmit2 && type2.Medicine_Days.errors}">
                                <div *ngIf="typesubmit2 && type2.Medicine_Days.errors" class="invalid-feedback">
                                    <span *ngIf="type2.Medicine_Days.errors.required">This value is required.</span>
                                </div>
                            </td>
                            <td>
                                <ng-select style="text-transform: uppercase;" formControlName="timings">
                                    <ng-option value="MORNING">MORNING</ng-option>
                                    <ng-option value="AFTERNOON">AFTERNOON</ng-option>
                                    <ng-option value="NIGHT">NIGHT</ng-option>
                                    <ng-option value="MOR-NYT">MOR-NYT</ng-option>
                                    <ng-option value="MOR-AFT-NYT">MOR-AFT-NYT</ng-option>
                                    <ng-option value="1D E.S">1D-EMPTY STOMACH</ng-option>
                                </ng-select>

                            </td>
                            <td style="text-align: center;" (click)="addmedicine()">
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 4%;"
                                    class="ri-add-circle-line" title="Add"></i>&nbsp;&nbsp;
                            </td>
                        </tr>
                    </table>
                </form>
                <hr>
                <form [formGroup]="diagnosis_from">
                    <table style="width:100%;">
                        <br>
                        <tr style="background-color: rgb(189, 76, 45);font-size: 17px;color:white;">
                            <th *ngIf="diagnosis_list.length" style="width:50px">S.no</th>
                            <th> Diagnosis </th>
                            <th> Diagnosis Instruction</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let i of diagnosis_list;let y = index">
                            <th style="width:50px">{{y+1}}</th>
                            <th>{{i.diagnosis}}</th>
                            <th>{{i.diagnosis_instruction}}</th>
                            <th (click)="deletediagnosis(y)">
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 4%;"
                                    class="ri-delete-bin-line" title="Delete"></i>
                            </th>
                        </tr>
                        <br>
                        <tr>
                            <td *ngIf="diagnosis_list.length" style="width:20px"></td>
                            <td>
                                <input type="text" class="form-control" placeholder="Type Diagnosis Name"
                                    formControlName="diagnosis"
                                    [ngClass]="{'is-invalid': typesubmit3 && type3.diagnosis.errors}">
                                <div *ngIf="typesubmit3 && type3.diagnosis.errors" class="invalid-feedback">
                                    <span *ngIf="type3.diagnosis.errors.required">This value is required.</span>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="Type Diagnosis Instruction"
                                    formControlName="diagnosis_instruction"
                                    [ngClass]="{'is-invalid': typesubmit3 && type3.diagnosis_instruction.errors}">
                                <div *ngIf="typesubmit3 && type3.diagnosis_instruction.errors" class="invalid-feedback">
                                    <span *ngIf="type3.diagnosis_instruction.errors.required">This value is
                                        required.</span>
                                </div>
                            </td>
                            <td style="text-align: center;" (click)="adddiagnosis()">
                                <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 4%;"
                                    class="ri-add-circle-line" title="Add"></i>&nbsp;&nbsp;
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="form-group mb-0" style="text-align: right;">
                    <br>
                    <button type="submit" class="btn btn-primary me-1" (click)="typeSubmit()"> Submit </button>
                    <button type="reset" class="btn btn-secondary" (click)="reset()"> Reset </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-add-prescription></app-add-prescription> -->


<div class="my-overlay" *ngIf="loadingpart">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>