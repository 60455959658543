import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../module.service';

@Component({
  selector: 'app-prescription-view',
  templateUrl: './prescription-view.component.html',
  styleUrls: ['./prescription-view.component.scss']
})
export class PrescriptionViewComponent {


  breadCrumbItems: Array<{}>;
  patient_id: any;
  main_patient: any;
  prescription_patient: any;
  medicinedata: any;
  testsdata: any;
  data: any

  constructor(private router: Router, private route: ActivatedRoute, public myservice: ModuleService) {
    this.route.queryParams.subscribe(params => {
      this.patient_id = params['q'];
    });

    var data = {
      patient_id: this.patient_id
    }
    this.myservice.getprescriptionview(data).subscribe((res: any) => {
      this.data = res.data
      this.main_patient = res.data[0][0];
      this.prescription_patient = res.data[1][0];
     
      
      this.medicinedata = res.data[2];
      this.testsdata = res.data[3]
    })
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Amaravathi' }, { label: 'Prescription-View', active: true }];
  }

  gotoprescriptionlist() {
    this.router.navigate(['/hospital/prescription-list']);
  }


  printPage() {
    localStorage.setItem('printdata', JSON.stringify(this.data))
    var $popup = window.open('prescriptedprint', "popup", "menubar=1,resizable=1,left=10,top=150,scrollbars=1")
  }


}
