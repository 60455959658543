import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { ModuleRoutingModule } from './module-routing.module';
import { OPComponent } from './op/op.component';
import { UiModule } from '../shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PagesRoutingModule } from '../pages/pages-routing.module';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PatientListComponent } from './patient-list/patient-list.component';
import { AddPrescriptionComponent } from './add-prescription/add-prescription.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrescriptionListComponent } from './prescription-list/prescription-list.component';
import { PrescriptionViewComponent } from './prescription-view/prescription-view.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { PrescriptedprintComponent } from './prescriptedprint/prescriptedprint.component';
import { PatientViewComponent } from './patient-view/patient-view.component';
import { OpPrintComponent } from './op-print/op-print.component';
import { AppointmentprintComponent } from './appointmentprint/appointmentprint.component';
import { AddDoctorComponent } from './add-doctor/add-doctor.component';
import { doctorlistadvance } from './doctorlist/doctorlist.advance';
import { DoctorlistComponent } from './doctorlist/doctorlist.component';



@NgModule({
  declarations: [
    OPComponent,
    PatientListComponent,
    AddPrescriptionComponent,
    PrescriptionListComponent,
    PrescriptionViewComponent,
    AddAppointmentComponent,
    AppointmentListComponent,
    PrescriptedprintComponent,
    PatientViewComponent,
    OpPrintComponent,
    AppointmentprintComponent, AddDoctorComponent, DoctorlistComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModuleRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    PagesRoutingModule,
    NgbModule,
    NgSelectModule,
  ],

  providers: [DecimalPipe, DatePipe]



})
export class ModuleModule { }
