<header id="page-topbar">
  <div class="navbar-header" [ngStyle]="{'background-color': color}">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" [ngStyle]="{'background-color': color}">
        <a href="/" class="logo logo-light">
          <h3 style="letter-spacing: 2px;color:white;margin: 10% -4%;">{{name}}</h3>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown"
          ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line" style="color:white;"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/brands/mail_chimp.png" alt="Header Avatar"> -->
          <!-- <img class="rounded-circle header-profile-user" src="{{logo}}" alt="Header Avatar"> -->
          <span class="d-none d-xl-inline-block ms-1" style="color:white;">&nbsp;&nbsp;{{name}} &nbsp;&nbsp;</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>