<div class="container-fluid">
    <app-pagetitle title="Prescription -View" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="gotoprescriptionlist()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i>Prescription-List
                </button>
                <br> <br>
                <div style="background-color: rgb(81, 81, 229);color: white;padding: 8px;font-weight: bolder;"
                    id="print">
                    <b> Patient ID</b> : {{patient_id}} &nbsp;&nbsp;&nbsp;&nbsp;,
                    <b> Name </b> : {{main_patient.first_name}} {{main_patient.last_name}}&nbsp;&nbsp;&nbsp;&nbsp;,
                    <b> Age </b> : {{main_patient.age}}&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;
                    <b> Date of Birth </b> : {{main_patient.dob | date :"dd-MM-yyyy"}}
                </div>
                <br>
                <p>Doctor Name : <b> {{main_patient.doctor_name}}</b> </p>
                <p>Doctor Description : <b> {{prescription_patient.description}}</b></p>
                <!-- <div style="background-color: rgb(81, 81, 229);color: white;padding: 8px;font-weight: bolder;">
                    <b> Name </b> : {{main_patient.first_name}}
                    {{main_patient.last_name}}&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;
                    <b> Age </b> : {{main_patient.age}}&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;
                    <b> Date of Birth </b> : {{main_patient.dob | date :"dd-MM-yyyy"}}
                </div>
                <br>
                <p>Cheif Complain : <b> {{prescription_patient.description}}</b> </p> -->
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Medicine's List </h4>
                <div class="table-responsive">
                    <table class="table table-bordered mb-0 ">
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Medicine Name</th>
                                <th>Medicine Type</th>
                                <th>Medicine Instruction</th>
                                <th>Days</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let i of medicinedata;let y =index">
                            <tr>
                                <th scope="row">{{y+1}}</th>
                                <td>{{i.medicine_name}}</td>
                                <td>{{i.medicine_type}}</td>
                                <td>{{i.medicine_instruction}}</td>
                                <td>{{i.medicine_day}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Lab Test's List </h4>
                <div class="table-responsive">
                    <table class="table table-bordered mb-0 ">
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Diagnosis</th>
                                <th>Diagnosis Instruction</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let i of testsdata;let y =index">
                            <tr>
                                <th scope="row">{{y+1}}</th>
                                <td>{{i.diagnosis}}</td>
                                <td>{{i.diagnosis_instruction}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <button (click)="printPage()">print</button> -->