import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OPComponent } from './op/op.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { AddPrescriptionComponent } from './add-prescription/add-prescription.component';
import { PrescriptionListComponent } from './prescription-list/prescription-list.component';
import { PrescriptionViewComponent } from './prescription-view/prescription-view.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { PatientViewComponent } from './patient-view/patient-view.component';
import { AddDoctorComponent } from './add-doctor/add-doctor.component';
import { doctorlistadvance } from './doctorlist/doctorlist.advance';
import { DoctorlistComponent } from './doctorlist/doctorlist.component';

const routes: Routes = [
  { path: 'patient-list', component: PatientListComponent },
  { path: 'op', component: OPComponent },
  { path: 'add-prescription', component: AddPrescriptionComponent },
  { path: 'prescription-list', component: PrescriptionListComponent },
  { path: 'prescription-view', component: PrescriptionViewComponent },
  { path: 'add-appointment', component: AddAppointmentComponent },
  { path: 'appointment-list', component: AppointmentListComponent },
  { path: 'patient-view', component: PatientViewComponent },
  { path: 'add-doctor', component: AddDoctorComponent },
  { path: 'doctorlist', component: DoctorlistComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuleRoutingModule { }
