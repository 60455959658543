<div class="container-fluid">
    <app-pagetitle title="Permission" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="card-body">
                    <form [formGroup]="addemployeedetailsform" (ngSubmit)="submitemployeedata()">
                        <div class="row">
                            <div class="col-12 col-lg-4 col-xl-4">
                                <div class="form-group">
                                    <label for="input-2">Full Name<span style="color:red">*</span></label>
                                    <input type="text" class="form-control" id="input-2" formControlName="name" required
                                        [ngClass]="{'is-invalid': formubmit && addCateFormCnt.name.errors}"
                                        placeholder="Enter Name" (blur)="trimInputValue('name')" />
                                    <div *ngIf="formubmit && addCateFormCnt.name.errors" class="invalid-feedback">
                                        <span *ngIf="addCateFormCnt.name.errors.required">Please Enter Name.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-xl-4">
                                <div class="form-group">
                                    <label for="input-2">Mobile No.<span style="color:red">*</span></label>
                                    <input type="tel" class="form-control" id="input-2" formControlName="number"
                                        placeholder="Enter Number" required (keypress)="numericOnly($event)"
                                        minlength="10" maxlength="10"
                                        [ngClass]="{'is-invalid': formubmit && addCateFormCnt.number.errors}">
                                    <div *ngIf="formubmit && addCateFormCnt.number.errors" class="invalid-feedback">
                                        <span *ngIf="addCateFormCnt.number.errors.required">Please Enter Mobile
                                            No.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-xl-4">
                                <div class="form-group">
                                    <label for="input-2">Email <span style="color:red">*</span></label>
                                    <input type="text" class="form-control" id="input-2" placeholder="Enter Email"
                                        formControlName="email" (keypress)="noteemail($event)"
                                        (paste)="$event.preventDefault()"
                                        [ngClass]="{'is-invalid': formubmit && addCateFormCnt.email.errors}">
                                    <div *ngIf="formubmit && addCateFormCnt.email.errors" class="invalid-feedback">
                                        <span *ngIf="addCateFormCnt.email.errors.required">Please Enter Email .</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="row" *ngIf="totalprojectlist.length>0">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <app-pagetitle
                                                title="Hospital Management Modules Permission"></app-pagetitle>
                                        </div>
                                        <!-- <div class="col-6">
                                            <div class="form-check mb-3">
                                                <input class="form-check-input" type="checkbox" value id="defaultCheck1"
                                                    [checked]="totalprojectlist.length == modulesname.length"
                                                    (change)="checkUncheckAll($event)">
                                                <h4 class="card-title mb-4">Select All Permissions</h4>
                                            </div>
                                        </div> -->
                                    </div>

                                    <h4 class="card-title mb-4"></h4>
                                    <div class="col-lg-12" *ngFor="let item of totalprojectlist; let i=index;">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <a href="javascript:;"
                                                    (click)="openprojectmodules(item.subcollopescnd,item)">
                                                    <h5>{{item.label}}</h5>
                                                </a>
                                            </div>
                                            <div class="col-md-2">
                                                <label class="container"><b>{{item.checkallstatus}}</b>
                                                    &nbsp;&nbsp;&nbsp;<input type="checkbox"
                                                        [checked]="item.checkallmodules"
                                                        id="user-checkbox{{item.title}}00"
                                                        (change)="checkallmoduledata(item.checkallmodules,item.submenu,item)">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <br> <br>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3" *ngFor="let subitem of item.submenu; let j=index;">
                                                <label class="container"
                                                    for="user-checkbox{{item.label}}{{subitem.check_sub_menu}}{{i}}{{j}}">{{subitem.label}}
                                                    &nbsp;&nbsp;&nbsp;<input type="checkbox"
                                                        id="user-checkbox{{item.label}}{{subitem.check_sub_menu}}{{i}}{{j}}"
                                                        [checked]="subitem.check_sub_menu"
                                                        (change)="selectedsubmodules(subitem.check_sub_menu,subitem,item)">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <br> <br>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>


                            <div class="row" style="text-align: center;">
                                <div class="col-lg-12">
                                    <button type="submit"
                                        class="btn btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-overlay" *ngIf="loadingpart">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>