import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BillServicesService } from 'src/app/billing/bill-services.service';

@Component({
  selector: 'app-lbresultsprint',
  templateUrl: './lbresultsprint.component.html',
  styleUrls: ['./lbresultsprint.component.scss']
})
export class LbresultsprintComponent {
  gotLabResults: any;
  showname: any;
  cEmail: any;
  cLogo: any;


  id: any;
  name: any;
  age: any;
  number: any;
  current_date: any;

  constructor(private location: Location, private service: BillServicesService,) {
    this.gotLabResults = JSON.parse(localStorage.getItem('labresults'))
    this.current_date = new Date().toDateString()
    this.func()
    if (this.gotLabResults[0].patient_id == '') {
      this.id = this.gotLabResults[0].sample_id
    }
    else {
      this.id = this.gotLabResults[0].patient_id
    }
    this.name = this.gotLabResults[0].name
    this.age = this.gotLabResults[0].age
    this.number = this.gotLabResults[0].number
    this.cEmail = localStorage.getItem("email");
    this.cLogo = localStorage.getItem('logo')
    this.getCompanyDetails()
  }

  print() {
    setTimeout(() => {
      window.print();
    }, 200);
  }

  public openPDF(): void {
    var x = this.showname;
    setTimeout(() => {
      x = this.showname;
      document.title = x;
      window.print();
    }, 200);
  }

  Back() {
    this.location.back();
  }

  hosName: any;
  hosNumber: any;
  address: any;
  email: any;
  getCompanyDetails() {
    var data = {
      'email': this.cEmail
    }
    this.service.getcompanyDetails(data).subscribe((res) => {
      this.hosName = res.data[0].hospital_name
      this.hosNumber = res.data[0].hospital_number
      this.address = res.data[0].address
      this.email = res.data[0].email
    })
  }


  groupedData: any;
  resultArrays: any;
  func() {
    this.groupedData = this.gotLabResults.reduce((accumulator, currentValue) => {
      // Check if there's already an array for the labtest_id, if not, create one
      if (!accumulator[currentValue.labtest_id]) {
        accumulator[currentValue.labtest_id] = [];
      }
      accumulator[currentValue.labtest_id].push(currentValue);
      return accumulator;
    }, {});
    this.resultArrays = Object.values(this.groupedData);
  }



}
