import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';

import { environment } from 'src/environments/environment';

var Api = environment.Api;

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  constructor(private http: HttpClient) { }

  addpatient(data) {
    return this.http.post<any>(Api + `addpatient`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getpatients() {
    var data = {
      company_id: localStorage.getItem("company_id")
    };
    return this.http.post<any>(Api + `getpatients`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getpatientsda(data) {
    var data1 = {
      date: data,
      company_id: localStorage.getItem("company_id")
    }
    return this.http.post<any>(Api + `getpatientsda`, data1).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  EditPatient(data) {
    return this.http.post<any>(Api + `EditPatient`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  Add_patient_prescription(data) {
    return this.http.post<any>(Api + `Add_patient_prescription`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getpatientprescription() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };

    return this.http.post<any>(Api + `getpatientprescription`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getprescriptionview(data) {
    return this.http.post<any>(Api + `getprescriptionview`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  Add_appointment(data) {
    return this.http.post<any>(Api + `Add_appointment`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  gettodayappointments() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + `gettodayappointments`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  Editappointmentlist(data) {
    return this.http.post<any>(Api + `Editappointmentlist`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getappointments() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + `getappointments`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  getappointmentsda(data) {
    var data1 = {
      date: data,
      company_id: localStorage.getItem("company_id")
    }
    return this.http.post<any>(Api + `getappointmentsda`, data1).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  appointmenttopatient(data) {
    return this.http.post<any>(Api + `appointmenttopatient`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  Add_doctor(data) {
    return this.http.post<any>(Api + `Add_doctor`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  adddoctorreports(data) {
    return this.http.post<any>(Api + `adddoctorreports`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  getDoctorsData() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + "getDoctorsData", data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  deletedoctorli(data) {
    return this.http.post<any>(Api + `deletedoctorli`, data);
  }

  Editdoctordetails(data) {
    return this.http.post<any>(Api + `Editdoctordetails`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  getmedicine() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + `getmedcnprescript`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getDatewiseAccount(index) {
    return this.http.get<any>(Api + `datewiseExpand/` + index).pipe(map(res => {
      return res;
    }, error => {
      // this.errorAlert();
      return error;
    }));
  }
  getdepartment() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + `getdepartment`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  gettimings() {
    var data = {
      company_id: localStorage.getItem("company_id"),
    };
    return this.http.post<any>(Api + `gettimings`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
  addNewdepartment(data) {
    return this.http.post<any>(Api + `addNewdepartment`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  addNewtimings(data) {
    return this.http.post<any>(Api + `addNewtimings`, data).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      )
    );
  }
}
