import { Component, QueryList, ViewChildren } from "@angular/core";
import { AddAppointmentComponent } from "../add-appointment/add-appointment.component";
import { appointmentlist } from "src/app/pages/registration/advanced.model";
import { Router } from "@angular/router";
import { Observable, take } from "rxjs";
import { Appointmentlistadvance } from "./appointmentlist.advance";
import {
  AdvancedSortableDirective,
  SortEvent,
} from "src/app/pages/registration/advanced-sortable.directive";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { ModuleService } from "../module.service";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: "app-appointment-list",
  templateUrl: "./appointment-list.component.html",
  styleUrls: ["./appointment-list.component.scss"],
})
export class AppointmentListComponent {
  breadCrumbItems: Array<{}>;
  date: any;
  tables$: Observable<appointmentlist[]>;
  total$: Observable<number>;
  Perform: FormGroup;
  typeeditForm: FormGroup;
  typeValidationForm: FormGroup;
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;

  constructor(
    public service: Appointmentlistadvance,
    private router: Router,
    public modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    public myservice: ModuleService
  ) {
    this.getdata();
  }
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Appointment-List", active: true },
    ];

    this.typeValidationForm = this.formBuilder.group({
      guardian: [""],
      aadhar: [""],
      consultant_fee: ["", [Validators.required]],
      days_to_expiry: ["", [Validators.required]],
      address: [""],
      company_id: [localStorage.getItem("company_id")],
      blood_pressure: [""],
      sugar: [""],
      weight: [""],
      height: [""],
      others: [""],
      expiry_date: [""],
    });
    this.Perform = this.formBuilder.group({
      pdate: ["", [Validators.required]],
      tdate: ["", [Validators.required]],
    });
    this.typeeditForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      number: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      amount: ["", [Validators.required]],
    });
  }
  showSpinner: boolean = false;

  searchdata() {
    this.showSpinner = true;
    var date = {
      fromdate: this.Perform.value.pdate,
      todate: this.Perform.value.tdate,
      company_id: localStorage.getItem("company_id"),
    };
    console.log(date);

    this.service.tables(date);
    this.showSpinner = false;
  }
  typesubmit: boolean;
  get type() {
    return this.typeValidationForm.controls;
  }
  
  getdata() {
    this.tables$ = this.service.tables(this.date);
    this.total$ = this.service.total$;
  }
  gotoprescriptionlist() {
    this.router.navigate(["/hospital/add-appointment"]);
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  patient_details: any;
  centerModal(centerDataModal: any, data) {
    this.patient_details = data;
    this.modalService.open(centerDataModal, { centered: true, size: "lg" });
  }
  typeSubmit() {
    this.typesubmit = true;
    if (this.typeValidationForm.invalid) {
      return;
    } else {
      var data = {
        patientdetails: this.patient_details,
        patientpart: this.typeValidationForm.value,
      };


      var DAYS = this.typeValidationForm.value.days_to_expiry;
      var date = new Date();
      date.setDate(date.getDate() + DAYS * 1);
      this.typeValidationForm.value.expiry_date = date.toLocaleDateString();
      this.myservice.appointmenttopatient(data).subscribe((res: any) => {
        if (res.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Successfully moved to Patient",
            showConfirmButton: false,
            timer: 1500,
          });
          this.typesubmit = false;
          this.modalService.dismissAll();
          this.typeValidationForm.reset();
          this.getdata();
        } else if (res.status == 525) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Patient Already Exist",
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Server Error",
            // showConfirmButton: true
            timer: 3000,
          });
        }
      });
    }
  }
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  isDownloading: boolean = false;
  downloadExcel() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data) => {
      const exportData = data.map((table) => ({
        Name: table.salutation + " " + table.name,
        Age: table.age,
        Number: table.number,
        "Date of Birth": table.dob,
        Gender: table.gender,
        "Appointment Date": table.appointment_date,
        Amount: table.amount,
        Department: table.department_name,
        Doctor: table.doctor_name,
        Problem: table.problem,
        "Information Source": table.infosource,
      }));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer: any = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "Appointment list ");
      this.isDownloading = false; // Reset the flag after download is complete
    });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
  centerModals(centerDataModals: any, data) {
    this.typeeditForm.patchValue({
      name: data.name,
      age: data.age,
      number: data.number,
      dob: data.dob,
      gender: data.gender,
      amount: data.amount,
      id: data.id,
    });
    this.id = data.id;
    this.modalService.open(centerDataModals, { centered: true, size: "lg" });
  }
  id: any;
  edit() {
    this.typesubmit = true;
    if (this.typeeditForm.invalid) {
      return;
    } else
      var data = {
        company_id: localStorage.getItem("company_id"),
        id: this.id,
        name: this.typeeditForm.value.name,
        age: this.typeeditForm.value.age,
        number: this.typeeditForm.value.number,
        dob: this.typeeditForm.value.dob,
        gender: this.typeeditForm.value.gender,
        amount: this.typeeditForm.value.amount,
      };

    this.myservice.Editappointmentlist(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.getdata();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Successfully Upadted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.typesubmit = false;
        this.typeValidationForm.reset();
        this.modalService.dismissAll();
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Oops...",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }
  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Name",
        "Age",
        "Number",
        "Date of Birth",
        "Gender",
        "Appointment Date",
        "Amount",
        "Department",
        "Doctor",
        "Problem",
        "Information Source",
      ];
      const exportData = data.map((table: any) => [
        table.salutation + " " + table.name,
        table.age,
        table.number,
        table.dob,
        table.gender,
        table.appointment_date,
        table.amount,
        table.department_name,
        table.doctor_name,
        table.problem,
        table.infosource,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("AppointmentList.pdf");
      this.isDownloading = false;
    });
  }
}
