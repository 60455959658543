 <div class="m-2">
    <button id="printPageButton" style="margin-top: 1%;" (click)="openPDF()" 
        class="btn btn-primary" >
        Print</button>&nbsp;&nbsp;
    <button id="printPageButton" style="margin-top: 1%;" (click)="Back()" 
        class="btn btn-primary">
        Back</button>&nbsp;&nbsp;
</div> 


<div class="m-3 p-3" style="border: 1px solid balck;">
    <h1 style="text-align: center;padding: 5px;"> <img src={{cLogo}} style="height: 50px;width: 50px;" />
        {{hosName}}</h1>
    &nbsp;
    <div style="text-align: center;">
        <h5>{{address}}</h5>
    </div>
    &nbsp;
    <hr style="border: 3px solid #252b3b;">

    <div style="display: flex;justify-content: space-between;" class="p-3">
        <div>
            <h3 style="font-weight: bold;text-transform: uppercase;">{{name}}</h3>
            <h4>Age : {{age}}</h4>
            <h4>Phone Number : {{number}}</h4>
            <h4>ID : {{id}}</h4>
        </div>
        <div>
            <h4>Test Assigned Date : {{this.gotLabResults[0].test_assign_date }}</h4>
            <h4>Test Completed Date : {{this.gotLabResults[0].completed_date }}</h4>
            <div style="text-align: end;">
                <img style="height: 150px;width: 150px;" src="assets/img/qr-code.png" />
            </div>
        </div>
    </div>
    <hr style="border: 3px solid #252b3b;">
    <h2 style="text-align: center;">LAB TEST RESULTS</h2>
    <hr style="border: 3px solid #252b3b;">
    <div>
        <div *ngFor="let group of resultArrays" class="m-1 p-2">
            <h2 style="text-align: center;">Test Name: {{ group[0].labtest_name }}</h2>
            <div>
                <div class="row p-4">
                    <table>
                        <tbody>
                            <tr>
                                <th>S.no</th>
                                <th>Investigations</th>
                                <th>Reference Values</th>
                                <th>Result</th>
                            </tr>
                            <tr *ngFor="let item of group; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{item.field}}</td>
                                <td>{{item.ranges}}</td>
                                <td style="font-size: 20px;font-weight: 700;">{{item.value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <hr style="border: 3px solid #252b3b;">
    <div style="display: flex;justify-content: space-between;">
        <p>Thanks for reference</p>
        <p>***End of Report***</p>
        <p>Here are your results</p>
    </div>
    <div style="text-align: left;">
        <h5>Lab Authority Signature :</h5>
        <h5>{{hosName}}</h5>

    </div>
    &nbsp;
    &nbsp;
    <div style="text-align: right;">
        <h5>{{hosName}}</h5>
        <h5>{{hosNumber}}</h5>
        <h5>{{email}}</h5>
    </div>

    <div style="text-align: left;">
        <h5>Report Generated on - {{current_date}}</h5>
    </div>
</div>