<table width="98%" style="padding:5px;" align="center">
    <tbody>
        <tr>
            <td colspan="8">
                <table width="98%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td width="6%" rowspan="4" align="left">
                                <div align="center"><img src="../../../assets/images/brands/ava.png"
                                        style="max-height:100px; max-width:800px"></div>
                            </td>
                            <td colspan="3" align="left">
                                <div align="center" class="style66">
                                    {{company}} </div>
                            </td>
                            <td width="8%" rowspan="4" align="left">
                                <!-- <div align="center">
                                    <img src="http://chart.googleapis.com/chart?cht=qr&amp;chl=https%3A%2F%2Ftirupatihms.com%2Fhms%2F%2Fcashreceipts%2Fprint_and_invoice%2F679%402556&amp;chs=130x130&amp;choe=UTF-8&amp;chld=L|1"
                                        alt="">
                                </div> -->
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                    </strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67"><strong>
                                        {{com_add}}</strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="left">
                                <div align="center" class="style67">Phone:-
                                    9177917791,&nbsp;Email:-
                                    infoamaravathisoftware.com</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" style="border:2px solid;" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td colspan="2" align="left" bgcolor="#99FFCC"><b>
                                    <div align="center"><b><span class="style41">
                                                BILL </span></b></div>
                                </b></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style41">
                                    DOB :</span>{{three}}<span class="style41">
                                </span></td>
                            <td align="right"><span class="style41">
                                    Date : </span><span class="style41">
                                    {{four}}</span></td>
                        </tr>
                        <tr>
                            <td><span class="style41">
                                    Patient : </span> <span class="style41"> {{one}} {{two}} </span>
                            </td>
                            <td align="right"><span class="style41">
                                    Age/Sex : </span><span class="style41">{{seven}} Yrs. /
                                    {{six}} </span></td>
                        </tr>
                        <tr>
                            <td align="left"><span class="style41">
                                    Doctor Name :</span><span class="style41">{{five}}</span></td>

                            <td align="right"><span class="style41">
                                    Mobile :</span><span class="style41">{{eight}}</span></td>
                        </tr>
                        <tr>


                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <table width="100%" style="border:2px solid;" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                    Sr. </span></td>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td bgcolor="#99FFCC">
                                <div align="center" class="style41">Problems </div>
                            </td>

                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>


                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">

                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="right" bgcolor="#99FFCC"><span class="style56 style13">
                                </span></td>
                            <td align="center" bgcolor="#99FFCC"></td>
                        </tr>

                        <tr>
                            <td colspan="13">
                                <hr>
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <span class="style13">1</span>
                            </td>
                            <td><span class="style13"></span></td>
                            <td><span class="style13"></span></td>
                            <td>
                                <div align="center" class="style13">{{nine}}</div>
                            </td>

                            <td align="right">
                                <span class="style13" style="padding-right:5px"><span class="style41"></span></span>
                            </td>
                            <td align="right"></td>

                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span>
                            </td>
                            <td align="right" style="padding-right:5px;"><span class="style13"></span></td>
                            <td align="right"><span class="style41"></span></td>
                            <td align="right"><span class="style13" style="padding-right:5px"></span></td>
                            <td align="center"></td>
                        </tr>


                        <tr>
                            <td colspan="13" style="padding-top:0px;">
                                <hr>
                            </td>
                        </tr>



                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13">*This is a
                                    Computerised Bill/Receipt </span></td>
                            <td colspan="10" style="padding-top:0px;">
                                <div align="right" class="style13">{{company}} </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;">&nbsp;</td>

                        </tr>
                        <tr>
                            <td colspan="3" style="padding-top:0px;"><span class="style13"> Wish you speedy
                                    Recovery..... </span></td>

                        </tr>
                        <tr>
                            <td style="padding-top:0px;" colspan="13">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<br><br>
<div class="btns">
    <button mat-raised-button id="printPageButton" style=" margin-top:1%;" (click)="print()" color="primary"
        class="button-customize">
        Print</button>
    <button mat-raised-button id="printPageButton" style=" margin-top:1%;" (click)="Back()" color="primary"
        class="button-customize">
        Back</button>
</div>