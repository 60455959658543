import { DecimalPipe } from '@angular/common';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable, take } from "rxjs";
import { AdvancedSortableDirective, SortEvent } from 'src/app/pages/registration/advanced-sortable.directive';
import { patientlist } from 'src/app/pages/registration/advanced.model';
import { ModuleService } from '../module.service';
import { Patientlistadvanced } from './patientlist.advance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
@Component({
  selector: "app-patient-list",
  templateUrl: "./patient-list.component.html",
  styleUrls: ["./patient-list.component.scss"],
})
export class PatientListComponent {
  // typeValidationForm: FormGroup;
  breadCrumbItems: Array<{}>;
  Perform: FormGroup;

  tables$: Observable<patientlist[]>;
  total$: Observable<number>;
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;
  date: any;
  constructor(
    private myservice: ModuleService,
    private pipe: DecimalPipe,
    public service: Patientlistadvanced,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    //

    this.getdata();
    this.get();

  }

  getdata() {
    var date = {
      fromdate: '',
      todate: '',
      company_id: localStorage.getItem("company_id"),
    };
    this.tables$ = this.service.tables(date);
    this.total$ = this.service.total$;
  }

  currentDate: any;
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Patient-list", active: true },
    ];

    this.Perform = this.formBuilder.group({
      pdate: ["", [Validators.required]],
      tdate: ["", [Validators.required]],
    });

    this.currentDate = new Date().toLocaleDateString()

  }

  showSpinner: boolean = false;
  searchdata() {
    this.showSpinner = true;
    var date = {
      fromdate: this.Perform.value.pdate,
      todate: this.Perform.value.tdate,
      company_id: localStorage.getItem("company_id"),
    };
    this.service.tables(date);
    this.showSpinner = false;
  }
  typesubmit: boolean;
  // get type() {
  //   return this.typeValidationForm.controls;
  // }

  clcik() {
    this.router.navigate(["/hospital/op"]);
  }

  hideme: boolean[] = [];
  data: any = [];

  get() {
    this.myservice.getpatients().subscribe((res: any) => {
      this.data = res.data;
      
      
      for (let i = 0; i <= this.data.length; i++) {
        this.hideme.push(true);
      }
    });
  }

  /////////////////////////////// table start/////////////////////////////////
  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }
  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  patient_id: any;

  // centerModal(centerDataModal: any, data) {
  //   this.patient_id = data.id;
  //   this.typeValidationForm.patchValue({
  //     first_name: data.first_name,
  //     last_name: data.last_name,
  //     age: data.age,
  //     phone_number: data.phone_number,
  //     dob: data.dob,
  //     gender: data.gender,
  //     guardian: data.guardian,
  //     aadhar: data.aadhar,
  //     address: data.address,
  //   });
  //   this.modalService.open(centerDataModal, { centered: true, size: "lg" });
  // }

  // edit() {
  //   this.typesubmit = true;
  //   if (this.typeValidationForm.invalid) {
  //     return;
  //   }
  //   else
  //     this.typeValidationForm.value.patient_id = this.patient_id;
  //   this.myservice.EditPatient(this.typeValidationForm.value).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "success",
  //         title: "Successfully Upadted",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       this.typesubmit = false;
  //       this.typeValidationForm.reset();
  //       this.getdata();
  //       this.get();
  //       this.modalService.dismissAll();
  //     } else {
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "error",
  //         title: "Oops...",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //     }
  //   });
  // }

  gotoprescriptionview(table) {
    this.router.navigate(["/hospital/patient-view"], {
      queryParams: { q: table.patient_id },
    });
  }



  isDownloading: boolean = false;
  downloadExcel() {
    // Check if a download is already in progress
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$
      .pipe(take(1)) // Take only the first emission
      .subscribe((data) => {
        const exportData = data.map((table) => ({
          "Patient Id": table.patient_id,
          "First Name": table.first_name,
          "Last Name": table.last_name,
          "Phone Number": table.phone_number,
          Age: table.age,
          Gender: table.gender,
          DOB: table.dob,
          Date: table.date,
        }));
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer: any = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "Patient list ");
        this.isDownloading = false; // Reset the flag after download is complete
      });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }

  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Patient Id",
        "First Name",
        "Last Name",
        "Phone Number",
        "Age",
        "Gender",
        "DOB",
        "Date",

      ];
      const exportData = data.map((table: any) => [
        table.patient_id,
        table.first_name,
        table.last_name,
        table.phone_number,
        table.age,
        table.gender,
        table.dob,
        table.date,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("Patient-List.pdf");
      this.isDownloading = false;
    });
  }


}












