<div class="vertical-menu">
  <perfect-scrollbar [ngStyle]="{'background-color': color}" style="width: 103% !important;">
    <div id="sidebar-menu">
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>

        <ng-container *ngFor="let item of menuItems;let i=index">
          <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
          <li *ngIf="!item.isTitle && !item.isLayout">
            <!-- <a *ngIf="hasItems(item)" href="javascript:void(0);" > -->

            <a href="javascript:void(0);" (click)="changeValue(i)">

              <i style=" color: white; font-size: x-large;  " class="bx {{item.icon}}"
                *ngIf="item.icon"></i>&nbsp;&nbsp;&nbsp;&nbsp;
              <span style="color: white !important;"> {{ item.label | translate }} </span>

              <span class="badge rounded-pill" style="text-align: right;"><i *ngIf="hideme[i]"
                  class="ri-arrow-down-s-line"></i> <i *ngIf="!hideme[i]" class="ri-arrow-up-s-line"></i></span>
            </a>

            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref">
              <i style="color: white; font-size: x-large;" class="bx ri-arrow-right-fill" *ngIf="item.icon"></i>
              <span> {{ item.label | translate }} </span>
              <span class="badge rounded-pill  bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text
                | translate}}</span>
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a [routerLink]="subitem.link" *ngIf="!hideme[i]" class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId" style="color: white !important;">
                  -&nbsp;&nbsp;
                  {{ subitem.label | translate }}
                </a>
                <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow"
                  [attr.data-parent]="subitem.parentId">
                  {{ subitem.label | translate }}
                </a>
                <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                      class="side-nav-link-ref">
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </perfect-scrollbar>

</div>