import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-roomsreport',
  templateUrl: './roomsreport.component.html',
  styleUrls: ['./roomsreport.component.scss']
})
export class RoomsreportComponent {
  disable: boolean = true;
  opdata: any[] = [];
  address: any;
  company: any;
  one: any;
  two: any;
  three: any;
  four: any;
  five: any;
  six: any;
  seven: any;
  eight: any;
  com_add: any;
  nine: any;
  hos_number: any;
  color: any;
  ten:any;
  eleven:any;

  constructor(private location: Location) {
   

  }
  ngOnInit(): void {
    this.opdata = [JSON.parse(sessionStorage.getItem('roomsprint'))]
    this.company = (localStorage.getItem('company_name'))
    this.com_add = (localStorage.getItem('address'))
    this.color = (localStorage.getItem('color'))
    this.hos_number = (localStorage.getItem('hos_number'))
    this.one = this.opdata[0].first_name
    this.two = this.opdata[0].last_name
    this.three = this.opdata[0].patient_id
    this.four = this.opdata[0].date_of_admit
    this.five = this.opdata[0].address
    this.six = this.opdata[0].gender
    this.seven = this.opdata[0].age
    this.eight = this.opdata[0].phone_number
    this.nine = this.opdata[0].amount
    this.ten= this.opdata[0].discharge_amount
    this.eleven=this.opdata[0].amountpaid
  }
  public openPDF(): void {
    this.disable = false;
    setTimeout(() => {
      window.print();
    }, 200);
  }

  Back() {
    this.location.back();
  }
}





