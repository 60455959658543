import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/account/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent {

  addemployeedetailsform: FormGroup
  breadCrumbItems: Array<{}>;
  loadingpart: boolean = false;
  permissionlist: any;
  hideselect1: boolean = false;
  reset1: any = '';
  clonedata1: any[] = [];
  masterdata1: any[] = [];
  selectedmoduleslist = [];
  notselectedmoduleslist = [];
  totalprojectlist = [];
  checkall = []
  totalmoduleslistarr = [];
  modulelistarr = [];
  constructor(public formBuilder: UntypedFormBuilder, private myservice: ServiceService,) {
    this.getemployee();
    this.getemployeemoduleslist(0);
  }
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Amaravathi' }, { label: 'Permission', active: true }];

    this.addemployeedetailsform = this.formBuilder.group({
      name: ['', [Validators.required]],
      number: ['', [Validators.required]],
      email: ['', [Validators.required]],
      checkallmodules1: [''],
      checkallmodules: [''],
      check_sub_menu: [false]
    });

  }

  formubmit: boolean = false;
  trimInputValue(controlName: string) {
    const control = this.addemployeedetailsform.get(controlName);
    control.setValue(control.value.trim());
  }
  get addCateFormCnt() { return this.addemployeedetailsform.controls }
  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getemployeemoduleslist(event) {
    this.selectedmoduleslist = [];
    var user_id = event;
    this.loadingpart = true;
    this.myservice.getmodulelistdata(user_id).subscribe(resdata => {
      this.totalmoduleslistarr = resdata.data;
      this.loadingpart = false;
      this.totalprojectlist = [];
      this.checkall = [];
      this.totalprojectlist = Object.values(this.totalmoduleslistarr.reduce((r, o) => {

        if (o.check_sub_menu == true) {
          this.selectedmoduleslist.push(o);
        }
        r[o.module_id] = r[o.module_id] || {
          module_id: o.module_id, 'label': o.module_nm, 'icon': o.icon, 'class': "", 'link': o.link, 'badge': "", 'badgeClass': "", 'd_in': o.d_in, 'isExternalLink': 0, 'subcollopescnd': false, checkallstatus: 'Un Check All', checkallmodules: true, 'submenu': [], 'reportdata': []
        };
        r[o.module_id]['submenu'].push({ module_id: o.module_id, module_order: o.module_order, id: o.id, 'label': o.label, 'icon': o.icon, 'class': "", 'link': o.link, 'badge': "", 'badgeClass': "", 'd_in': o.d_in, 'isExternalLink': 0, 'subcollopescnd': false, checkallstatus: 'Un Check All', 'check_sub_menu': o.check_sub_menu, checkallmodules: true, submenu: [] });
        return r;
      }, {}));

      this.totalprojectlist.map(obj => {
        obj.submenu.map(obj2 => {
          if (obj2.check_sub_menu == false) {
            return obj.checkallmodules = false, obj.checkallstatus = 'Check All';
          } else {
            return obj
          }
        })
      })

      this.modulelistarr = resdata.data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.module_id === value.module_id
        ))
      )
    })

  }

  getemployee() {
  }

  noteemail(event: any): boolean {
    // let patt = /^([0-9])$/;
    let patt = /^[a-zA-Z0-9_,.@]*$/;
    let result = patt.test(event.key);
    return result;
  }

  checkallmoduledata(checkallmodules, projectmodules, projectdata) {
    if (checkallmodules == true) {
      checkallmodules = false;
    }
    else if (checkallmodules == false) {
      checkallmodules = true;
    }
    if (checkallmodules == true) {
      projectdata.submenu.map(obj123 => {
        if (obj123.check_sub_menu == false) {
          obj123.check_sub_menu = true;
          this.selectedmoduleslist.push(obj123);
        }
      })
      projectdata.checkallmodules = checkallmodules;
      projectdata.checkallstatus = 'Un Check All';
    } else if (checkallmodules == false) {
      var tepmcheckedmodule = [];
      var ccnt = 0;
      this.selectedmoduleslist.map(obj => {
        ccnt++;
        if (obj.module_id == projectdata.module_id) {
          projectdata.submenu.map(obj123 => {
            obj123.check_sub_menu = false;
          })
        } else {
          tepmcheckedmodule.push(obj);
        }
        if (ccnt == this.selectedmoduleslist.length) {
          this.selectedmoduleslist = [];
          this.selectedmoduleslist = tepmcheckedmodule;
        }
      })
      projectdata.checkallmodules = checkallmodules;
      projectdata.checkallstatus = 'Check All';
    }
  }

  selectedsubmodules(modulecnd, moduledetails, projectdata) {
    if (modulecnd == true) {
      modulecnd = false;
    } else if (modulecnd == false) {
      modulecnd = true;
    }
    if (modulecnd == true) {
      moduledetails.check_sub_menu = modulecnd;
      this.selectedmoduleslist.push(moduledetails);
      var mcnt = 0;
      var checkcnt = 0;
      projectdata.submenu.map(m => {
        mcnt++;
        if (m.check_sub_menu == false) {
          checkcnt = 1;
        }
        if (mcnt == projectdata.submenu.length && checkcnt == 0) {
          projectdata.checkallstatus = 'Un Check All';
          projectdata.checkallmodules = true;
        }
      })
    } else if (modulecnd == false) {
      moduledetails.check_sub_menu = modulecnd;
      this.selectedmoduleslist.map(m => {
        if (m.id == moduledetails.id) {
          var index = this.selectedmoduleslist.indexOf(m);
          this.selectedmoduleslist.splice(index, 1);
        }
      })
      projectdata.checkallstatus = 'Check All';
      projectdata.checkallmodules = false;
    }
  }

  openprojectmodules(subcollopescnd, pdata) {
    if (subcollopescnd == true) {
      subcollopescnd = false;
    } else if (subcollopescnd == false) {
      subcollopescnd = true;
    }
    pdata.subcollopescnd = subcollopescnd;
  }

  submitemployeedata() {
    this.loadingpart = true;
    this.formubmit = true;
    if (this.addemployeedetailsform.invalid) {
      this.loadingpart = false;
      return;
    } else if (this.selectedmoduleslist.length == 0) {
      Swal.fire({
        position: 'top-end',
        icon: 'question',
        title: 'Please Select the Modules ! ',
        showConfirmButton: false,
        timer: 1500
      })
      this.loadingpart = false;
    } else {
      this.addemployeedetailsform.value.selectedmoduleslist = this.selectedmoduleslist;
      this.addemployeedetailsform.value.color = localStorage.getItem('color');
      this.addemployeedetailsform.value.logo = localStorage.getItem('logo');
      this.addemployeedetailsform.value.company_id = localStorage.getItem('company_id');
      this.addemployeedetailsform.value.business_ind = localStorage.getItem('business_ind');
      this.addemployeedetailsform.value.address = localStorage.getItem('address');
      this.addemployeedetailsform.value.hospital_number = localStorage.getItem('hospital_number');
      this.myservice.submituserslogin(this.addemployeedetailsform.value).subscribe(res => {
        if (res.status == 300) {
          this.loadingpart = false;
          Swal.fire({
            position: 'top-end',
            icon: 'question',
            title: 'Mobile Number Already Exists',
            showConfirmButton: false,
            timer: 1500
          });
        } else if (res.status == 200) {

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Successfully Permissions Added ! ',
            showConfirmButton: false,
            timer: 1500
          });
          this.addemployeedetailsform.reset();
          this.getemployeemoduleslist(0);
          this.loadingpart = false;
        }
      })
    }
  }

  modulesname: any = [];
  checkUncheckAll(event) {
    console.log(event.target.value);

    this.totalprojectlist.forEach((c) => (c.isChecked = event.target.checked));
    this.modulesname = [];
    if (event.target.checked == true) {
      for (let i = 0; i <= this.totalprojectlist.length; i++) {
        this.modulesname.push({
          id: this.totalprojectlist[i].id,
          label: this.totalprojectlist[i].label,
          subItems: this.totalprojectlist[i].subItems,
        });
      }
    }
  }


}







