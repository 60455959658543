import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-labreports',
  templateUrl: './labreports.component.html',
  styleUrls: ['./labreports.component.scss']
})
export class LabreportsComponent {
  par1: any;
  disable: boolean = true;
  par2: any;
  opdata: any[] = [];
  address: any;
  company: any;
  one: any;
  three: any;
  four: any;
  eight: any;
  com_add: any;
  nine: any;
  hos_number: any;
  color: any;
  business_ind:any;

  constructor(public activate: ActivatedRoute,private location:Location) {

    this.business_ind = localStorage.getItem('business_ind')
    this.activate.queryParams.subscribe((parms) => {
      this.par1 = JSON.parse(parms.labreport1)
      this.one = this.par1.name
      this.four = this.par1.date
      if (this.business_ind == 0){
        this.three = this.par1.patient_id
      }
      else {
        this.three = this.par1.sample_id
      }
      this.eight = this.par1.number
      this.nine = this.par1.grandtotal
      this.par2 = JSON.parse(parms.labreport2)
    })
  }

  ngOnInit(): void {
    this.company = (localStorage.getItem('company_name'))
    this.com_add = (localStorage.getItem('address'))
    this.hos_number = (localStorage.getItem('hos_number'))
  }

  Back() {
    this.location.back();
  }
  public openPDF(): void {
    this.disable = false;
    setTimeout(() => {
      window.print();
    }, 200);
  }
}

