import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

var Api = environment.Api;

@Injectable({
  providedIn: 'root'
})
export class ReportsserviceService {

  constructor(private http: HttpClient) {

  }
  errorAlert() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      timer: 1500
    })
  }
  getpatients() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getpatients`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
  getAccount() {
    return this.http.get<any>(Api + `getAccount`).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }

  getDatewiseAccount(data) {
    return this.http.post<any>(Api + `datewiseExpand`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }


  gettodayaccount() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `gettodayaccount`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }

  getDatewiseamount(data) {
    return this.http.post<any>(Api + `getDatewiseamount`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }


  ////////////////////////////// analaysis 

  getRoomsOccupancy() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getRoomsOccupancy`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }



  getMonthWisePatients() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getMonthWisePatients`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }


  getTopMedicinesSale() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getTopMedicinesSale`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }


  getTotalIncomeCompany() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `getTotalIncomeCompany`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }

  last7daysLabsIncome() {
    var data = {
      company_id: localStorage.getItem('company_id')
    }
    return this.http.post<any>(Api + `lastSvndaysLabsIncome`, data).pipe(map(res => {
      return res;
    }, error => {
      this.errorAlert();
      return error;
    }));
  }

}
