import { Component, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, take } from "rxjs";
import {
  AdvancedSortableDirective,
  SortEvent,
} from "src/app/pages/registration/advanced-sortable.directive";
import { ServiceService } from 'src/app/account/service.service';
import { registerationlist } from "../registration/advanced.model";
import { UntypedFormBuilder } from "@angular/forms";
import { registerationlistAdvance } from "./registeration-list.advance";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: "app-registeration-list",
  templateUrl: "./registeration-list.component.html",
  styleUrls: ["./registeration-list.component.scss"],
})
export class RegisterationListComponent {
  tables$: Observable<registerationlist[]>;
  total$: Observable<number>;
  breadCrumbItems: any;
  data: any;
  date: any;

  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private myservice: ServiceService,
    public route: ActivatedRoute,
    private router: Router,
    public modalService: NgbModal,
    public service: registerationlistAdvance
  ) {
    this.tables$ = this.service.tables();
    this.total$ = this.service.total$;
    this.get();
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  loadingpart: boolean = false;
  getactive(event, id) {
    this.loadingpart = true;
    if (event == "1") {
      var idfg = "0";
    } else {
      var idfg = "1";
    }
    var data = {
      active_ind: idfg,
      id: id,
    };
    this.myservice.postactive_status(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.fgetdata();
        if (idfg == "1") {
          this.loadingpart = false;
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Account must be Deactived !",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.loadingpart = false;
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Account must be Actived !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire("Failed");
        this.loadingpart = false;
      }
    });
  }
  fgetdata() {
    this.tables$ = this.service.tables();
    this.total$ = this.service.total$;
  }

  delete(i) {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to delete it ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = { id: i.id };
        this.myservice.delete_company(data).subscribe((res: any) => {
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            this.fgetdata();
          }
        });
      }
    });
  }
  get() {
    this.loadingpart = true;
    this.myservice.gethospitaldetails().subscribe((res: any) => {
      this.data = res.data;
      this.loadingpart = false;
      for (let i = 0; i <= this.data.length; i++) {
        this.hideme.push(true);
      }
    });
  }
  hideme: boolean[] = [];
  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }
  //excell download//
  isDownloading: boolean = false;
  downloadExcel() {
    // Check if a download is already in progress
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$
      .pipe(take(1)) // Take only the first emission
      .subscribe((data) => {
        const exportData = data.map((table) => ({
          Name: table.name,
          Number: table.number,
          Email: table.email,
          "Hospital Name": table.hospital_name,
          "Hospital Number": table.hospital_number,
          "Package Type": table.package_type,
          "Purchase Date": table.date,
          "Expiry Date": table.Expiry_date,
          "Paid Amount": table.final_price,
          "Payment Type": table.payment_type,
          "Id Proof": table.id_proof,
          Address: table.address,
        }));
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer: any = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "Registeration");
        this.isDownloading = false; // Reset the flag after download is complete
      });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
  //pdfdownload//
  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Name",
        "Number",
        "Email",
        "Hospital Name",
        "Hospital Number",
        "Package Type",
        "Purchase Date",
        "Expiry Date",
        "Paid Amount",
        "Payment Type",
        "Id Proof",
        "Address",
      ];
      const exportData = data.map((table: any) => [
        table.name,
        table.number,
        table.email,
        table.hospital_name,
        table.hospital_number,
        table.package_type,
        table.date,
        table.Expiry_date,
        table.final_price,
        table.payment_type,
        table.id_proof,
        table.address,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("Registerations.pdf");
      this.isDownloading = false;
    });
  }

}
