<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Registration List Table</h4>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <label class="d-inline-flex align-items-center">
                                Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                                entries
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-2" style="text-align: left">
                        <div class="form-group mb-0" style="text-align: right;">
                            <button class="btn btn-secondary me-1" (click)="downloadExcel()">Excel</button>
                            <button class="btn btn-secondary" (click)="downloadPdf()"> PDF</button>
                        </div>

                    </div>
                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th></th>
                                <th>S . no</th>
                                <th sortable="name" (sort)="onSort($event)">
                                    Name
                                </th>
                                <th sortable="number" (sort)="onSort($event)">
                                    Number
                                </th>
                                <th sortable="email" (sort)="onSort($event)">
                                    Email
                                </th>
                                <th sortable="hospital_name" (sort)="onSort($event)">
                                    Hospital Name
                                </th>
                                <th sortable="hospital_number" (sort)="onSort($event)">
                                    Hospital Number
                                </th>
                                <th sortable="package_type" (sort)="onSort($event)">
                                    Package Type
                                </th>
                                <th sortable="date" (sort)="onSort($event)">
                                    Product Purchase Date
                                </th>
                                <th sortable="Expiry_date" (sort)="onSort($event)">
                                    Product Expiry Date
                                </th>
                                <th sortable="bussiness_id" (sort)="onSort($event)">
                                    Business Type
                                </th>
                                <th>Active Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let table of tables$ | async;let i=index">
                            <tr>
                                <td align="center">
                                    <a class="expand-row" (click)="changeValue(i)">
                                        +
                                    </a>
                                </td>
                                <td>{{i+1}}</td>
                                <td>
                                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.number" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.email" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.hospital_name"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.hospital_number"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.package_type"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>

                                <td>{{table.date | date:'dd-MM-yyyy'}}</td>
                                <td>{{table. Expiry_date | date:'dd-MM-yyyy'}}</td>
                                <td>
                                    <!-- <ngb-highlight [result]="table.bussiness_id"
                                        [term]="service.searchTerm"></ngb-highlight> -->
                                    <p *ngIf="table.bussiness_id==0">Hospital</p>
                                    <p *ngIf="table.bussiness_id==1">Store</p>
                                </td>
                                <td align="center">
                                    <label class="switch" (click)="getactive(table.active_status , table.id)">
                                        <input type="checkbox" [checked]="table.active_status==0">
                                        <div class="slider"></div>

                                        <div class="slider-card">
                                            <div class="slider-card-face slider-card-front"></div>
                                            <div class="slider-card-face slider-card-back"></div>
                                        </div>
                                    </label>
                                    <p *ngIf="table.active_status==0">Active</p>
                                    <p *ngIf="table.active_status==1">In-Active</p>
                                </td>
                                <td>
                                    <i style="font-size: 19px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 3%;"
                                        class=" ri-delete-bin-2-line" title="Delete"></i>
                                </td>
                            </tr>
                            <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                                <td align="center"></td>
                                <td colspan="15" class="p-0">
                                    <table class="table mb-0 table-striped">
                                        <thead>
                                            <tr>
                                                <th>Color</th>
                                                <th>:</th>
                                                <td>
                                                    <div style="width:60px;height:30px;border-radius: 4px; "
                                                        [ngStyle]="{'background-color': table.color}">
                                                    </div>
                                                    {{table.color}}
                                                </td>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Paid Amount</th>
                                                <th>:</th>
                                                <th>{{table.final_price}}</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Payment Type</th>
                                                <th>:</th>
                                                <th>{{table.payment_type}}</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>ID Proof</th>
                                                <th>:</th>
                                                <th>{{table.id_proof}}</th>
                                                <th></th>
                                                <th></th>
                                            </tr>

                                            <tr>
                                                <th>ID Proof Image</th>
                                                <th>:</th>
                                                <a style="cursor:pointer;">
                                                    <img src="{{table.proof_img}}" style="width:80px;height: 80px;"
                                                        onerror="this.onerror=null;this.src='assets/img/avatar.png';"
                                                        height="70px" width="70px">
                                                </a>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Address</th>
                                                <th>:</th>
                                                <td>{{table.address}}</td>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Logo</th>
                                                <th>:</th>
                                                <th>
                                                    <a style="cursor:pointer;">
                                                        <img src="{{table.logo}}" style="width:80px;height: 80px;"
                                                            onerror="this.onerror=null;this.src='assets/img/avatar.png';"
                                                            height="70px" width="70px">
                                                    </a>
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Main Modules</th>
                                                <th>:</th>
                                                <th>
                                                    <li *ngFor="let item of table.module_nm "
                                                        style="text-align:left !important;">
                                                        {{item}}
                                                    </li>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <br>
                                    <br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <!-- End table -->

                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-end float-md-end s pagination-rounded">
                            <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->

                </div>
            </div>
        </div>
    </div>
</div>
