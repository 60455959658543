<table border='0' cellpadding='3' cellspacing='3' width='600px' align="center"
    style="border:3px solid #000000;border-radius:10px;margin-top: 20%;">
    <tr align="center" valign="top">
        <td>
            <table border='0' cellpadding='3' cellspacing='3' width='100%' align="center">

                <tr align="center" style="height:100px;">
                    <td colspan="3">
                        <h1 class="style" style="font-size:16px;line-height:5px;margin-top: 25px;"><b
                                style="text-transform: uppercase;">{{this.main_patient.first_name}}{{this.main_patient.last_name}}</b> Details </h1><br>
                    </td>
                </tr>

                <tr align="center" valign="top">
                    <td colspan="2">
                        <table border='0' cellpadding='3' cellspacing='3' width='100%' align="center">
                            <tr align="left">
                                <td style="padding-left: 25%;font-weight: bold;" class="style" width="10%" nowrap>Age </td>
                                <td style="padding-left: 25%;font-weight: bold;"class="style" width="4%"> </td>
                                <td style="padding-left: 25%;font-weight: bold;">{{this.main_patient.age}}</td>
                            </tr>
                            <tr align="left">
                                <td style="padding-left: 25%;font-weight: bold;" class="style" width="10%" nowrap>Number </td>
                                <td style="padding-left: 25%;font-weight: bold;"class="style" width="4%"> </td>
                                <td style="padding-left: 25%;font-weight: bold;">{{this.main_patient.phone_number}}</td>
                            </tr>
                        </table><br>
                    </td>
                </tr>
                <tr align="center">
                    <td colspan="3">

                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <span style="color: red;font-size: 25px;text-align: center;" *ngIf="details.length==0">No Data Found</span>
</table><br><br>

<button mat-raised-button id="printPageButton" style="margin-left: 50%;" (click)="openPDF()" color="primary"
    class="button-customize">
    Print</button>&nbsp;&nbsp;
