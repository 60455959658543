import { Component } from '@angular/core';
import { ModuleService } from '../module.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.scss']
})
export class AddDoctorComponent {
  breadCrumbItems: Array<{}>;
  addDoctorForm: FormGroup;
  addNew: FormGroup;
  addNewtime: FormGroup;
  submitte: boolean = false
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Add-Doctor", active: true },
    ];
    this.get();
  }
  constructor(public formBuilder: UntypedFormBuilder, private myservice: ModuleService, private pipe: DecimalPipe, private router: Router, public modalService: NgbModal) {

    this.addDoctorForm = this.formBuilder.group({
      // doctor_id: ['', [Validators.required]],
      doctor_name: ["", [Validators.required]],
      doctor_age: ["", [Validators.required]],
      doctor_gender: ["", [Validators.required]],
      doctor_number: ["", [Validators.required]],
      doctor_address: ["", [Validators.required]],
      license_number: ["", [Validators.required]],
      doctor_timings: ["", [Validators.required]],
      doctor_charges: ["", [Validators.required]],
      doctor_dept: ["", [Validators.required]],
      doctor_experience: ["", Validators.required],
      doctor_email: ["", [Validators.required]],
      description: [""],
    });
    this.addNew = this.formBuilder.group({
      new_type: [""]
    });
    this.addNewtime = this.formBuilder.group({
      new_time: [""]
    })
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get valid() {
    return this.addDoctorForm.controls
  }


  addDoctor() {
    this.submitte = true;
    if (this.addDoctorForm.invalid) {
      return;
    } else {
      this.addDoctorForm.value.company_id = localStorage.getItem('company_id');
      this.myservice.Add_doctor(this.addDoctorForm.value).subscribe((res: any) => {
        if (res.status == 200) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Successfully Submitted',
            showConfirmButton: false,
            timer: 1500
          });
          this.submitte = false;
          this.addDoctorForm.reset();
        } else {
          Swal.fire('Failed');
        }
      })
    }
  }
  gotoprescriptionlist() {
    this.router.navigate(['/hospital/doctorlist']);
  }

  department: any[];
  timings: any[];
  get() {
    this.myservice.getdepartment().subscribe((res: any) => {
      this.department = res.data;
    });
    this.myservice.gettimings().subscribe((res: any) => {
      this.timings = res.data;
    });
  }
  changeType(e: any, openPopforNew) {

    if (e == 'ADD') {
      this.addnewtype(openPopforNew)
    }
  }

  addnewtype(openPopforNew) {
    this.modalService.open(openPopforNew, { centered: true, size: "l" });
  }

  dis() {
    this.modalService.dismissAll()
  }
  sub: boolean = false;
  subs: boolean = false;
  submitNew() {
    this.sub = true
    if (this.addNew.invalid) {
      alert("Please Add Type")
    }
    else {
      var data = {
        'name': this.addNew.value.new_type,
        'company_id': localStorage.getItem('company_id')
      }
      this.myservice.addNewdepartment(data).subscribe((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: "Good job!",
            text: "Type Added",
            icon: "success"
          })
          this.modalService.dismissAll()
          this.addNew.reset()
        }
      })
    }
  }
  submitNewtime() {
    this.subs = true
    if (this.addNewtime.invalid) {
      alert("Please Add Type")
    }
    else {
      var data = {
        'name': this.addNewtime.value.new_time,
        'company_id': localStorage.getItem('company_id')
      }
      this.myservice.addNewtimings(data).subscribe((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: "Good job!",
            text: "Timing Added",
            icon: "success"
          })
          this.modalService.dismissAll()
          this.addNew.reset()
        }
      })
    }
  }
}
