import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nazox';


  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('usr_id') == '' || localStorage.getItem('usr_id') == null || localStorage.getItem('usr_id') == 'undefined') {
      this.router.navigate(['/account/auth/login'])
    } else {
      this.router.navigate(['/'])
    }

  }



}
