import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sales-print',
  templateUrl: './sales-print.component.html',
  styleUrls: ['./sales-print.component.scss']
})
export class SalesPrintComponent {
  firstData:any;
  secondData:any;

  companyName:any;
  companyAddress:any;
  companyNumber:any;
  a:any;
  b:any;
  data1:any;
  data2:any;
  grandTotal:any;
  
  pName:any;
  pId:any;
  pAge:any;
  constructor(public route: ActivatedRoute){

    this.a = localStorage.getItem("salesReport1")
     this.b = localStorage.getItem("salesReports2")
     this.companyName = localStorage.getItem(('name'))
     this.companyAddress = localStorage.getItem(('address'))
     this.companyNumber = localStorage.getItem(('hospital_number'))
     this.data1 = JSON.parse(this.a)
     this.data2 = JSON.parse(this.b)
     this.grandTotal = this.data1.grandtotal
     this.pName=this.data1.name
     this.pId = this.data1.patient_id
     this.pAge=this.data1.age
    
  }
}
