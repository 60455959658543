import { Component, QueryList, ViewChildren } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { patientlist, prescriptionlist } from 'src/app/pages/registration/advanced.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable,take } from 'rxjs';
import { AdvancedSortableDirective, SortEvent } from 'src/app/pages/registration/advanced-sortable.directive';
import { Prescriptionlistadvanced } from './prescriptionlist.advance';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import "jspdf-autotable";
@Component({
  selector: "app-prescription-list",
  templateUrl: "./prescription-list.component.html",
  styleUrls: ["./prescription-list.component.scss"],
})
export class PrescriptionListComponent {
  breadCrumbItems: Array<{}>;
  tableData: patientlist[];
  tables$: Observable<prescriptionlist[]>;
  total$: Observable<number>;

  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;

  constructor(
    private pipe: DecimalPipe,
    public service: Prescriptionlistadvanced,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.getdata();
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Patient Prescription-list", active: true },
    ];
  }

  getdata() {
    this.tables$ = this.service.tables$;
   

    this.total$ = this.service.total$;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  gotoprescriptionview(event: any) {
    this.router.navigate(["/hospital/prescription-view"], {
      queryParams: { q: event.patient_id },
    });
  }

  gotoprescriptionview1() {
    this.router.navigate(["/hospital/add-prescription"]);
  }

  //excell download//
  isDownloading: boolean = false;
  downloadExcel() {
    // Check if a download is already in progress
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$
      .pipe(take(1)) // Take only the first emission
      .subscribe((data) => {
        const exportData = data.map((table) => ({
          "Patient Id": table.patient_id,
          Name: table.first_name,
          Weight: table.weight,
          "BP": table.bp,
          Reference: table.reference,
          "Description": table.description,

        }));
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer: any = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "Prescription list ");
        this.isDownloading = false; // Reset the flag after download is complete
      });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
}

