import { Component, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../module.service';
import Swal from 'sweetalert2';
import { AdvancedSortableDirective, SortDirection, SortEvent } from 'src/app/pages/registration/advanced-sortable.directive';
import { BehaviorSubject, Observable, Subject, debounceTime, delay, of, switchMap, tap } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-op",
  templateUrl: "./op.component.html",
  styleUrls: ["./op.component.scss"],
})
export class OPComponent {
  breadCrumbItems: Array<{}>;
  typeValidationForm: FormGroup; // type validation form
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;
  now: any;
  today: any;
  editForm: any;
  submitted: boolean = false
  constructor(
    public formBuilder: UntypedFormBuilder,
    private myservice: ModuleService,
    public modalService: NgbModal,
    private pipe: DecimalPipe,
    private router: Router,
    public datePipe: DatePipe
  ) {
    this.getDoctors();
    this.today = new Date();
    this.now = datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  loadingpart: boolean = false;

  ngOnInit(): void {
    this.
      breadCrumbItems = [
        { label: "Amaravathi" },
        { label: "Add-Patient", active: true },
      ];

    this.typeValidationForm = this.formBuilder.group({
      salutation: ["", [Validators.required]],
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      guardian: [""],
      aadhar: [""],
      consultant_fee: ["", [Validators.required]],
      days_to_expiry: ["", [Validators.required]],
      address: [""],
      blood_pressure: [""],
      sugar: [""],
      weight: [""],
      height: [""],
      others: [""],
      expiry_date: [""],
      date: [""],
      dpt_name: [""],
      d_name: [""],
      description: [""],
    });

    this.editForm = this.formBuilder.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      guardian: [""],
      aadhar: [""],
      address: [""],
    })
    this.get();
    this.getDoctors();
    // this.tables12();
  }

  typesubmit: boolean;
  get type() {
    return this.typeValidationForm.controls;
  }


  typeSubmit() {
    this.loadingpart = true;
    this.typesubmit = true;
    if (this.typeValidationForm.invalid) {
      this.loadingpart = false;
      return;
    } else {
      this.typeValidationForm.value.company_id =
        localStorage.getItem("company_id");
      var DAYS = this.typeValidationForm.value.days_to_expiry;
      var today = new Date().toLocaleDateString();
      this.typeValidationForm.value.date = today;
      var date = new Date();
      date.setDate(date.getDate() + DAYS * 1);
      this.typeValidationForm.value.expiry_date = date.toLocaleDateString();
      this.myservice
        .addpatient(this.typeValidationForm.value)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.loadingpart = false;
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Successfully Submitted",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadingpart = false;
            this.typesubmit = false;
            this.typeValidationForm.reset();
            this.get();
          } else {
            Swal.fire("Failed");
            this.loadingpart = false;
          }
        });
    }
  }

  hit() {
    var $popup = window.open(
      "op-print",
      "popup",
      "menubar=1,resizable=1,left=150,top=150,scrollbars=1"
    );
  }

  clcick() {
    this.router.navigate(["/hospital/patient-list"]);
  }

  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  close() {
    this.modalService.dismissAll();
  }

  hideme: boolean[] = [];
  data: any = [];

  get() {
    this.myservice.getpatients().subscribe((res: any) => {
      this.data = res.data;
      console.log(this.data);
      
      for (let i = 0; i < this.data.length; i++) {
        this.hideme.push(true);
      }
    });
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }
  printData(data) {
    sessionStorage.setItem("opprint", JSON.stringify(data));
    this.router.navigate(["op-print"]);
  }

  doctorData: any;
  getDoctors() {
    this.myservice.getDoctorsData().subscribe((res) => {
      this.doctorData = res.data;
    });
  }

  autoFdoctor(e) {

    this.doctorData.map((res) => {
      if (res.doctor_name == e) {
        this.typeValidationForm.patchValue({
          dpt_name: res.doctor_dept,
        });
      }
    });
  }

  addvital(centerDataModal) {
    this.modalService.open(centerDataModal, { centered: true, size: "lg" });
  }

  dismiss(editFormTempo) {
    this.modalService.dismissAll(editFormTempo)
  }



  get valid() {
    return this.editForm.controls;
  }
 
  patient_id:any;
  editPatientDetails(data, editFormTempo) {
    this.patient_id = data.id;
    this.editForm.patchValue({
      first_name: data.first_name,
      last_name: data.last_name,
      age: data.age,
      phone_number: data.phone_number,
      dob: data.dob,
      gender: data.gender,
      guardian: data.guardian,
      aadhar: data.aadhar,
      address: data.address,
    });
    this.modalService.open(editFormTempo, { centered: true, size: "lg" });
  }

  editData() {
    this.submitted = true
    if (this.editForm.invalid) {
      alert("Please fill all details")
    }
    else{
      this.editForm.value.patient_id = this.patient_id;
      this.myservice.EditPatient(this.editForm.value).subscribe((res: any) => {
        if (res.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Successfully Upadted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.submitted = false;
          this.editForm.reset();
          this.get()
          this.modalService.dismissAll();
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Oops...",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  }



}
