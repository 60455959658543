import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RegistrationComponent } from './registration/registration.component';
import { PermissionComponent } from './permission/permission.component';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { RegisterationListComponent } from './registeration-list/registeration-list.component';

const routes: Routes = [
  { path: "", component: DashboardComponent },
  { path: "register", component: RegistrationComponent },
  { path: "permission", component: PermissionComponent },
  { path: "permission-list", component: PermissionListComponent },
  { path: "registeration-list", component: RegisterationListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
