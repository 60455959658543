import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-op-print',
  templateUrl: './op-print.component.html',
  styleUrls: ['./op-print.component.scss']
})
export class OpPrintComponent {
  disable: boolean = true;
  opdata: any[] = [];
  address: any;
  company: any;
  one: any;
  two: any;
  three: any;
  four: any;
  five: any;
  six: any;
  seven: any;
  eight: any;
  com_add: any;
  nine: any;
  hos_number: any;
  color: any;
  ngOnInit(): void {
    this.opdata = [JSON.parse(sessionStorage.getItem('opprint'))]
    this.company = (localStorage.getItem('company_name'))
    this.com_add = (localStorage.getItem('address'))
    this.color = (localStorage.getItem('color'))
    this.hos_number = (localStorage.getItem('hos_number'))
    this.one = this.opdata[0].first_name
    this.two = this.opdata[0].last_name
    this.three = this.opdata[0].patient_id
    this.four = this.opdata[0].date
    this.five = this.opdata[0].address
    this.six = this.opdata[0].gender
    this.seven = this.opdata[0].age
    this.eight = this.opdata[0].phone_number
    this.nine = this.opdata[0].consultant_fee
  }

  constructor(private location: Location) {


  }
  public openPDF(): void {
    this.disable = false;
    setTimeout(() => {
      window.print();
    }, 200);
  }

  Back() {
    this.location.back();
  }
}







