<div class="container-fluid">
    <app-pagetitle title="Doctor List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>


<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <button type="button" class="btn btn-success" (click)="gotoprescriptionview1()">
                    <i class=" ri-menu-2-fill align-middle me-2"></i> Add Doctor
                </button>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-8">
                        <div class="dataTables_length" id="tickets-table_length">
                            <br>
                            <label class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2" style="text-align: left">
                        <div class="form-group mb-0" style="text-align: right;">
                            <button class="btn btn-secondary me-1" (click)="downloadExcel()">Excel</button>
                            <button class="btn btn-secondary" (click)="downloadPdf()"> PDF</button>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-2">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                                class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                            </label>
                        </div>
                    </div>

                </div>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th sortable="doctor_name" (sort)="onSort($event)">Doctor name</th>
                                <th sortable="doctor_age" (sort)="onSort($event)">Doctor Age</th>
                                <th sortable="doctor_gender" (sort)="onSort($event)">Gender</th>
                                <th sortable="doctor_number" (sort)="onSort($event)">Mobile Number</th>
                                <th sortable="doctor_address" (sort)="onSort($event)">Address</th>
                                <th sortable="license_number" (sort)="onSort($event)">License Number </th>
                                <th sortable="doctor_timings" (sort)="onSort($event)">Doctor Timings</th>
                                <th sortable="doctor_charges" (sort)="onSort($event)">Doctor Charges</th>
                                <th sortable="doctor_dept" (sort)="onSort($event)">Doctor Dept</th>
                                <th sortable="doctor_experience" (sort)="onSort($event)">Doctor Experience</th>
                                <th sortable="doctor_email" (sort)="onSort($event)">Doctor Mail</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let table of tables$ | async;let i=index">
                                <td>
                                    {{i+1}}
                                </td>

                                <td>
                                    <ngb-highlight [result]="table.doctor_name"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    {{table.doctor_age}}
                                </td>
                                <td>
                                    {{table.doctor_gender}}
                                </td>
                                <td>
                                    {{table.doctor_number}}
                                </td>
                                <td>
                                    {{table.doctor_address}}
                                </td>
                                <td>
                                    {{table.license_number}}
                                </td>
                                <td>
                                    {{table.doctor_timings}}
                                </td>
                                <td>
                                    {{table.doctor_charges}}
                                </td>
                                <td>
                                    {{table.doctor_dept}}
                                </td>
                                <td>
                                    {{table.doctor_experience}}
                                </td>
                                <td>
                                    {{table.doctor_email}}
                                </td>
                                <td>
                                    <div style="display: flex;">


                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(31, 115, 31);color: white;padding: 2%;"
                                            class="ri-edit-fill" title="Edit"
                                            (click)="centerModal(centerDataModal,table)"></i>&nbsp;&nbsp;
                                        <i style="font-size: 20px;border-radius:5px;background-color: rgb(83, 12, 12);color: white;padding: 2%;"
                                            class="ri-delete-bin-2-line" title="Delete" (click)="Delete(table)"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-end float-md-end s pagination-rounded">
                            <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #centerDataModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Edit Doctor Details </h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="edit()" [formGroup]="typeValidationForm">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Name <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_name"
                            [ngClass]="{'is-invalid': typesubmit && type.first_name.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_name.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_name.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_age"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_age.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_age.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_age.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Gender <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_gender"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_gender.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_gender.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_gender.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Phone Number <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_number"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_number.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_number.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_number.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Address <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_address"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_address.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_address.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_address.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>License Number<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="license_number"
                            [ngClass]="{'is-invalid': typesubmit && type.license_number.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.license_number.errors" class="invalid-feedback">
                            <span *ngIf="type.license_number.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Timings <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <ng-select formControlName="doctor_timings">
                            <ng-option value="" disabled>Select Timings</ng-option>
                            <ng-option value="10 AM TO 1 PM">10 AM TO 1 PM</ng-option>
                            <ng-option value="3PM TO 6PM">3 PM TO 6 PM</ng-option>
                            <ng-option value="10 AM TO 8PM">10 PM TO 8 PM</ng-option>
                        </ng-select>
                        <div *ngIf="typesubmit && type.doctor_timings.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_timings.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Charges <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_charges"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_charges.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_charges.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_charges.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Depeartment <span style="color:red">&nbsp;&nbsp;*</span></label>
                        <ng-select formControlName="doctor_dept">
                            <ng-option value="CARDIOLOGY">CARDIOLOGY</ng-option>
                            <ng-option value="NEUROLOGY">NEUROLOGY</ng-option>
                            <ng-option value="UROLOGY">UROLOGY</ng-option>
                            <ng-option value="ORTHOPEDIC">ORTHOPEDIC</ng-option>
                            <ng-option value="GASTROENTEROLOGIST">GASTROENTEROLOGIST</ng-option>
                            <ng-option value="NEPHROLOGY">NEPHROLOGY</ng-option>
                            <ng-option value="GYNECOLOGIST">GYNECOLOGIST</ng-option>
                        </ng-select>
                        <div *ngIf="typesubmit && type.doctor_dept.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_dept.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Exprience<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_experience"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_experience.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_experience.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_experience.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Doctor Email<span style="color:red">&nbsp;&nbsp;*</span></label>
                        <input type="text" class="form-control" formControlName="doctor_email"
                            [ngClass]="{'is-invalid': typesubmit && type.doctor_email.errors}"
                            placeholder="Type something" />
                        <div *ngIf="typesubmit && type.doctor_email.errors" class="invalid-feedback">
                            <span *ngIf="type.doctor_email.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary me-1"> Save changes </button>
                    <button type="reset" class="btn btn-secondary"> Reset</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>