import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../module.service';
import { DecimalPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-add-prescription",
  templateUrl: "./add-prescription.component.html",
  styleUrls: ["./add-prescription.component.scss"],
})
export class AddPrescriptionComponent {
  loadingpart: boolean = false;
  breadCrumbItems: Array<{}>;

  form: FormGroup;
  medicine_form: FormGroup;
  diagnosis_from: FormGroup;
  patient_id: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private myservice: ModuleService,
    private pipe: DecimalPipe,
    public route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Add - Prescription", active: true },
    ];

    this.form = this.formBuilder.group({
      patient_id: ["", [Validators.required]],
      name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      weight: [""],
      bp: [""],
      height: [""],
      prescription_description: [""],
    });

    this.medicine_form = this.formBuilder.group({
      Medicine_Name: ["", [Validators.required]],
      Medicine_Type: ["", [Validators.required]],
      Medicine_Instruction: ["", [Validators.required]],
      Medicine_Days: ["", [Validators.required]],
      timings: [""]
    });

    this.diagnosis_from = this.formBuilder.group({
      diagnosis: ["", [Validators.required]],
      diagnosis_instruction: ["", [Validators.required]],
    });

    this.get();
    this.getmedicine();
  }

  reset() {
    this.medicine_form.reset();
    this.diagnosis_from.reset();
    // this.typesubmit = false;
  }
  typesubmit: boolean;

  get type() {
    return this.form.controls;
  }

  typesubmit2: boolean;

  get type2() {
    return this.medicine_form.controls;
  }

  typesubmit3: boolean;

  get type3() {
    return this.diagnosis_from.controls;
  }

  hideme: boolean[] = [];
  data: any = [];
  medicinedata: any;
  get() {
    this.loadingpart = true;
    this.myservice.getpatients().subscribe((res: any) => {
      this.data = res.data;
      this.loadingpart = false;
      for (let i = 0; i <= this.data.length; i++) {
        this.hideme.push(true);
      }
    });
  }
  getmedicine() {
    this.myservice.getmedicine().subscribe((res: any) => {
      this.medicinedata = res.data;


    });
  }
  changepns(medicine_name) {

    this.medicinedata.map((res) => {
      if (res.medicine_name == medicine_name) {
        this.medicine_form.patchValue({
          Medicine_Type: res.medicine_type
        })
      }
    })
  }
  changeFn(event) {
    var result = this.data.find((o) => o.patient_id === event);


    this.form.patchValue({
      name: result.first_name + result.last_name,
      age: result.age,
      phone_number: result.phone_number,
      dob: result.dob,
      gender: result.gender,
      weight: result.weight,
      height: result.height,
      bp: result.blood_pressure,
    });
  }

  medicine_list: any = [];
  diagnosis_list: any = [];

  addmedicine() {
    this.typesubmit2 = true;
    if (this.medicine_form.invalid) {
      return;
    } else {
      this.medicine_list.push({
        Medicine_Name: this.medicine_form.value.Medicine_Name,
        Medicine_Type: this.medicine_form.value.Medicine_Type,
        Medicine_Instruction: this.medicine_form.value.Medicine_Instruction,
        Medicine_Days: this.medicine_form.value.Medicine_Days,
        timings: this.medicine_form.value.timings,
      });

      this.medicine_form.reset({

      });
      this.typesubmit2 = false;

    }
  }

  deleteTab(index) {
    this.medicine_list.splice(index, 1);
  }

  adddiagnosis() {
    this.typesubmit3 = true;
    if (this.diagnosis_from.invalid) {
      return;
    } else {
      this.diagnosis_list.push({
        diagnosis: this.diagnosis_from.value.diagnosis,
        diagnosis_instruction: this.diagnosis_from.value.diagnosis_instruction,
      });
      this.diagnosis_from.reset();
      this.typesubmit3 = false;
    }
  }

  deletediagnosis(index) {
    this.diagnosis_list.splice(index, 1);
  }

  typeSubmit() {
    this.loadingpart = true;
    this.typesubmit = true;
    if (this.form.invalid) {
      this.loadingpart = false;
      return;
    } else if (this.medicine_list.length == 0) {
      Swal.fire({
        position: "top-end",
        icon: "question",
        title: "Please Enter the Suggestions Medicine ! ",
        showConfirmButton: false,
        timer: 1500,
      });
      this.loadingpart = false;
    } else {
      this.form.value.medicine_list = this.medicine_list;
      this.form.value.diagnosis_list = this.diagnosis_list;
      this.form.value.company_id = localStorage.getItem("company_id");
      this.myservice
        .Add_patient_prescription(this.form.value)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.loadingpart = false;
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Successfully Submitted",
              showConfirmButton: false,
              timer: 1500,
            });
            this.typesubmit = false;
            this.form.reset();
            this.medicine_list = [];
            this.diagnosis_list = [];
          } else {
            this.loadingpart = false;
            Swal.fire("Failed");
          }
        });
    }
  }

  gotoprescriptionlist() {
    this.router.navigate(["/hospital/prescription-list"]);
  }

}



