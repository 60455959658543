import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-purchase-prints',
  templateUrl: './purchase-prints.component.html',
  styleUrls: ['./purchase-prints.component.scss']
})
export class PurchasePrintsComponent {
  firstData: any;
  secondData: any;

  companyName: any;
  companyAddress: any;
  companyNumber: any;
  a: any;
  b: any;
  data1: any;
  data2: any;
  grandTotal: any;
  disable:boolean=true

  sName: any;
  sInvoice: any;
  constructor(public route: ActivatedRoute , public location :Location) {

    this.a = localStorage.getItem("purchase_report1")
    this.b = localStorage.getItem("purchase_report2")
    this.companyName = localStorage.getItem(('name'))
    this.companyAddress = localStorage.getItem(('address'))
    this.companyNumber = localStorage.getItem(('hospital_number'))
    this.data1 = JSON.parse(this.a)
    this.data2 = JSON.parse(this.b)                
    this.grandTotal =  this.data2.reduce((sum , item)=> sum + (item.medicine_price || 0),0);
    this.sName = this.data1.supplier_name
    this.sInvoice = this.data1.supplier_invoice
  }

  public openPDF(): void {
    this.disable = false;
    setTimeout(() => {
      window.print();
    }, 200);
  }

  Back() {
    this.location.back();
  }
  
}
