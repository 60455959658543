import { Component, QueryList, ViewChildren } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { doctorlistreports } from 'src/app/pages/registration/advanced.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { doctorlistadvance } from './doctorlist.advance';
import { AdvancedSortableDirective, SortEvent } from 'src/app/pages/registration/advanced-sortable.directive';
import { ModuleService } from '../module.service';
import Swal from 'sweetalert2';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: "app-doctorlist",
  templateUrl: "./doctorlist.component.html",
  styleUrls: ["./doctorlist.component.scss"],
})
export class DoctorlistComponent {
  breadCrumbItems: Array<{}>;
  tableData: doctorlistadvance[];
  tables$: Observable<doctorlistreports[]>;
  total$: Observable<number>;
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;
  data: any;
  constructor(
    private pipe: DecimalPipe,
    public myservice: ModuleService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    public service: doctorlistadvance
  ) {
    this.getdata();
  }
  typeValidationForm: FormGroup;
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Doctorlist", active: true },
    ];
    this.typeValidationForm = this.formBuilder.group({
      doctor_name: ["", [Validators.required]],
      doctor_age: ["", [Validators.required]],
      doctor_gender: ["", [Validators.required]],
      doctor_number: ["", [Validators.required]],
      doctor_address: ["", [Validators.required]],
      license_number: ["", [Validators.required]],
      doctor_email: ["", [Validators.required]],
      doctor_experience: ["", [Validators.required]],
      doctor_dept: ["", [Validators.required]],
      doctor_charges: ["", [Validators.required]],
      doctor_timings: ["", [Validators.required]],
    });
  }
  getdata() {
    this.tables$ = this.service.tables();
    this.total$ = this.service.total$;
  }
  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  gotoprescriptionview1() {
    this.router.navigate(["/hospital/add-doctor"]);
  }
  Delete(i) {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to delete it ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.myservice.deletedoctorli(i).subscribe((res: any) => {
          if (res.status == 200) {
            this.getdata();
            Swal.fire({
              position: "top-end",
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          }
        });
      }
    });
  }
  centerModal(centerDataModal: any, data) {
    this.typeValidationForm.patchValue({
      doctor_name: data.doctor_name,
      doctor_age: data.doctor_age,
      doctor_gender: data.doctor_gender,
      doctor_number: data.doctor_number,
      doctor_address: data.doctor_address,
      license_number: data.license_number,
      doctor_email: data.doctor_email,
      doctor_experience: data.doctor_experience,
      doctor_dept: data.doctor_dept,
      doctor_charges: data.doctor_charges,
      doctor_timings: data.doctor_timings,
      id: data.id,
    });
    this.id = data.id;
    this.modalService.open(centerDataModal, { centered: true, size: "lg" });
  }
  id: any;
  typesubmit: boolean;
  get type() {
    return this.typeValidationForm.controls;
  }
  edit() {
    this.typesubmit = true;
    if (this.typeValidationForm.invalid) {
      return;
    } else
      var data = {
        company_id: localStorage.getItem("company_id"),
        id: this.id,
        medicine_name: this.typeValidationForm.value.medicine_name,
        doctor_name: this.typeValidationForm.value.doctor_name,
        doctor_age: this.typeValidationForm.value.doctor_age,
        doctor_gender: this.typeValidationForm.value.doctor_gender,
        doctor_number: this.typeValidationForm.value.doctor_number,
        doctor_address: this.typeValidationForm.value.doctor_address,
        license_number: this.typeValidationForm.value.license_number,
        doctor_email: this.typeValidationForm.value.doctor_email,
        doctor_experience: this.typeValidationForm.value.doctor_experience,
        doctor_dept: this.typeValidationForm.value.doctor_dept,
        doctor_charges: this.typeValidationForm.value.doctor_charges,
        doctor_timings: this.typeValidationForm.value.doctor_timings,
      };

    this.myservice.Editdoctordetails(data).subscribe((res: any) => {
      if (res.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Successfully Upadted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.typesubmit = false;
        this.typeValidationForm.reset();
        this.modalService.dismissAll();
        this.getdata();
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Oops...",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }
  isDownloading: boolean = false;
  downloadExcel() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data) => {
      const exportData = data.map((table) => ({
        "Doctor name": table.doctor_name,
        Age: table.doctor_age,
        "Doctor Gender": table.doctor_gender,
        "DOctor Number": table.doctor_number,
        Address: table.doctor_address,
        "License Number": table.license_number,
        "Doctor timings": table.doctor_timings,
        Charges: table.doctor_charges,
        Depeartment: table.doctor_dept,
        Experience: table.doctor_experience,
        Email: table.doctor_email,
      }));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer: any = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "Doctor list ");
      this.isDownloading = false; // Reset the flag after download is complete
    });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Doctor Name",
        "Age",
        "Doctor Gender",
        "DOctor Number",
        "Address",
        "License Number",
        "Doctor timings",
        "Charges",
        "Depeartment",
        "Experience",
        "Email",
      ];
      const exportData = data.map((table: any) => [
        table.doctor_name,
        table.doctor_age,
        table.doctor_gender,
        table.doctor_number,
        table.doctor_address,
        table.license_number,
        table.doctor_timings,
        table.doctor_charges,
        table.doctor_dept,
        table.doctor_experience,
        table.doctor_email,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("Doctors-List.pdf");
      this.isDownloading = false;
    });
  }
}


