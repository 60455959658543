<div class="container-fluid">
    <app-pagetitle title="Registration" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-success" (click)="clcick()">
                        <i class=" ri-menu-2-fill align-middle me -2"></i> Registeration-List
                    </button>
                </div>
                <form (ngSubmit)="typeSubmit()" [formGroup]="typeValidationForm">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Name
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{'is-invalid': typesubmit && type.name.errors}"
                                    placeholder="Type something">
                                <div *ngIf="typesubmit && type.name.errors" class="invalid-feedback">
                                    <span *ngIf="type.name.errors.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Phone Number
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input formControlName="number" type="text" minlength="10" maxlength="10"
                                        class="form-control" (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.number.errors}"
                                        placeholder="Enter only numbers">
                                    <div *ngIf="typesubmit && type.number.errors" class="invalid-feedback">
                                        <span *ngIf="type.number.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Email
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input formControlName="email" type="text" class="form-control"
                                        (keypress)="noteemail($event)" (paste)="$event.preventDefault()"
                                        [ngClass]="{'is-invalid': typesubmit && type.email.errors}"
                                        placeholder="Enter Email">
                                    <div *ngIf="typesubmit && type.email.errors" class="invalid-feedback">
                                        <span *ngIf="type.email.errors.required">This email is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Hospital Name
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="url" class="form-control" placeholder="Hospital Name"
                                        formControlName="hospital_name"
                                        [ngClass]="{'is-invalid': typesubmit && type.hospital_name.errors}">
                                    <div *ngIf="typesubmit && type.hospital_name.errors" class="invalid-feedback">
                                        <span *ngIf="type.hospital_name.errors.required">This value is required.</span>
                                        <span *ngIf="type.hospital_name.errors.pattern">
                                            This value should be a valid
                                            Hospital name.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Hospital Phone Number
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="url" class="form-control" placeholder="Hospital Phone Number"
                                        formControlName="hospital_number" (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.hospital_number.errors}">
                                    <div *ngIf="typesubmit && type.hospital_number.errors" class="invalid-feedback">
                                        <span *ngIf="type.hospital_number.errors.required">
                                            This value is
                                            required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Domain URL
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="url" class="form-control" placeholder="URL" formControlName="url"
                                        [ngClass]="{'is-invalid': typesubmit && type.url.errors}">
                                    <div *ngIf="typesubmit && type.url.errors" class="invalid-feedback">
                                        <span *ngIf="type.url.errors.required">This value is required.</span>
                                        <span *ngIf="type.url.errors.pattern">This value should be a valid url.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Category
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <select formControlName="bussiness_id" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.bussiness_id.errors}">
                                        <option value selected>
                                            Select
                                        </option>
                                        <option value="0">Hospital</option>
                                        <option value="1">Store</option>
                                    </select>
                                    <div *ngIf="typesubmit && type.bussiness_id.errors" class="invalid-feedback">
                                        <span *ngIf="type.bussiness_id.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Color
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="color" class="form-control" placeholder="URL" formControlName="color"
                                        [ngClass]="{'is-invalid': typesubmit && type.color.errors}">
                                    <div *ngIf="typesubmit && type.color.errors" class="invalid-feedback">
                                        <span *ngIf="type.color.errors.required">This value is required.</span>
                                        <span *ngIf="type.color.errors.pattern">
                                            This value should be a valid
                                            color.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Package Type
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <select formControlName="package_type" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.package_type.errors}">
                                        <option value selected> Select </option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="threeMonths"> Three Months</option>
                                        <option value="sixmonths"> Six Months</option>
                                        <option value="Yearly">Yearly</option>
                                    </select>
                                    <div *ngIf="typesubmit && type.package_type.errors" class="invalid-feedback">
                                        <span *ngIf="type.package_type.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Price
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Enter price"
                                        formControlName="price" [(ngModel)]="price" (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.price.errors}" (keyup)="clear()">
                                    <div *ngIf="typesubmit && type.price.errors" class="invalid-feedback">
                                        <span *ngIf="type.price.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    GST
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Enter Gst"
                                        formControlName="gst" (keyup)="gste(gst)" [(ngModel)]="gst"
                                        (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.gst.errors}">
                                    <div *ngIf="typesubmit && type.gst.errors" class="invalid-feedback">
                                        <span *ngIf="type.gst.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Final Price
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Final Amount"
                                        formControlName="final_price" readonly (keypress)="numericOnly($event)"
                                        [ngClass]="{'is-invalid': typesubmit && type.final_price.errors}"
                                        [(ngModel)]="final_price">
                                    <div *ngIf="typesubmit && type.final_price.errors" class="invalid-feedback">
                                        <span *ngIf="type.final_price.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Payment Type
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <select class="form-control" formControlName="payment_type"
                                        [ngClass]="{'is-invalid': typesubmit && type.payment_type.errors}">
                                        <option selected disabled>
                                            Select The Option
                                        </option>
                                        <option value="Cash">Cash</option>
                                        <option value="Card">Card</option>
                                        <option value="Upi">Upi</option>
                                        <option value="Net Banking">Net Banking</option>
                                    </select>
                                    <div *ngIf="typesubmit && type.payment_type.errors" class="invalid-feedback">
                                        <span *ngIf="type.payment_type.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    ID Proof ( Any Number ) Pan or Aadhar
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="text" class="form-control" placeholder="ID Proof Number"
                                        formControlName="id_proof"
                                        [ngClass]="{'is-invalid': typesubmit && type.id_proof.errors}">
                                    <div *ngIf="typesubmit && type.id_proof.errors" class="invalid-feedback">
                                        <span *ngIf="type.id_proof.errors.required">This value is required.</span>
                                        <span *ngIf="type.id_proof.errors.pattern">
                                            This value should be a valid
                                            url.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Proof Photo
                                    <span style="color:red">&nbsp;&nbsp;* (100*100)</span>
                                </label>
                                <div>
                                    <input id="yourFileInputId" type="file" accept="image/png"
                                        (change)="onImageChange2($event)">

                                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                        format="png" (imageCropped)="onImageCropped($event)"
                                        (imageLoaded)="onImageLoaded($event)" (cropperReady)="onCropperReady($event)"
                                        (loadImageFailed)="onLoadImageFailed($event)"
                                        style="width: 50%;"></image-cropper>
                                    <i (click)="clearimg()" style="font-size: 24px" class="fa fa-window-close m-2"
                                        aria-hidden="true"></i>

                                    <div *ngIf="croppedImage">
                                        <h4>Cropped Image</h4>
                                        <img [src]="croppedImage" alt="Cropped Image" style="width: 20%;height: 20%;">

                                    </div>
                                </div>
                                <div *ngIf="typesubmit && type.proof_img.errors" class="invalid-feedback">
                                    <span *ngIf="type.proof_img.errors.required">Image Required</span>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Logo
                                    <span style="color:red">&nbsp;&nbsp;* (40*30)</span>
                                </label>

                                <div>
                                    <input id="yourFileInputId1" type="file" accept="image/png"
                                        (change)="onImageChange($event)">

                                    <image-cropper [imageChangedEvent]="imageChangedEventlogo"
                                        [maintainAspectRatio]="true" format="png"
                                        (imageCropped)="onImageCropped1($event)" (imageLoaded)="onImageLoaded1($event)"
                                        (cropperReady)="onCropperReady1($event)"
                                        (loadImageFailed)="onLoadImageFailed1($event)"
                                        style="width: 50%;"></image-cropper>
                                    <i (click)="clearimg1()" style="font-size: 24px" class="fa fa-window-close m-2"
                                        aria-hidden="true"></i>
                                    <div *ngIf="croppedImage1">
                                        <h4>Cropped Image</h4>
                                        <img [src]="croppedImage1" alt="Cropped Image" style="width: 20%;height: 20%;">

                                    </div>
                                </div>
                                <div *ngIf="typesubmit && type.proof_img.errors" class="invalid-feedback">
                                    <span *ngIf="type.proof_img.errors.required">Image Required</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Address
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <textarea formControlName="address" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.address.errors}"></textarea>
                                    <div *ngIf="typesubmit && type.address.errors" class="invalid-feedback">
                                        <span *ngIf="type.address.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>
                                    Expiry date
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div>
                                    <input type="date" formControlName="expirydate" class="form-control"
                                        [ngClass]="{'is-invalid': typesubmit && type.address.errors}"
                                        [value]="typeValidationForm.get('expirydate').value | date:'yyyy-MM-dd'"
                                        readonly>
                                    <div *ngIf="typesubmit && type.address.errors" class="invalid-feedback">
                                        <span *ngIf="type.address.errors.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-12">
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <app-pagetitle title="Hospital Management Modules Permission"></app-pagetitle>
                                    <h4 class="card-title mb-4"></h4>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value
                                                        id="defaultCheck1"
                                                        [checked]="totalprojectlist.length == modulesname.length"
                                                        (change)="checkUncheckAll($event)">
                                                    <h4 class="card-title mb-4">Select All Permissions</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3" *ngFor="let i of totalprojectlist">
                                            <input [checked]="i.isChecked" class="form-check-input" type="checkbox"
                                                value="{{i.id}}" id="defaultCheck1"
                                                (change)="singlemodulecheck($event,i)">
                                            <label class="form-check-label">
                                                &nbsp;&nbsp;
                                                {{i.label}}
                                                <br>
                                                <br>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group" style="text-align: center;">
                                <br>
                                <input type="checkbox" formControlName="term"
                                    [ngClass]="{'is-invalid': typesubmit && type.term.errors}">
                                <label>
                                    &nbsp;&nbsp;&nbsp; Terms And Conditions
                                    <span style="color:red">&nbsp;&nbsp;*</span>
                                </label>
                                <div *ngIf="typesubmit && type.term.errors" class="invalid-feedback">
                                    <span *ngIf="type.term.errors.required">This value is required.</span>
                                    <span *ngIf="type.term.errors.pattern">This value should be a valid term.</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-0" style="text-align: right;">
                            <button type="submit" class="btn btn-primary me-1">
                                Submit
                            </button>
                            <button type="reset" class="btn btn-secondary">
                                Reset
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Registration Data Table</h4>
                    <div class="row mb-md-2">
                        <div class="col-sm-12 col-md-8">
                            <div class="dataTables_length" id="tickets-table_length">
                                <label class="d-inline-flex align-items-center">
                                    Show
                                    <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                        [(ngModel)]="service.pageSize"
                                        class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                        <option [ngValue]="10">10</option>
                                        <option [ngValue]="25">25</option>
                                        <option [ngValue]="50">50</option>
                                        <option [ngValue]="100">100</option>
                                    </select>
                                    entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-2" style="text-align: left">
                            <div class="form-group mb-0" style="text-align: right;">
                                <button class="btn btn-secondary me-1" (click)="downloadExcel()">Excel</button>
                                <button class="btn btn-secondary" (click)="downloadPdf()"> PDF</button>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <label class="d-inline-flex align-items-center"> Search:
                                    <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                        aria-controls="tickets-table" [(ngModel)]="service.searchTerm">
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive">
                        <table id="basic-datatable"
                            class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>S . no</th>
                                    <th sortable="name" (sort)="onSort($event)">
                                        Name
                                    </th>
                                    <th sortable="number" (sort)="onSort($event)">
                                        Number
                                    </th>
                                    <th sortable="email" (sort)="onSort($event)">
                                        Email
                                    </th>
                                    <th sortable="hospital_name" (sort)="onSort($event)">
                                        Hospital Name
                                    </th>
                                    <th sortable="hospital_number" (sort)="onSort($event)">
                                        Hospital Number
                                    </th>
                                    <th sortable="package_type" (sort)="onSort($event)">
                                        Package Type
                                    </th>
                                    <th sortable="date" (sort)="onSort($event)">
                                        Product Purchase Date
                                    </th>
                                    <th sortable="Expiry_date" (sort)="onSort($event)">
                                        Product Expiry Date
                                    </th>
                                    <th>Active Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let table of tables$ | async;let i=index">
                                <tr>
                                    <td align="center">
                                        <a class="expand-row" (click)="changeValue(i)">
                                            +
                                        </a>
                                    </td>
                                    <td>{{i+1}}</td>
                                    <td>
                                        <ngb-highlight [result]="table.name"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.number"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.email"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.hospital_name"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.hospital_number"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="table.package_type"
                                            [term]="service.searchTerm"></ngb-highlight>
                                    </td>
                                    <td>{{table.date | date:'dd-MM-yyyy'}}</td>
                                    <td>{{table. Expiry_date | date:'dd-MM-yyyy'}}</td>

                                    <td align="center">
                                        <label class="switch" (click)="getactive(table.active_status , table.id)">
                                            <input type="checkbox" [checked]="table.active_status==0"
                                                (click)="senttoactive()">
                                            <div class="slider"></div>

                                            <div class="slider-card">
                                                <div class="slider-card-face slider-card-front"></div>
                                                <div class="slider-card-face slider-card-back"></div>
                                            </div>
                                        </label>
                                        <p *ngIf="table.active_status==0">Active</p>
                                        <p *ngIf="table.active_status==1">In-Active</p>
                                    </td>
                                    <td>
                                        <i style="font-size: 19px;border-radius:5px;background-color: rgb(33, 130, 44);color: white;padding: 3%;"
                                            class=" ri-delete-bin-2-line" title="Delete" (click)="delete(table)"></i>
                                    </td>
                                </tr>
                                <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                                    <td align="center"></td>
                                    <td colspan="15" class="p-0">
                                        <table class="table mb-0 table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Color</th>
                                                    <th>:</th>
                                                    <td>
                                                        <div style="width:60px;height:30px;border-radius: 4px; "
                                                            [ngStyle]="{'background-color': table.color}">
                                                        </div>
                                                        {{table.color}}
                                                    </td>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Paid Amount</th>
                                                    <th>:</th>
                                                    <th>{{table.final_price}}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Payment Type</th>
                                                    <th>:</th>
                                                    <th>{{table.payment_type}}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>ID Proof</th>
                                                    <th>:</th>
                                                    <th>{{table.id_proof}}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>

                                                <tr>
                                                    <th>ID Proof Image</th>
                                                    <th>:</th>
                                                    <th>
                                                        <a style="cursor:pointer;"
                                                            (click)="openimg(table.proof_img,imagemodel)">
                                                            <img src="{{table.proof_img}}"
                                                                style="width:80px;height: 80px;"
                                                                onerror="this.onerror=null;this.src='assets/img/avatar.png';"
                                                                height="70px" width="70px">
                                                        </a>
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <th>:</th>
                                                    <td>{{table.address}}</td>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Comapny Url</th>
                                                    <th>:</th>
                                                    <td>{{table.url}}</td>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Logo</th>
                                                    <th>:</th>
                                                    <th>
                                                        <a style="cursor:pointer;"
                                                            (click)="openimg(table.logo,imagemodellogo)">
                                                            <img src="{{table.logo}}" style="width:80px;height: 80px;">
                                                        </a>
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>Main Modules</th>
                                                    <th>:</th>
                                                    <th>
                                                        <li *ngFor="let item of table.module_nm "
                                                            style="text-align:left !important;">
                                                            {{item}}
                                                        </li>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <br>
                                        <br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <!-- End table -->

                    <div class="row justify-content-md-between align-items-md-center mt-2">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}}
                                entries
                            </div>
                        </div>

                        <!-- Pagination -->
                        <div class="col-sm-12 col-md-5">
                            <div class="text-md-end float-md-end s pagination-rounded">
                                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                                    [pageSize]="service.pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- End Pagination -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-overlay" *ngIf="loadingpart">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>
<ng-template #imagemodel let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0 ">Image</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()">
            ×
        </button>
    </div>
    <img src={{proof_img}}>
</ng-template>
<ng-template #imagemodellogo let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0 ">Image</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()">
            ×
        </button>
    </div>
    <img src={{logo}}>
</ng-template>