import { Component, Inject, PLATFORM_ID, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/account/service.service';
import Swal from 'sweetalert2';
import { AdvancedService } from './advanced.service';
import { Observable, take } from "rxjs";
import { Table } from './advanced.model';
import { AdvancedSortableDirective, SortEvent } from './advanced-sortable.directive';
import { isPlatformBrowser } from "@angular/common";
import { DecimalPipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Router } from '@angular/router';
@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
  providers: [AdvancedService, DecimalPipe],
})
export class RegistrationComponent {
  // bread crumb items
  breadCrumbItems: Array<{}>;
  typeValidationForm: UntypedFormGroup; // type validation form
  tableData: Table[];
  tables$: Observable<Table[]>;
  total$: Observable<number>;
  @ViewChildren(AdvancedSortableDirective)
  headers: QueryList<AdvancedSortableDirective>;
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;
  main_modules: any = [];
  checkall: number = 0;
  price: any;
  gst: any;
  final_price: any;
  loadingpart: boolean = false;
  showbanner: boolean;
  showicon: boolean;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private myservice: ServiceService,
    public service: AdvancedService,
    private pipe: DecimalPipe,
    private modalService: NgbModal,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalCtrl: NgbModal
  ) { }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Amaravathi" },
      { label: "Registration", active: true },
    ];

    this.typeValidationForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      number: ["", [Validators.required]],
      email: ["", [Validators.required]],
      url: ["", [Validators.required]],
      hospital_name: ["", [Validators.required]],
      hospital_number: ["", [Validators.required]],
      color: ["", [Validators.required]],
      address: ["", [Validators.required]],
      package_type: ["", [Validators.required]],
      term: ["", [Validators.required]],
      id_proof: ["", [Validators.required]],
      bussiness_id: ["", [Validators.required]],
      proof_img: [""],
      logo: [""],
      price: ["", [Validators.required]],
      gst: ["", [Validators.required]],
      final_price: [""],
      payment_type: ["", [Validators.required]],
      expirydate: [""],
    });

    this.typeValidationForm
      .get("package_type")
      .valueChanges.subscribe((packageType) => {
        this.updateExpiryDate(packageType);
      });

    this.updateExpiryDate(this.typeValidationForm.value.package_type);

    this.get();
    this.getpermission();
    this.fgetdata();
  }
  updateExpiryDate(packageType: string) {
    const currentDate = new Date();

    if (packageType === "Monthly") {
      const oneMonthLater = new Date();
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
      this.typeValidationForm.get("expirydate").setValue(oneMonthLater);
    } else if (packageType === "threeMonths") {
      const threeMonthsLater = new Date();
      threeMonthsLater.setMonth(currentDate.getMonth() + 3);
      this.typeValidationForm.get("expirydate").setValue(threeMonthsLater);
    } else if (packageType === "sixmonths") {
      const sixMonthsLater = new Date();
      sixMonthsLater.setMonth(currentDate.getMonth() + 6);
      this.typeValidationForm.get("expirydate").setValue(sixMonthsLater);
    } else if (packageType === "Yearly") {
      const oneYearLater = new Date();
      oneYearLater.setFullYear(currentDate.getFullYear() + 1);
      this.typeValidationForm.get("expirydate").setValue(oneYearLater);
    }
  }
  fgetdata() {
    this.tables$ = this.service.tables$;
    this.total$ = this.service.total();
  }

  typesubmit: boolean;
  get type() {
    return this.typeValidationForm.controls;
  }

  gste(gst: any) {
    var amount = ((this.price * 1) / 100) * (gst * 1);
    var total12 = this.price * 1 + amount * 1;
    this.typeValidationForm.patchValue({
      final_price: total12,
    });
  }

  clear() {
    this.gst = "";
    this.final_price = "";
  }

  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }



  clearimg() {
    this.imageChangedEvent = null; // or this.imageChangedEvent = [];
    this.croppedImage = null; // Clear the cropped image as well, if needed
    this.imagedata2 = []
    const fileInput = document.getElementById('yourFileInputId') as HTMLInputElement
    if (fileInput) {
      fileInput.value = '';
    }
  }


  clearimg1() {
    this.imageChangedEventlogo = null; // or this.imageChangedEvent = [];
    this.croppedImage1 = null; // Clear the cropped image as well, if needed
    this.imagedata = []
    const fileInput = document.getElementById('yourFileInputId1') as HTMLInputElement
    if (fileInput) {
      fileInput.value = '';
    }
  }

  imagedata: any = [];


  imageChangedEvent: any;
  croppedImage: any;
  croppedImage1: any;
  imageChangedEventlogo: any;
  onImageChange2(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageChangedEvent = event;
      };
      reader.readAsDataURL(file);
    }
  }

  onImageChange(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageChangedEventlogo = event;
      };
      reader.readAsDataURL(file);
    }
  }

  hideme: boolean[] = [];
  data: any = [];

  get() {
    this.loadingpart = true;
    this.myservice.gethospitallimit().subscribe((res: any) => {
      this.data = res.data;
      this.loadingpart = false;
      for (let i = 0; i <= this.data.length; i++) {
        this.hideme.push(true);
      }
    });
  }

  menuItems: any = [];
  totalprojectlist: any = [];
  selectedmoduleslist: any = [];

  getpermission() {
    this.loadingpart = true;
    this.myservice.getpermission().subscribe((res: any) => {
      this.main_modules = res.data[0];
      this.loadingpart = false;
      var data2 = res.data[1];
      this.main_modules.map((res) => {
        var subdata = data2.filter((obj) => {
          return obj.parentId == res.id;
        });
        if (subdata.length) {
          res.subItems = subdata;
        } else {
          res.subItems = [];
        }
      });
      this.menuItems = this.main_modules;
      this.totalprojectlist = [];
      this.totalprojectlist = Object.values(
        this.main_modules.reduce((r, o) => {
          if (o.check_sub_menu == true) {
            this.selectedmoduleslist.push(o);
          }
          r[o.id] = r[o.id] || {
            id: o.id,
            label: o.label,
            link: o.link,
            icon: o.icon,
            subItems: o.subItems,
            d_in: o.d_in,
            subcollopescnd: false,
            isChecked: false,
          };
          // r[o.module_id]['subItems'].push({ parentId: o.parentId, 'label': o.label, 'link': o.link, 'icon': o.icon, 'subcollopescnd': false, checkallstatus: 'Un Check All', 'check_sub_menu': o.check_sub_menu, checkallmodules: true, submenu: [] });
          return r;
        }, {})
      );

    });
  }

  modulesname: any = [];
  checkUncheckAll(evt) {
    this.totalprojectlist.forEach((c) => (c.isChecked = evt.target.checked));
    this.modulesname = [];
    if (evt.target.checked == true) {
      for (let i = 0; i <= this.totalprojectlist.length; i++) {
        this.modulesname.push({
          id: this.totalprojectlist[i].id,
          label: this.totalprojectlist[i].label,
          subItems: this.totalprojectlist[i].subItems,
        });
      }
    }
  }

  singlemodulecheck(evt, singlemoduledta) {
    if (evt.target.checked == true) {
      this.modulesname.push({
        id: singlemoduledta.id,
        label: singlemoduledta.label,
        subItems: singlemoduledta.subItems,
      });
    } else {
      var index = this.modulesname.findIndex((i) => i.id == singlemoduledta.id);
      this.modulesname.splice(index, 1);
    }
  }

  typeSubmit() {
    this.loadingpart = true;
    this.typesubmit = true;

    if (this.typeValidationForm.invalid) {
      this.loadingpart = false;
      return;
    } else if (this.imagedata.length == 0) {
      Swal.fire("Please Logo Picture");
      this.loadingpart = false;
    } else if (this.modulesname.length == 0) {
      Swal.fire("Please Select  Modules");
      this.loadingpart = false;
    } else {
      this.typeValidationForm.value.logo = this.imagedata;
      this.typeValidationForm.value.proof_img = this.imagedata2;
      this.typeValidationForm.value.modules = this.modulesname;
      this.myservice
        .posthospitaldetails(this.typeValidationForm.value)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.loadingpart = false;
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Successfully Submitted",
              showConfirmButton: false,
              timer: 1500,
            });
            this.typesubmit = false;
            this.typeValidationForm.reset();
            this.croppedImage = null;
            this.croppedImage1 = null;
            this.modulesname = [];
            this.imagedata = [];
            this.imagedata2 = [];

            this.fgetdata();

            localStorage.setItem("logo", this.imagedata2);
          } else {
            Swal.fire("Failed");
            this.loadingpart = false;
          }
        });
    }
  }

  /////////////////////////////// table start/////////////////////////////////
  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }
  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  getactive(event, id) {
    this.loadingpart = true;
    if (event == "1") {
      var idfg = "0";
    } else {
      var idfg = "1";
    }
    var data = {
      active_ind: idfg,
      id: id,
    };
    this.myservice.postactive_status(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.fgetdata();
        if (idfg == "1") {
          this.loadingpart = false;
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Account must be Deactived !",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.loadingpart = false;
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Account must be Actived !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire("Failed");
        this.loadingpart = false;
      }
    });
  }

  delete(i) {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to delete it ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = { id: i.id };

        this.myservice.delete_company(data).subscribe((res: any) => {
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            this.fgetdata();
          }
        });
      }
    });
  }
  public closeResult: string;
  ImageSrc: string;
  imagesData: any = [];
  modalOpen: boolean;
  openModal(product) {
    this.imagesData = product;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {

      this.modalCtrl
        .open(this.QuickView, {
          size: 's',
          ariaLabelledBy: "modal-basic-title",
          centered: true,
          windowClass: "Quickview",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  proof_img: any = [];
  logo: any = [];
  openimg(image, openmodel) {
    this.proof_img = image;
    this.logo = image;
    this.modalService.open(openmodel, { size: "l", centered: true });
  }
  modalDismiss() {
    this.showbanner = false;
    this.showicon = false;
    this.modalService.dismissAll();
  }

  //excell download//
  isDownloading: boolean = false;
  downloadExcel() {
    // Check if a download is already in progress
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$
      .pipe(take(1)) // Take only the first emission
      .subscribe((data) => {
        const exportData = data.map((table) => ({
          Name: table.name,
          Number: table.number,
          Email: table.email,
          "Hospital Name": table.hospital_name,
          "Hospital Number": table.hospital_number,
          "Package Type": table.package_type,
          "Purchase Date": table.date,
          "Expiry Date": table.Expiry_date,
          "Paid Amount": table.final_price,
          "Payment Type": table.payment_type,
          "Id Proof": table.id_proof,
          Address: table.address,
        }));
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer: any = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "Registeration");
        this.isDownloading = false; // Reset the flag after download is complete
      });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const blob: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName + "_" + new Date().getTime() + ".xlsx");
  }
  //pdfdownload//
  downloadPdf() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.tables$.pipe(take(1)).subscribe((data: any) => {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [500, 300],
      }) as any;
      const headers = [
        "Name",
        "Number",
        "Email",
        "Hospital Name",
        "Hospital Number",
        "Package Type",
        "Purchase Date",
        "Expiry Date",
        "Paid Amount",
        "Payment Type",
        "Id Proof",
        "Address",
      ];
      const exportData = data.map((table: any) => [
        table.name,
        table.number,
        table.email,
        table.hospital_name,
        table.hospital_number,
        table.package_type,
        table.date,
        table.Expiry_date,
        table.final_price,
        table.payment_type,
        table.id_proof,
        table.address,
      ]);

      pdf.autoTable({
        head: [headers],
        body: exportData,
      });
      pdf.save("Registerations.pdf");
      this.isDownloading = false;
    });
  }

  clcick() {
    this.router.navigate(["/registeration-list"]);
  }

  noteemail(event: any): boolean {
    // let patt = /^([0-9])$/;
    let patt = /^[a-zA-Z0-9_,.@]*$/;
    let result = patt.test(event.key);
    return result;
  }

  imagedata2: any = [];
  onImageCropped(event: ImageCroppedEvent) {

    if (event && event.blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.croppedImage = reader.result;

        const originalFileName = this.imagedata2[0]?.filetype || "png";
        this.imagedata2 = [];
        this.imagedata2.push({
          reviewimg: this.croppedImage, filetype: originalFileName,
        });
      };

      // Read the blob as a data URL
      reader.readAsDataURL(event.blob);
    } else {
      console.warn("Cropped image blob is undefined.");
    }
  }
  onImageLoaded(event: any) {

  }
  onCropperReady(event: any) {

  }
  onLoadImageFailed(event: any) {

  }
  onSaveCroppedImage() {
    if (this.croppedImage) {

    } else {
      console.warn("No cropped image to save.");
    }
  }
  onImageCropped1(event: ImageCroppedEvent) {

    if (event && event.blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.croppedImage1 = reader.result;

        const originalFileName = this.imagedata[0]?.filetype || "png";
        this.imagedata = [];
        this.imagedata.push({
          reviewimg: this.croppedImage1, filetype: originalFileName,
        });
      };

      // Read the blob as a data URL
      reader.readAsDataURL(event.blob);
    } else {
      console.warn("Cropped image blob is undefined.");
    }
  }
  onImageLoaded1(event: any) {

  }
  onCropperReady1(event: any) {

  }
  onLoadImageFailed1(event: any) {

  }
  onSaveCroppedImage1() {
    if (this.croppedImage1) {

    } else {
      console.warn("No cropped image to save.");
    }
  }

  senttoactive() {


  }
}

